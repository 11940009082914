import react, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../assets/css/style.css"
import moment from 'moment';
import {  } from 'react-social-sharing'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faLinkedin, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons' 
    const Footer = () => {

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 300) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      });
    }, []);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smoothly scrolling
      });
    };

    return (
        <footer className="footer-area footer--light">
        <div className="mini-footer">
         <Container>
           <Row className="d-flex align-items-center">
             <Col xs={12} md={6} lg={8} sm={6} className="justify-content-start d-flex center-wrap">
             <div className="copyright-text">
                  <p>
                    {/* © {moment().format('YYYY')}
                    <a href="/home" className="text-decoration-none text-secondary"> EVFY</a>. All rights reserved. */}
                    <span ><a href="/term-of-use" style={{marginLeft:"5px", marginRight:"5px"}} className="text-decoration-none text-secondary mt-3 fs-6">Terms of Use</a>  | </span>
                    <span style={{marginLeft:"5px"}}><a href="/careers" className="text-decoration-none text-secondary mt-3 fs-6">Careers</a></span>
                  </p>
                </div>
             </Col>
             <Col xs={12} md={6} lg={4} sm={6} className="d-flex justify-content-end justify-content-xs-center align-left center-wrap">
             {/* <div > */}
                         <a href="https://www.facebook.com/evfysg" target="_blank"><div className="iconStyleFooter me-3"><FontAwesomeIcon icon={faFacebook} style={{ fontSize: '16px', color:'#02AF95' }} /></div></a>
                          <a href="https://www.instagram.com/evfysg" target="_blank"><div className="iconStyleFooter me-3"><FontAwesomeIcon icon={faInstagram} style={{ fontSize: '16px', color:'#02AF95' }} /></div></a>
                          <a href="https://www.linkedin.com/company/evfy-singapore/" target="_blank"><div className="iconStyleFooter"><FontAwesomeIcon icon={faLinkedin} style={{ fontSize: '16px', color:'#02AF95' }} /></div></a>

                    {/* </div> */}
             </Col>
             <Col xs={12} md={12} lg={12} sm={12} className="justify-content-center d-flex mt-4">
             <div className="copyright-text">
                  <p style={{fontSize:"12px"}}>© {moment().format('YYYY')}
                    <a href="/home" className="text-decoration-none text-secondary"> EVFY</a>. All rights reserved.
                  </p>
                </div>
             </Col>
           </Row>
          </Container>
        </div>
        {showButton && <button onClick={(e) => {scrollToTop()}} className="backtoTopBtn" title="Go to top"><div className="btt-icon"><FontAwesomeIcon icon={faChevronUp} style={{ fontSize: '24px', color:'#fff' }} /></div></button> }
      </footer>
    );
};
export default Footer;
