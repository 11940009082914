import { Container, Row, Col, Accordion } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "../../../../assets/css/style.css"
import TempImg from '../../../../assets/img/tempImg/temp.jpg'
import YoutubeEmbed from "./youtubeEmbedded";
import API from '../../../../../src/api/api';
import apiUtil from "../../../../../src/api/apiUtil";
import { useEffect, useState } from "react";
import reviewCommingSoon from '../../../../assets/img/Review Coming Soon.png'
const EVListingSec2 = (props) => {

  const [id] = useState(props.id);
  const [evVehicle, setEvVehicle] = useState([]);
  const [embedId, setEmbedId] = useState(null);

  useEffect(()=>{
    if(id > 0) {
        callVehicleListDetail()
    }     
    }, [id])

const callVehicleListDetail = () => {
  let data = {
  }
  API.get('/api/front_ev_vehicles/'+id, data, '')
  .then((result) => {
     apiUtil.parseResult(result, (data) => { 
       let result = data.data
       setEvVehicle(result)
       let url = result.youtube_link;
       let videoid = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
          if(videoid != null) {
            setEmbedId(videoid[1]);
          } 
       console.log("evve", result)
     }, (error, type) => {
       
      });
  });
}

  return (
    <Container fluid className="p-0 m-0">
      <Row>
        <Col xs={12} md={6} lg={6} className="pt-5">
          <h2 className="text-center">Hear what others have to say!</h2>
          {embedId == "" || embedId == null ?
          <img src={reviewCommingSoon} width="100%" className="img-dropshadow"/> :
          <YoutubeEmbed embedId={embedId} />}
        </Col>
        <Col xs={12} md={6} lg={6} className="pt-5">
        <h3 className="text-center fs-3 mb-4"><span className="pb-2 header-border">FAQs</span></h3>
          <Accordion>
          <Accordion.Item eventKey="0">
              <Accordion.Header>Are EVs cheap?</Accordion.Header>
              <Accordion.Body>
               <p>Our selection of Electric Vans are competitively priced to other diesel or petrol vans, based on their storage capacity. For e.g, our BYD T3 has a similar cargo space to the Nissan NV200, and we make sure to match our rental rates at similar prices.</p>
               <p>Even if there is a difference in rates, you can expect to easily save $8000 in refuelling costs every year! Overall, there will be substantial savings from driving an EV over an ICE van.</p>
               <p>Find out how much you can save with our EVs <a href="/home/#RentalCost" >here.</a></p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>How expensive is it to charge an EV?</Accordion.Header>
              <Accordion.Body>
               <p>Not expensive at all! At current market rates, a full DC charge on our BYD T3 costs less than $30. While electricity tariffs will fluctuate, EV charging prices are still substantially lower than topping up petrol. If you typically drive an average of 150km/day, your annual fuel cost for an ICE vehicle would easily surpass $12,000; however, with an EV, you’d spend less than $5000.</p>
               <p>Need to see which charging provider has the best rates? Click <a href="/home/#EVChargingRate" >here.</a> to compare.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>What’s the difference between AC vs DC charging?</Accordion.Header>
              <Accordion.Body>
                <p>In a nutshell: charging speed vs cost! DC charging can be up to 7x faster than AC charging, but they cost more. Specific charging speeds for both AC and DC would vary depending on the specific EV you drive, so do refer to your vehicle specs for reference.</p>
                <p>To find out more, check out our blog post on <a href="https://evfy.sg/details/20" >EV charging. </a></p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Tell me about Smoothdrive 2.0</Accordion.Header>
              <Accordion.Body>
                <p>Smoothdrive 2.0 is Evfy’s Driver-Partner benefits programme. We believe that our Driver-Partners are micro-entrepreneurs in their own right, and deserve basic job benefits and welfare protection. These benefits would scale as you take more jobs from Evfy.</p>
                <p>To know what benefits we offer, click <a href="/smoothdrive" >here.</a></p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Can I still be a Driver-Partner without leasing?</Accordion.Header>
              <Accordion.Body>
                <p>Sure! Just sign up <a href="#" >here!</a></p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};
export default EVListingSec2;
