import { Container, Row, Col, Form, Accordion } from "react-bootstrap";
import TempImg from '../../../../assets/img/tempImg/temp.jpg'
import "../../../../assets/css/style.css"
import { faChevronLeft, faShare, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bgImg from "../../../../assets/img/background/about_bg.jpg"
import logoImg from "../../../../assets/img/logo/evfy-logo-square.png"
import { useState, useEffect } from "react";
import axios from 'axios'
import Swal from 'sweetalert2'
import toastr from 'toastr'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import API from '../../../../api/api'
import apiUtil from "../../../../api/apiUtil";
import CustomLoading from '../../../../components/CustomLoading'
const CarrerInfo = (props) => {

    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [carrer, setCarrer] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [file, setFile] = useState('');
    const [pdfLink, setPdfLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
      setIsLoading(true);
      //console.log('e.target', e.target)
      emailjs.sendForm('service_8lm954n', 'template_7jbe6ue', e.target, '2stbnOdEztvRCrsq-')
        .then((result) => {
         // console.log(result.text);
          setIsLoading(false);
        }, (error) => {
         // console.log(error.text);
          setIsLoading(false);
        });
    };

    useEffect(() => {
        getCarrerJob();
      }, [])

    const setFileHandler = (e) => { 

        var reader = new FileReader();
        reader.onload = function() {
            var pdfFilevalue = reader.result;
            //console.log("answer pdfFilevalue", pdfFilevalue);
            setFile(pdfFilevalue);
          };
          var asd =reader.readAsDataURL(e.target.files[0]);
           
      }

      const getCarrerJob = () => {
        let data = {
          latest: true,
          status: true,
          expired: false,
        }
        setIsLoading(true);
          API.get('/api/front_careers', data, '')
          .then((result) => {
             apiUtil.parseResult(result, (data) => { 
              let result = data.data && data.data.length > 0 ? data.data : [];
              setCarrer(result)
              setIsLoading(false);
             }, (error, type) => {
              setIsLoading(false);
             });
          });
      }

      const getCarrerWithID = (id) => {
          let url = `${process.env.REACT_APP_API}/api/carrerForntEnd/${id}`
        axios
          .get(url)
          .then((response) => {
            //console.log("id data",response.data)
           setPdfLink(response.data);
          })
          .catch((error) => {
            console.log(error)
          })
      }

// Send Carrer 
    const sendCarrer = (career_id) => { 
        var form = document.getElementsByClassName('needs-validation')
        if (form[0].checkValidity() === false) {
          form[0].classList.add('was-validated')
          return
        }
        const payLoad = {
          name: name,
          email: email,
          phone: phoneNumber,
          address: address,
          attachment_file: file,
          career_id: career_id,
        }
        axios
          .post(`${process.env.REACT_APP_API}/api/front_candiate`, payLoad)
          .then(function (response) {
              if(response != ""){
                // console.log("response _",response.data.success); 
                if(response.data.success == true){
                    Swal.fire('Sent!', 'Thanks for your apply!! we will get back to you.', 'success');
                    setName('');
                    setEmail('');
                    setPhoneNumber('');
                    setFile('');
                    setAddress('');
                    getCarrerWithID(response.data.data.planner.id);
                    //  handleClose()
                }else{
                // show err
                }
              }else{
                  // show err 
              }
          })
          .catch(function (error) {
            console.log('error ', error)
          })
      }


  return (
    <Container fluid className="p-0 m-0">
 <Accordion>
 {isLoading ?  
        <Row className="d-flex justify-content-center align-items-center p-5 mt-5">
              <CustomLoading />
              </Row>
              :
 carrer && carrer.map((carrer) => (
            <Row>
              <Col md={2} lg={2}></Col>
              
              <Col className="d-flex justify-content-between career-style align-items-center my-2">
              <a href={`/career-details/${carrer.id}`} style={{textDecoration:'none'}} className="w-100"><div className="w-100 d-flex justify-content-between align-items-center">
              <span className="text-dark"><strong>{carrer.title}</strong></span>
              <span className="text-dark"> &#62; </span></div></a>
              </Col>
              <Col md={2} lg={2}></Col>
              
            </Row>
            ))}

       </Accordion>
    </Container>
  );
};
export default CarrerInfo;


{/* <FontAwesomeIcon icon={faUpload} style={{ fontSize: '14px', color: '#0FBEA3', }} /> */}