import react, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, Card, Form, Button } from "react-bootstrap";

const SmoothDriveOne = () => {

    return (
        <Container>
             <Row>
            <Col>
              <h3 className="fs-3">Benefits Available <hr className="header-border-secondary border-width-long"/></h3>
              <p className="sub-title">Scale up your benefits as you drive. <a href="/smoothdrive" style={{textDecoration:'none'}}><span style={{color:'#0FBEA3'}}>How?</span></a></p>
            </Col>
          </Row>
        </Container>
    );
};
export default SmoothDriveOne;
