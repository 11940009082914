import react, { useState, useEffect } from "react";
import { Container, Row, Col} from "react-bootstrap";
import "../../../assets/css/style.css"

const NavMenu = () => {
    const [navChack, setNavCheck] = useState("false");


    useEffect(() => {
        NavChange();
    });

    const NavChange = () => {
        // console.log(window.location.pathname );
        if (window.location.pathname != "/home") {
            setNavCheck("true")
        } else {
            const scrollChange = () => {
                { window.scrollY >= 200 ? setNavCheck("true") : setNavCheck("false") }
            }
            window.addEventListener('scroll', scrollChange);
        }
    }
    return (
            <Container fluid className="mx-0 px-0 w-100 py-3" style={{ marginTop: '68px', backgroundColor: '#F6F6F6' }}>
                 <Container>
                 <Row className="d-flex justify-content-evenly subNav">
                    <Col xs={6} md={6} lg={3} className={window.location.pathname == "/ev-chartering"? 'text-center py-2 active  m-0 p-0 borderRight': 'text-center py-2  m-0 p-0 borderRight'}>
                        <div><a href="/ev-chartering" >
                        EV Chartering</a></div>
                    </Col>
                    <Col xs={6} md={6} lg={3} className={window.location.pathname == "/last-mile-delivery"? 'text-center py-2 active  m-0 p-0 borderRight': 'text-center py-2  m-0 p-0 borderRight'}>
                       <div><a href="/last-mile-delivery" >
                        Last-Mile Deliveries</a></div>
                    </Col>
                    <Col xs={6} md={6} lg={3} className={window.location.pathname == "/green-warehousing"? 'text-center py-2 active  m-0 p-0 borderRight': 'text-center py-2  m-0 p-0 borderRight'}>
                    <div><a href="/green-warehousing" >
                        Green Warehousing</a></div>
                    </Col>
                    <Col xs={6} md={6} lg={3} className={window.location.pathname == "/why-ev"? 'text-center py-2 active  m-0 p-0 borderRight': 'text-center py-2  m-0 p-0 borderRight'}>
                    <div><a href="/why-ev" >
                        Why Evfy</a></div>
                    </Col>
                </Row>
                </Container>
            </Container>
    );
};
export default NavMenu;
