import react, { useState, useEffect } from "react";
import NavMenu from "../Layout/navbar";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "../../assets/css/style.css"
import ECOServiceNav from "./partial/ECOServiceNav";
import TempImg from '../../assets/img/background/business.jpg'
import bgImg from '../../assets/img/Eco-Services/GreenWarehousing.jpg'
import EnquireNow from "../Resources/EnquireNow";
import Footer from "../Layout/Footer";

const GreenWarehouse = () => {
    const [show, setShow] = useState(false);

    const Dialog = () => {
        return <Modal
        show={show}
        onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enquire Now!!</Modal.Title>
        </Modal.Header>
      
        <Modal.Body>
          <EnquireNow
          onClose={() => setShow(false)}
          />
        </Modal.Body>
      </Modal>
    }


    return (<>
        <Container fluid>
            <Row>
                <Col>
                    <NavMenu />
                   
                </Col>
            </Row>
            </Container>
            <Container fluid>
        <Row>
          <Col>
          <div className="bg-image-WhyEV"><img src={bgImg} alt="background"/></div>  
          </Col>
        </Row>
        </Container>
            <ECOServiceNav />
            <Container className="min-height">
                <Row>
                    <Col xs={12} md={8} lg={6}>
                        <Row>
                            <Col>
                                <h1 className="fs-3 text-center mt-5">
                                Green Warehousing <hr className="header-border-primary border-width-long" style={{margin: "10px auto"}} /></h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="outer">
                                    <div className="progress">
                                        <div className="right">
                                            <div><h4>Fully-decked Solar Panel Rooftop</h4>
                                                <p className="progress-text">Tapping on renewable energy sources reduces your reliance on electricity from the National Grid.
                                                </p> </div>
                                            <div><h4>Efficient Inventory</h4>
                                                <p className="progress-text">Scalable from the smallest micro-entrepreneur to the largest conglomerate, our in-house Warehouse Management System guarantees a 100% visibility of your goods being inbound and outbound. </p>
                                            </div>
                                            <div className="right-end"><h4>Biodegradable Packaging Options</h4>
                                                <p className="progress-text">Why stop at sustainable mobility? We offer pick-and-pack and repackaging services to enhance your efforts into building a circular economy!</p></div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-center pt-5 mb-5">
                                    <button 
                                     onClick={() => setShow(true)}
                                    className="btn" style={{ fontSize: '14px', color: '#007CDE', borderColor: '#007CDE', borderRadius: '15px' }}>
                                        Enquire Now </button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {Dialog()}
            </Container>
            <Container fluid className="footer-bottom p-0"><Footer/></Container>
        </>
    );
};
export default GreenWarehouse;
