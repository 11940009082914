import react, { useState, useEffect } from "react";
import NavMenu from "../Layout/navbar";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "../../assets/css/style.css"
import ECOServiceNav from "./partial/ECOServiceNav"
import TempImg from '../../assets/img/background/business.jpg'
import bgImg from '../../assets/img/Eco-Services/ev.jpg'
import EnquireNow from "../Resources/EnquireNow";
import Footer from "../Layout/Footer";


const EvCarthering = () => {
  const [show, setShow] = useState(false);

    const Dialog = () => {
        return <Modal
        show={show}
        onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enquire Now!!</Modal.Title>
        </Modal.Header>
      
        <Modal.Body>
          <EnquireNow
          onClose={() => setShow(false)}
          />
        </Modal.Body>
      </Modal>
    }


  return (<>
    <Container fluid>
        <Row>
          <Col>
            <NavMenu />
          </Col>
        </Row>
        </Container>
        <Container fluid>
        <Row>
          <Col>
          <div className="bg-image-WhyEV"><img src={bgImg} alt="background"/></div>  
          </Col>
        </Row>
        </Container>
        <ECOServiceNav/> 
        <Container className="min-height">
        <Row>
        <Col xs={12} md={8} lg={6}>
            <Row>
                <Col>
        <h1 className="fs-3 text-center mt-5">EV Chartering  <hr className="header-border-primary border-width-long" style={{margin: "10px auto"}} /></h1>
                      </Col>
                      </Row>
            <Row>
                <Col>
        <div className="outer">
            <div className="progress">
                <div className="right">
                    <div><h4>Dedicated Fleets</h4>
                        <p className="progress-text">Whether it is ad-hoc or planned, daily or weekly, our fleet of EVs and Driver-Partners are exclusive for you. 
                        </p> </div>
                    <div><h4>Seamless Bookings and Live Tracking</h4>
                        <p className="progress-text">Supported by Evfy’s proprietary tech platform to give you a transparent overview of your eco-logistics process.</p>
                    </div>
                    <div className="right-end"><h4>0% Direct Carbon Emissions</h4>
                        <p className="progress-text">Achieve your Environmental, Social and Governence (ESG) needs and maintain a high corporate image. Learn more about our other eco-llaborations <a href="/Eco-allobration">here.</a></p></div>
                </div>
            </div>
        </div>
        <br />
        </Col>
        </Row>
        <Row>
            <Col>
        <div className="text-center pt-5 mb-5">
        <button 
         onClick={() => setShow(true)}
      className="btn" style={{ fontSize: '14px', color: '#007CDE', borderColor: '#007CDE', borderRadius: '15px' }}>
        Enquire Now </button>
              </div> 
              </Col>
            </Row>
        </Col>
        </Row>
        {Dialog()}
        </Container>
        <Container fluid className="footer-bottom p-0"><Footer/></Container>
    </>
  );
};
export default EvCarthering;
