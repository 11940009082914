import react, { useState, useEffect } from "react";
import { Container, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import NavMenu from "../../Layout/navbar";
import EVListingNavMenu from '../partial/EVListingNav'
import "../../../assets/css/style.css"
import EVListingSec1 from "./Partial/EVListingSec1";
import Footer from "../../Layout/Footer";
import EVListingSec2 from "./Partial/EVListingSec2";
import EVListingSec3 from "./Partial/EVListingSec3";
const EVListing = (props) => {

  let ID = (props.match && props.match.params && props.match.params.id && props.match.params.id > 0) ? props.match.params.id : 0;
  const [id] = useState(ID)
  useEffect(() => {
  }, [])

  return (<>
    <Container fluid className="p-0 m-0">
      <Row>
          <Col>
            <NavMenu />
            
          </Col>
        </Row>
        </Container>
        <EVListingNavMenu />   
        <Container>
          <Row>
            <Col>
                <EVListingSec1 
                id={id}
                />
            </Col>
          </Row>
        </Container>
        <Container className="pb-5">
          <Row>
            <Col>
                <EVListingSec2 id={id}/>
            </Col>
          </Row>
        </Container>
        <Container className="pb-5">
          <Row>
            <Col>
                <EVListingSec3 />
            </Col>
          </Row>
        </Container>
        <Footer />
    </>
  );
};
export default EVListing;
