import react, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Navbar, Nav} from "react-bootstrap";
import "../../assets/css/style.css"
import Logo from '../../assets/img/logo/evfy-logo.png'
import Aos from "aos";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const NavMenu = () => {
    const [navChack, setNavCheck] = useState("false");

    const screenHeight = (window.screen.height - 150);
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        NavChange();
        setWidth(window.innerWidth);
      }, [width]);

    const NavChange = () => {
        if(window.location.pathname  != "/home") {
            setNavCheck("true")
        } else {
            const scrollChange = () => {
            {window.scrollY >=screenHeight ? setNavCheck("true") : setNavCheck("false")}
            }
            window.addEventListener('scroll', scrollChange);
        }
        if(width == 300 ){
            window.location.reload(false)
        } 
    }
    return (<>
        <Navbar collapseOnSelect expand="md" m={0} p={0} bg="dark" variant="dark" fixed={navChack == "true" ? "top" : "bottom" } className={navChack == "true" ? "fade-in" : "fade-out" }>
            <Container>
                <Navbar.Brand href="/home">
                    <img src={Logo} alt="profile_picture" width="100px"/>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="d-flex justify-content-end w-100 align-items-center ms-auto">
                        <Nav.Link href="/ev-chartering" className="ms-3 text-uppercase space">Eco-Services</Nav.Link>
                        <Nav.Link href="/global-news" className="ms-3 text-uppercase space">Global News</Nav.Link>
                        <Nav.Link href="/drive-with-us" style={{scrollMarginTop: '50px' }} className="ms-3 text-uppercase space">Driver Partners</Nav.Link>
                        {/* <Nav.Link href="/explore-question" className="ms-3 text-uppercase space">EV-erything EV</Nav.Link> */}
                        <Nav.Link href="/mission-vision" className="ms-3 text-uppercase space">About Us</Nav.Link>
                        <Nav.Link href="/contact" className="ms-3 text-uppercase space">Contact Us</Nav.Link>
                        <span className="d-flex justify-content-center align-items-center">
                        <Nav.Link href={process.env.REACT_APP_CUSTOM_NODE_ENV == "staging" ? 'https://staging-fms.evfy.sg/' : 'https://fms.evfy.sg/'} className="ms-3 text-uppercase space login-btn-style my-2 px-2">Login</Nav.Link>
                        <Nav.Link href="/fms/register" className="ms-3 text-uppercase space sign-up-btn-style my-2 px-2">Sign Up</Nav.Link>
                        </span>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            </Container>
            
        </Navbar>
        <ToastContainer/>
        </>
    );
};
export default NavMenu;
