import react, { useState, useEffect } from "react";
import { Container, Row, Col, InputGroup, FormControl, } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../assets/css/style.css"

const EvEverythingNav = () => {
    const [navChack, setNavCheck] = useState("false");

    useEffect(() => {
        NavChange();
    });

    const NavChange = () => {
        // console.log(window.location.pathname );
        if (window.location.pathname != "/home") {
            setNavCheck("true")
        } else {
            const scrollChange = () => {
                { window.scrollY >= 200 ? setNavCheck("true") : setNavCheck("false") }
            }
            window.addEventListener('scroll', scrollChange);
        }
    }
    return (
            <Container fluid className="mx-0 px-0 w-100 py-3" style={{ marginTop: '70px', backgroundColor: '#F6F6F6' }}>
                <Container>
                <Row className="d-flex justify-content-evenly subNav">
                    <Col xs={6} md={6} lg={3} className={window.location.pathname == "/explore-question"? 'text-center py-2 active  m-0 p-0 borderRight': 'text-center py-2  m-0 p-0 borderRight'}>
                        <div><a href="/explore-question" >
                        Explore Questions</a></div>
                    </Col>
                    <Col xs={6} md={6} lg={3} className={window.location.pathname == "/saved-question"? 'text-center py-2 active  m-0 p-0 borderRight': 'text-center py-2  m-0 p-0 borderRight'}>
                        <div><a href="/saved-question" >
                        Saved Questions</a></div>
                    </Col>
                    <Col xs={6} md={6} lg={3} className={window.location.pathname == "/my-question"? 'text-center py-2 active  m-0 p-0 borderRight': 'text-center py-2  m-0 p-0 borderRight'}>
                        <div><a href="/my-question" >
                        My Questions</a></div>
                    </Col>
                    <Col xs={6} md={6} lg={3} className="text-center">
                    <section style={{border: 'none !important'}}>
                        <InputGroup>
                        <FormControl
                            placeholder="Search"
                            aria-label="search"
                            aria-describedby="search"
                            style={{padding:'10px 5px'}}
                        />
                        <InputGroup.Text id="search">
                        <FontAwesomeIcon icon={faSearch} style={{ fontSize: '22px', color: '#a3a3a3' }} />
                        </InputGroup.Text>
                    </InputGroup>
                    </section>
                    </Col>
                </Row>
                </Container>
            </Container>
    );
};
export default EvEverythingNav;
