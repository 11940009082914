import react, { useState } from "react";
import ReactLoading from "react-loading";

const CustomLoading = (props) => {

    const type = props.type ? props.type: 'cylon';
    const color = props.color ?  props.color : '#2398ff';
    const height = props.height ? props.height : 80 ;
    const width = props.width ? props.width : 100 ;
    const calssName = props.calssName ? props.className : 'loading';

    return (<ReactLoading
            type={type}
            color={color}
            height={height} 
            width={width}
            className={calssName}
        />)
}
export default CustomLoading


