import React, { useRef, useState, useContext, useEffect } from 'react';

import { faThumbsUp, faCalculator, faLocationArrow, faBatteryFull, faArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Card, Stack, Button,Modal } from "react-bootstrap";
import "../../../assets/css/style.css"
import TempImg from '../../../assets/img/tempImg/temp.jpg'

import  API  from '../../../api/api'
import apiUtil from '../../../api/apiUtil'
import EnquireNow from "../../Resources/EnquireNow";

export default function PickEV()  {

  const [evVehicles, setEvVheicle] = useState([]);
  const [show, setShow] = useState(false);
  useEffect(()=>{

    callVehicleList()
  }, [])

  const callVehicleList = () => {
     let data = {
       latest: true,
       limit: 3,
     }
     API.get('/api/front_ev_vehicles', data, '')
     .then((result) => {
        apiUtil.parseResult(result, (data) => { 
          let result = data.data && data.data.length > 0 ? data.data : [];
          setEvVheicle(result)
        }, (error, type) => {
          
         });
     });
  }
  const Dialog = () => {
    return <Modal
    show={show}
    onHide={() => setShow(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Enquire Now!!</Modal.Title>
    </Modal.Header>
  
    <Modal.Body>
      <EnquireNow
      onClose={()=>setShow(false)}
      />
    </Modal.Body>
  </Modal>
}

  return (
    <Container fluid className="mt-5">
                    <h2 className="fs-3">Pick your EVs  <hr className="header-border-secondary border-width-long"/></h2>
                      <p className='sub-title'>Competitive prices* · Guaranteed jobs · Zero emissions</p>
          <Stack gap={4}>
            <Row className="d-flex justify-content-center m-auto w-100">
            {evVehicles && evVehicles.map((evVehicle) => (
              <Col key={evVehicle.id} xs={12} sm={12} md={6} lg={4} className="pb-5" >

{/* { evVehicle.m3_volumn == 0.00 || evVehicle.m3_volumn == '' || evVehicle.m3_volumn == null?
             <Card style={{ width: '100%', height:'350px', borderRadius: "15px", alignItems: 'center'}} className="cardHover">
            <div className="pickEv-img-wrapper2 hover01" >
            <a href={`/ev-listing/${evVehicle.id}`} className="remove_underline"><img alt={evVehicle.name} src={evVehicle.ev_vehicles_images.length > 0 ? `${process.env.REACT_APP_API}/${evVehicle.ev_vehicles_images[0].img_url}`:TempImg} className="pickev-img"/></a>
              </div>
              <Card.Body className='ev_pickup_cardbody'>
                <a href={`/ev-listing/${evVehicle.id}`} className="remove_underline"><Card.Title className="text-center pt-3">{evVehicle.name && evVehicle.name}</Card.Title></a>
                <Row className="pt-3">
                  <Col lg={8} md={8} xs={8} >
                    <Row>
                      <Col  lg={12} md={12} xs={12} className="margin_top_5">
                      <FontAwesomeIcon icon={faBatteryFull} className="pickup-icon"/>
                        <span className='ev_pickup_span'>{ evVehicle.estimated_range && evVehicle.estimated_range  } km</span> 
                      </Col>
                      <Col lg={12} md={12} xs={12} className="margin_top_5">
                      <FontAwesomeIcon icon={faArchive}  className="pickup-icon" />
                        <span className='ev_pickup_span'>{evVehicle.cargo_space && evVehicle.cargo_space[0] && JSON.parse(evVehicle.cargo_space).cargo_space[0].key1 + ' x ' +
                                JSON.parse(evVehicle.cargo_space).cargo_space[0].key2 + ' x ' +
                                JSON.parse(evVehicle.cargo_space).cargo_space[0].key3}</span>
                        
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={4} md={4} xs={4}>
                    <Row>
                      <Col  lg={12} md={12} xs={12}>
                      <div className="ev_pick_price">${evVehicle.cost && parseFloat(evVehicle.cost)}</div><div className="ev_pickup_span" style={{marginTop:"-5px"}}>monthly</div>
                      </Col>
                     
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
              </Card>
: */}
<Card style={{ width: '100%', height:'350px', borderRadius: "15px", alignItems: 'center'}} className="cardHover">
<div className="pickEv-img-wrapper2 hover01" >
<a href={`/ev-listing/${evVehicle.id}`} className="remove_underline"><img alt={evVehicle.name} src={evVehicle.ev_vehicles_images.length > 0 ? `${process.env.REACT_APP_API}/${evVehicle.ev_vehicles_images[0].img_url}`:TempImg} className="pickev-img"/></a>
  </div>
  <Card.Body className='ev_pickup_cardbody'>
    <a href={`/ev-listing/${evVehicle.id}`} className="remove_underline"><Card.Title className="text-center pt-3">{evVehicle.name && evVehicle.name}</Card.Title></a>
    <Row className="pt-3">
      <Col lg={12} md={12} xs={12} >
        <Row>
          <Col  lg={6} md={6} xs={6} className="margin_top_5">
          <FontAwesomeIcon icon={faBatteryFull} className="pickup-icon"/>&nbsp;
            <span className='ev_pick_m3'>{evVehicle.estimated_range && parseFloat(evVehicle.estimated_range).toFixed(2) } km</span> 
          </Col>
          <Col lg={6} md={6} xs={6} className="margin_top_5">
          <FontAwesomeIcon icon={faArchive}  className="pickup-icon" />
          {evVehicle && evVehicle.m3_volumn && evVehicle.m3_volumn == '' || evVehicle.m3_volumn == null || evVehicle.m3_volumn == 0.00? 
              <span className='ev_pick_m3'>{evVehicle.cargo_space && JSON.parse(evVehicle.cargo_space).cargo_space.length > 0 && 
                (JSON.parse(evVehicle.cargo_space).cargo_space[0].key1 ? parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key1).toFixed(2): '') + 'm x ' +
                (JSON.parse(evVehicle.cargo_space).cargo_space[0].key2 ? parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key2).toFixed(2) : '') + 'm x ' +
                (JSON.parse(evVehicle.cargo_space).cargo_space[0].key3 ? parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key3).toFixed(2) : '' ) + 'm'}</span>
                : <span className="ev_pick_m3"> {parseFloat(evVehicle.m3_volumn).toFixed(2)}m<sup>3</sup></span>  
              }
          </Col>
        </Row>
      </Col>
    </Row>
  </Card.Body>
  </Card>
{/* } */}

              </Col>
            ))}
            </Row>
            <Row className="justify-content-md-center ">
                <Col className="pt-4 text-center" xs={12} md={4} lg={3}>
                <a href="#RentalCost"><Button className="btn ev_pick_button">
                <FontAwesomeIcon icon={faCalculator}/> &nbsp;Cost Savings </Button></a> 
              </Col>
              <Col className="pt-4 text-center" xs={12} md={4} lg={3}>
              <Button className="btn ev_pick_button" onClick={() => setShow(true)}>
                <FontAwesomeIcon icon={faLocationArrow} /> &nbsp;Book a Test Drive </Button>
              </Col>
              <Col className="pt-4 text-center" xs={12} md={4} lg={3}>
              <a href="/benefit-of-ev"><Button className="btn ev_pick_button">
                <FontAwesomeIcon icon={faThumbsUp}  /> &nbsp;Benefits of EVs</Button></a>
              </Col>
            </Row> 
                     
            <Row>
                <Col>
                <p className="text-center text-muted mt-2 sub-title">*At least 50% cheaper than petrol / diesel</p>
                </Col>
            </Row>
          </Stack> 
          {Dialog()}
    </Container>
  );
}

