import react, { useState, useEffect } from "react";
import { Container, Row, Col, Carousel} from "react-bootstrap";

import axios from 'axios';
import cus1 from "../../../assets/img/clients/feedback/zesty.png"
import cus2 from "../../../assets/img/clients/feedback/coco_2.png"
import cus3 from "../../../assets/img/clients/feedback/interstaller goods.png"
const CustomerSatification = () => {
    const [partner, setPartner] = useState();

    const callPartnerApi = () => {
        let url = `${process.env.REACT_APP_API}/api/getPartners`
          axios
            .get(url)
            .then((response) => {
              setPartner(response.data.data)

            })
            .catch((error) => {
              console.log(error)
            })
        }

        useEffect(() => {
            callPartnerApi();
          }, [ ])

    return (
        <Container fluid className="mt-5 mb-5">
           <Row>
              <Col xs={12} md={8} lg={8}>
                <h2 className="fs-3">Customer Satisfaction  <hr className="header-border-primary border-width-long"/></h2>
                <p className="sub-title">Our no.1 priority</p>
                </Col>
              </Row>
              <Carousel
                fade
                controls={false}
                indicators={true}
                variant={"dark"}
              >
                <Carousel.Item>
                    <Row>
                        <Col md={4}>
                            <div className="message-pf" >
                            <img src={cus1} alt="ZestyLeaf"/>
                            </div>
                        </Col>
                        <Col md={8} className="message-leftrow">
                            <div className="message-t">
                            <p>
                              We are happy to work alongside EVFY for their innovative EV-powered
                              delivery service, which helps reduce the emissions produced from every
                              delivery.
                            </p>
                            <br />
                            <h5>- Dominic</h5>
                            <p>Founder of ZestyLeaf</p>
                            </div>
                        </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                <Row>
                        <Col md={4}>
                            <div className="message-pf" >
                            <img src={cus2} alt="Cocolicious"/>
                            </div>
                        </Col>
                        <Col md={8} className="message-leftrow">
                            <div className="message-t">
                            <p>
                              We use Evfy’s scheduled delivery, and now coupled with their EV fleet, it
                              has increased overall delivery efficiency and adds on to my company’s
                              green public image.
                            </p>
                            <br />
                            <h5>- KJ Tan</h5>
                            <p>Owner of Cocolicious</p>
                            </div>
                        </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                <Row>
                        <Col md={4}>
                            <div  className="message-pf" >
                            <img src={cus3} alt="Interstellar Goods"/>
                            </div>
                        </Col>
                        <Col md={8} className="message-leftrow">
                            <div className="message-t">
                            <p>
                              Climate change is no longer something we can afford to ignore, and every
                              business has a responsibility towards this effort. Sustainable logistics
                              is one small step towards this goal. EVs fit perfectly into this model!
                            </p>
                            <br />
                            <h5>- Daniel Yee</h5>
                            <p>Co-founder of Interstellar Goods</p>
                            </div>
                        </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
              <Container>
                <Row className="d-flex justify-content-center align-items-center">
                    {partner && partner.map((partner) => ( 
                    <Col xs="12" md="3" lg="2" className="p-2 mb-3 justiy-content-center">
                        <div style={{height:'140px', width:'80%'}}>
                        <img src={process.env.REACT_APP_API + '/' +partner.image_url} alt="partner images" className="h-100" />
                        </div>
                    </Col>

                    
                    ))}
                </Row>
              </Container>
        </Container>
    );
};
export default CustomerSatification;

