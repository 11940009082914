import { useState, useEffect } from "react";

import { Container, Row, Col, Card, Table, NavDropdown, Dropdown} from "react-bootstrap";
import "../../../assets/css/style.css"
import TempImg from '../../../assets/img/tempImg/temp.jpg'
import smoothDrive from '../../../assets/img/Rental Cost Savings/smoothdrive.png'
import DriveWithUs from '../../../assets/img/Rental Cost Savings/drive-with-us.png'
import adsBanner from '../../../assets/img/BannerAds/160x600banner_email.png'
import API from '../../../api/api';
import apiUtil from '../../../api/apiUtil';

const RentalCost = () => {

    const [evVehicles, setEvVheicles] = useState([]);
    const [vehicle, setVehicle] = useState(null)
    const [travelDistance, setTravelDistance] = useState(4030);
    const [petrolPrice, setPetrolPrice] = useState(2.90);
    const [chargingPrice, setChargingPrice] = useState(0.43);
    const [icVehicleRental, setIcVehicleRental] = useState('');
    const [icVehicleRefuelling, setIcVehicleRefuelling] = useState('');
    const [evVehicleRental, setEvVehicleRental] = useState('');
    const [evVehicleRefuelling, setEvVehicleRefuelling] = useState('');
    const [everyMonth, setEveryMonth] = useState('');
    const [everyYear, setEveryYear] = useState('');
    const [saveFuelCost, setSaveFuelCost] = useState('');

  useEffect(()=>{

    callVehicleList()
  }, [])

  const callVehicleList = () => {
     let data = {
       latest: true,
     }
     API.get('/api/front_ev_vehicles', data, '')
     .then((result) => {
        apiUtil.parseResult(result, (data) => { 
          let result = data.data && data.data.length > 0 ? data.data : [];
          setEvVheicles(result)
          if (result.length > 0) {
            let selected_vehicle = result[0]
            setVehicle(selected_vehicle)
            callRender(selected_vehicle, travelDistance, petrolPrice, chargingPrice )
          } else {
            callRender();
          }
          

        }, (error, type) => {
          
         });
     });
  }

  const callRender = (selected_vehicle, travel_distance = 1, pretol_price = 1, charging_price = 1) => {
    let ice_rental = selected_vehicle && selected_vehicle.ice_vehicle && selected_vehicle.ice_vehicle.rental_price && !isNaN(selected_vehicle.ice_vehicle.rental_price) ? parseFloat(selected_vehicle.ice_vehicle.rental_price): 1;
    let ev_rental = selected_vehicle && selected_vehicle.cost && !isNaN(selected_vehicle.cost)  ? parseFloat(selected_vehicle.cost) : 1;
    let ice_efficiency = selected_vehicle && selected_vehicle.ice_vehicle.fuel_efficiency && !isNaN(selected_vehicle.ice_vehicle.fuel_efficiency) ? parseFloat(selected_vehicle.ice_vehicle.fuel_efficiency) : 1 ;
    let ev_energy_com = selected_vehicle && selected_vehicle.energy_consumption && !isNaN(selected_vehicle.energy_consumption) ? parseFloat(selected_vehicle.energy_consumption) : 1 ;
    
    let ice_refuelling = travel_distance  / ice_efficiency * pretol_price
    let ev_refuelling = travel_distance * ev_energy_com / 100 * charging_price
    
    let ice_total = ice_rental + ice_refuelling
    let ev_total = ev_rental + ev_refuelling
    let every_month = Math.abs(ice_total - ev_total)
    let every_year = every_month * 12
    let save_fuel = Math.abs((ice_total - ev_total) / ice_total * 100 )
    setIcVehicleRental(ice_rental)
    setEvVehicleRental(ev_rental)
    setIcVehicleRefuelling(ice_refuelling)
    setEvVehicleRefuelling(ev_refuelling)
    setEveryMonth(every_month)
    setEveryYear(every_year)
    setSaveFuelCost(save_fuel)
  }



  const handleChangeVehicle = (id) => {
      let vehicle = evVehicles.filter(ev => ev.id ==  id)
      setVehicle(vehicle[0])
      callRender(vehicle[0], travelDistance, petrolPrice, chargingPrice)
  }

    return (
        <Container fluid className="mt-5">
            <h2 className="fs-3">Rental Cost Savings  <hr className="header-border-secondary border-width-long"/></h2>
            <p className="sub-title">Find out how much you save with an EV!</p>
            <Row>
                <Col xs={12} md={4} lg={4} className="p-2">
                <div className="border rounded-3 p-1">
                    <Row>
                        <Col xs={12} md={12} lg={12} className="pt-3">
                            <label className="form-label">Vehicle Model</label><br/>
                            <select value={vehicle && vehicle.id} onChange={(e) => handleChangeVehicle(e.target.value)} style={{ borderColor: '#0FBEA3', borderRadius: '8px', width: '50%', height:"40px"}} >
                               {evVehicles.length > 0 && evVehicles.map(evVehicle =>(
                                    <option key={evVehicle.id} value={evVehicle.id}>{evVehicle.name}</option>
                               ))}
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12} className="pt-3">
                            <label className="form-label">Distance Travelled</label>
                            <Row>
                                <Col xs={8} md={8} lg={8} >
                                    <input type="number" className="form-control" value={travelDistance} style={{ borderColor: '#0FBEA3', borderRadius: '8px' }} 
                                    placeholder="Km"
                                    onChange={e=>{
                                        let value = !isNaN(e.target.value) ? e.target.value : 0
                                        setTravelDistance(value)
                                        callRender(vehicle, value, petrolPrice, chargingPrice)
                                    }}
                                    />
                                </Col>
                                <Col xs={4} md={4} lg={4} >
                                    <p className="text-muted"> km/Month</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12} className="pt-3">
                            <label className="form-label">Petrol Prices</label>
                            <Row>
                                <Col xs={8} md={8} lg={8} >
                                    <input 
                                    type="number" 
                                    className="form-control" 
                                    placeholder="$"
                                    value={petrolPrice} 
                                    style={{ borderColor: '#0FBEA3', borderRadius: '8px' }}
                                    onChange={(e) => {
                                        let value = !isNaN(e.target.value) ? e.target.value : 0
                                        setPetrolPrice(value)
                                        callRender(vehicle, travelDistance, value, chargingPrice)
                                    }}
                                    />
                                </Col>
                                <Col xs={4} md={4} lg={4} >
                                    <p className="text-muted"> $/Liter</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12} className="pt-3">
                            <label className="form-label">Charging Prices</label>
                            <Row>
                                <Col xs={8} md={8} lg={8} >
                                    <input type="number" className="form-control" value={chargingPrice} style={{ borderColor: '#0FBEA3', borderRadius: '8px' }} 
                                    placeholder="$"
                                    onChange={e => {
                                        let value = !isNaN(e.target.value) ? e.target.value : 0
                                        setChargingPrice(value)
                                        callRender(vehicle, travelDistance, petrolPrice, value)
                                    }}
                                    />
                                </Col>
                                <Col xs={4} md={4} lg={4} >
                                    <p className="text-muted"> $/kWh</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </Col>
                <Col xs={12} md={8} lg={6} className="p-2">
                   <div className="border rounded-3 p-1 overflow-auto">
                    <Table className="table-borderless w-100">
                        <tr className="p-5">
                            <th></th>
                            <th className="p-3">{vehicle && vehicle.ice_vehicle && vehicle.ice_vehicle.name }</th>
                            <th className="p-3">{vehicle && vehicle.name}</th>
                        </tr>
                        <tr>
                            <td className="p-3">Rental</td>
                            <td className="p-3">${parseFloat(icVehicleRental).toFixed(2)}</td>
                            <td className="p-3">${parseFloat(evVehicleRental).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className="p-3">Refuelling</td>
                            <td className="p-3">${parseFloat(icVehicleRefuelling).toFixed(2)}</td>
                            <td className="p-3">${parseFloat(evVehicleRefuelling).toFixed(2)}</td>
                        </tr>
                    </Table>
                    <hr />
                    <p className="text-center">With an EV, you can save up to:</p>
                    <Row>
                        <Col xs={3} md={3} lg={3} className="text-center">
                            <p style={{ color: '#0FBEA3', fontSize: '24px' }}>${parseFloat(everyMonth).toFixed(2)}</p>
                            <p>Every Month</p>
                        </Col>
                        <Col xs={1} md={1} lg={1} className="text-center align-self-center">or</Col>
                        <Col xs={3} md={3} lg={3} className="text-center">
                            <p style={{ color: '#0FBEA3', fontSize: '24px' }}>${parseFloat(everyYear).toFixed(2)}</p>
                            <p>Every Year</p>
                        </Col>
                        <Col xs={1} md={1} lg={1} className="text-center align-self-center">or</Col>
                        <Col xs={3} md={3} lg={3} className="text-center">
                            <p style={{ color: '#0FBEA3', fontSize: '24px' }}>{parseFloat(saveFuelCost).toFixed(2)}%</p>
                            <p>In Fuel Costs</p>
                        </Col>
                    </Row>
                    </div>
                </Col>
                <Col xs={12} md={12} lg={2} className="ads-banner mt-2">
                    <a href="mailto:hello@evfy.sg"><img alt='ads banner of evfy' src={adsBanner} height="340vh" /></a>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} lg={5} className="mb-3">
                <a href="/smoothdrive" className="text-decoration-none">
                <div style={{position: "relative", height: "150px"}} className="cardRentalHover">
                <img style={{position: "absolute", zIndex: '-2',  objectFit:'cover'}} src={smoothDrive} alt="smooth drive" width="100%" height="150px" />
                <p  className="text-end text-white fs-4 p-2 notHoverText" style={{position: "absolute", bottom:'-10px', right:'0'}}>Smoothdrive 2.0</p>
                <div className="text-center">
                    <p  className="text-center text-white fs-4 p-2 hoverText" >Smoothdrive 2.0</p>
                    <p  className="text-center text-white fs-6 p-2 hoverText" >Practical and targeted. Scale incentives with our Driver-Partner benefits programme!</p>
                </div>
                </div>
                </a>
                </Col>
                <Col xs={12} md={6} lg={5}>
                <a href="/drive-with-us" className="text-decoration-none">
                <div style={{position: "relative", height: "150px"}} className="cardRentalHover">
                <img style={{position: "absolute", zIndex: '-2', objectFit:'cover'}} src={DriveWithUs} alt="drive with us" width="100%" height="150px" />
                <p  className="text-end text-white fs-4 p-2 notHoverText" style={{position: "absolute", bottom:'-10px', right:'0'}}>Drive With Us</p>
                <div className="text-center">
                    <p  className="text-center text-white fs-4 p-2 hoverText" >Drive With Us</p>
                    <p  className="text-center text-white fs-6 p-2 hoverText" >Join our ecosystem for a lucrative and sustainable career</p>
                </div>
                </div>
                </a>
                </Col>
            </Row>
        </Container>
    );
};
export default RentalCost;

