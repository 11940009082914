import react, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import "../../../assets/css/style.css"
import React, { useRef } from 'react';
import client_blue from '../../../assets/img/onboarding/client_blue.png'
import vendor_blue from '../../../assets/img/onboarding/vendor_blue.png'
import fleet_blue from '../../../assets/img/onboarding/fleet_blue.png'
const OnboardingChoose = (props) => {
  const isConfirmed = props.isConfirmed ? props.isConfirmed : false

  return (
          <Row className="p-0 m-0">
              <Col xs={12} md={6} lg={6} className="left-menu-container p-3 m-0 d-flex flex-column justify-content-center align-items-center">
                <h4 className="text-white text-center">How can we work together?</h4>
                <img src={client_blue} alt="client" width="300px" className="my-3 img-btn"
                onClick={()=> {
                 props.onSave(1)
                }}/>
                <img src={vendor_blue} alt="vendor" width="300px" className="my-3 img-btn"
                onClick={()=> {
                  props.onSave(2)
                }}/>
                <img src={fleet_blue} alt="fleet" width="300px" className="my-3 img-btn"
                onClick={()=> {
                  props.onSave(3)
                }}/>
                <div className="text-white mx-4 text-justify my-3 ">Choose the option that best suits you. Pay for only what you need, and our Fleet Management System will be fully integrated with your business!</div>
                <div className="text-white mx-4 text-left my-3 cursor_underline" 
                onClick={()=>{
                  props.onShow(true)
                }}
                >  See which one suits you</div>
              </Col>
              <Col xs={12} md={6} lg={6} className="right-menu-container p-0 m-0 d-flex flex-column justify-content-center align-items-center">
              {isConfirmed ? <div class="alert alert-success justify-content-center align-items-center" role="alert">
                    A confirmation mail has been sent to you. Please check your email.
                </div> : <div className="">Select one to begin</div>}
              
              </Col>
          </Row>
  );
};
export default OnboardingChoose;


