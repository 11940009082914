import { Container, Row, Col, Form, Accordion } from "react-bootstrap";
import "../../../../assets/css/style.css"
import { faMapMarkerAlt, faUserTie, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavMenu from "../../../Layout/navbar";
import AboutNav from "../../partial/AboutNav";
import { useState, useEffect } from "react";
import axios from 'axios'
import Swal from 'sweetalert2'
import toastr from 'toastr'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Footer from "../../../Layout/Footer";
import API from '../../../../api/api'
import apiUtil from "../../../../api/apiUtil";
import CustomLoading from '../../../../components/CustomLoading'
const CarrerDetails = (props) => {

    let detailID = (props.match && props.match.params && props.match.params.id && props.match.params.id > 0) ? props.match.params.id : 0;
    const [dataId, setDataId] = useState();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [carrerData, setCarrerData] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [file, setFile] = useState('');
    const [pdfLink, setPdfLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCall, setIsLoadingCall] = useState(false);
    const form = useRef();
  
    const sendEmail = (e) => {
      // e.preventDefault();
      
      // var form = document.getElementsByClassName('needs-validation')
      // if (form[0].checkValidity() === false) {
      //   form[0].classList.add('was-validated')
      //   return
      // }

      // setIsLoading(true);
      // console.log('e.target', e.target)
      // emailjs.sendForm('service_8lm954n', 'template_shff07d', e.target, '2stbnOdEztvRCrsq-')
      //   .then((result) => {
      //     console.log(result.text);
      //     setIsLoading(false);
      //   }, (error) => {
      //     console.log(error.text);
      //     setIsLoading(false);
      //   });
    };

    useEffect(() => {
        setDataId(detailID);
      if(dataId !== 0 && dataId != null) {
        getCarrerWithID();
      }
      }, [dataId])

    const setFileHandler = (e) => { 

        var reader = new FileReader();
        reader.onload = function() {
            var pdfFilevalue = reader.result;
            // console.log("answer pdfFilevalue", pdfFilevalue);
            setFile(pdfFilevalue);
          };
          var asd =reader.readAsDataURL(e.target.files[0]);
           
      }

      const getCarrerWithID = () => {
        let id = dataId;
        setIsLoadingCall(true);
          let url = `${process.env.REACT_APP_API}/api/front_careers/${id}`
        axios
          .get(url)
          .then((response) => {
            setCarrerData(response.data.data);
            setIsLoadingCall(false);
          })
          .catch((error) => {
            console.log(error)
            setIsLoadingCall(false);
          })
      }

// Send Carrer 
    const sendCarrer = (career_id) => { 
        var form = document.getElementsByClassName('needs-validation')
        if (form[0].checkValidity() === false) {
          form[0].classList.add('was-validated')
          return
        }
        setIsLoading(true);
        const payLoad = {
          name: name,
          email: email,
          phone: phoneNumber,
          address: address,
          attachment_file: file,
          career_id: career_id,
        }
        axios
          .post(`${process.env.REACT_APP_API}/api/front_candiate`, payLoad)
          .then(function (response) {
              if(response != ""){
                // console.log("response _",response.data.success); 
                if(response.data.success == true){
                    Swal.fire('Sent!', 'Thanks for your apply!! we will get back to you.', 'success');
                    setName('');
                    setEmail('');
                    setPhoneNumber('');
                    setFile('');
                    setAddress('');
                    getCarrerWithID(response.data.data.planner.id);
                    setIsLoading(false);
                }else{
                  setIsLoading(false);
                }
              }else{
                setIsLoading(false);
              }
          })
          .catch(function (error) {
            console.log('error ', error)
            setIsLoading(false);
          })
      }


  return (<>
    <Container fluid className="p-0 m-0">
       <Row>
     <Col>
       <NavMenu /> 
     </Col>
   </Row>
   </Container>
       <AboutNav />  
    <Container>
        <Row>
            <Col>
            <Row>
            <Col md={2} lg={2}></Col>
            <Col md={8} lg={8}>
                <Row className="mt-5 mb-5 border carrer-title">
                <Col xs={12} md={12} lg={12} >
                <h5 className="fs-3">{isLoadingCall ? <CustomLoading /> :  carrerData && carrerData.title && carrerData.title}</h5>
                <p className="text-muted fw-3">EVFY Singapore</p>
                </Col>
                <Col xs={4} md={4} lg={4}><FontAwesomeIcon icon={faMapMarkerAlt} style={{ fontSize: '16px' }} />&nbsp; {isLoadingCall ? <CustomLoading /> :  carrerData && carrerData.location && carrerData.location}</Col>
                <Col xs={4} md={4} lg={4}><FontAwesomeIcon icon={faClock} style={{ fontSize: '16px'}} />&nbsp; {isLoadingCall ? <CustomLoading /> :  carrerData && carrerData.job_type && carrerData.job_type}</Col>
                <Col xs={4} md={4} lg={4}></Col>
                </Row>
              </Col>
              <Col md={2} lg={2}></Col>
              </Row>
              <Row>
              <Col md={2} lg={2}></Col>
              <Col>
              <div>
                {isLoadingCall ? <CustomLoading /> : carrerData && carrerData.description && <div dangerouslySetInnerHTML={{ __html: carrerData.description }} />}
              </div>
              <h4 className="text-center pt-4 mb-3 pb-2">
                Apply for this job <hr className="header-border-secondary border-width-long" style={{margin: "10px auto"}} />
              </h4>
              </Col>
              <Col md={2} lg={2}></Col>
              </Row>
                <Form ref={form} autoComplete="off" onSubmit={sendEmail} className="needs-validation create-form" noValidate>
                  <Row>
                    <Col md={2} lg={2}></Col>
                    <Col>
                    <Row>
                    <Col xs={12} md={12} lg={12} className="pt-3">
                      <label className="form-label">Name</label>
                      <input type="text" name="name" placeholder="Name"  className="form-control w-100" value={name} onChange={(e) => setName(e.target.value)} style={{borderColor:'#0FBEA3', borderRadius: '8px'}} required/>
                    </Col>
                    <Col xs={12} md={12} lg={12} className="pt-3">
                      <label className="form-label">Email Address</label>
                      <input type="email" name="email_name" placeholder="Email"  className="form-control w-100" value={email} onChange={(e) => setEmail(e.target.value)} style={{borderColor:'#0FBEA3', borderRadius: '8px'}} required/>
                    </Col>
                    <Col xs={12} md={12} lg={12} className="pt-3">
                      <label className="form-label">Phone </label>
                      <input type="number" name="phone_number" placeholder="+65..."  className="form-control w-100" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} style={{borderColor:'#0FBEA3', borderRadius: '8px'}} required/>
                    </Col>  
                    </Row>
                    </Col>
                    <Col md={2} lg={2}></Col>                                     
                  </Row> 
                  <Row className="pb-5 pt-5">
                  <Col md={2} lg={2}></Col> 
                      <Col className="pt-2" xs={6} md={3} lg={3}>
                          <input type="file" name="file" className="custom-file-input" accept=".doc,.pdf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={(e) => setFileHandler(e)}
                          />
                      </Col>
                      <Col md={2} lg={2}></Col> 
                  </Row>
                  <Row className="pb-5 pt-5">
                      <Col className="pt-2 text-center" xs={12}>
                          <button onClick={()=> {
                            sendCarrer(carrerData.id);
                          }} className="btn" >
                            <input type="submit" className="btn" style={{ paddingLeft: "20px", paddingRight: "20px", color:"#FFF", fontSize: '18px', backgroundColor: '#0FBEA3', borderColor: '#0FBEA3', borderRadius: '15px' }} value={isLoading ? 'Loading…' : 'Apply Now'} disabled={isLoading ? true : false}/>
                          </button>
                      </Col>
                  </Row>
                </Form>
            </Col>
        </Row>
    </Container>
    <Footer/>
    </>
  );
};
export default CarrerDetails;


{/* <FontAwesomeIcon icon={faUpload} style={{ fontSize: '14px', color: '#0FBEA3', }} /> */}