import { Container, Row, Col, } from "react-bootstrap";
import TempImg from '../../../../assets/img/tempImg/temp.jpg'
import "../../../../assets/css/style.css"
import bgImg from "../../../../assets/img/background/about_bg.jpg"
import logoImg from "../../../../assets/img/logo/evfy-logo-square.png"
const MVSec1 = (props) => {

  return (<>
    <Container fluid className="m-0">
        <Row>
            <Col lg={12} className="p-0">
            <div style={{position: "relative", height: "350px"}}>
                <img src={bgImg} style={{position: "absolute", width:"100%", height: "100%", zIndex: "-4", filter:"brightness(0.7)"}}/>
                <div className="p-5">
                <h1 className="text-center pb-3 text-white">About Evfy <hr className="header-border-secondary border-width-long mx-auto mt-2" /></h1>
                <p className="mb-2 mb-center text-center text-white"><em>Pronounced <strong>[E-V-fahy]</strong></em></p>
                <p className="mb-2 mb-center text-center text-white" ><em>Verb (used with subject), <strong>EV·fied, EV·fy·ing.</strong></em></p>
                <p className="mb-2 mb-center text-center text-white"><em>To convert to EV (electric vehicle)</em></p>
                <p className="mb-center text-center text-white"><em>To effect change, reduce carbon emission or carbon
                    footprint</em></p>
                </div>
            </div>
            </Col>
        </Row>
    </Container>
    <Container>
          <Row className="align-items-center">
            <Col xs={12} md={3} lg={4} className="mt-5 d-flex justify-content-center">
                <img alt="logo" src={logoImg} style={{ width:"80%", height: "100%", borderRadius:'20px'}}/>
            </Col>
            <Col xs={12} md={9} lg={8} className="mt-5">
            <p className="">Last-mile logistics does not need to take a back seat,
                nor does it need to be at the expense of Mother Nature. </p>
            <p className="">Tapping on our sizeable EV fleet and green warehouses,
                we aim to be the breath of fresh air that infuses sustainable mobility and
                green logistics
                into the supply chain ecosystem. </p>
            </Col>
        </Row>
    </Container>
    </>
  );
};
export default MVSec1;
