import { faBatteryFull, faArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "../../../../assets/css/style.css"
import TempImg from '../../../../assets/img/tempImg/temp.jpg'
import API from '../../../../../src/api/api';
import apiUtil from "../../../../../src/api/apiUtil";
import { useEffect, useState } from "react";

const EVListingSec3 = () => {

  const [evVehicles, setEvVheicle] = useState([]);

  useEffect(()=>{

    callVehicleList()
  }, [])

  const callVehicleList = () => {
     let data = {
       latest: true,
       limit: 3,
     }
     API.get('/api/front_ev_vehicles', data, '')
     .then((result) => {
        apiUtil.parseResult(result, (data) => { 
          let result = data.data && data.data.length > 0 ? data.data : [];
          setEvVheicle(result)
        }, (error, type) => {
          
         });
     });
  }

  return (
    <Container fluid classname="p-0 m-0">
      <h3 className="text-center fs-3 mb-4">Check out similar models! <hr className="header-border-secondary border-width-long mx-auto mt-2" /></h3>
      <Row className="d-flex justify-content-center m-auto w-100">
        {evVehicles && evVehicles.map((evVehicle) => (
          <Col xs={12} sm={12} md={6} lg={4} className="pb-5" >
         {/* { evVehicle.m3_volumn == 0.00 || evVehicle.m3_volumn == '' || evVehicle.m3_volumn == null?
             <Card style={{ width: '100%', height:'350px', borderRadius: "15px", alignItems: 'center'}} className="cardHover">
            <div className="pickEv-img-wrapper2 hover01" >
            <a href={`/ev-listing/${evVehicle.id}`} className="remove_underline"><img alt={evVehicle.name} src={evVehicle.ev_vehicles_images.length > 0 ? `${process.env.REACT_APP_API}/${evVehicle.ev_vehicles_images[0].img_url}`:TempImg} className="pickev-img"/></a>
              </div>
              <Card.Body className='ev_pickup_cardbody'>
                <a href={`/ev-listing/${evVehicle.id}`} className="remove_underline"><Card.Title className="text-center pt-3">{evVehicle.name && evVehicle.name}</Card.Title></a>
                <Row className="pt-3">
                  <Col lg={8} md={8} xs={8} >
                    <Row>
                      <Col  lg={12} md={12} xs={12} className="margin_top_5">
                      <FontAwesomeIcon icon={faBatteryFull} className="pickup-icon"/>
                        <span className='ev_pickup_span'>{evVehicle.estimated_range && evVehicle.estimated_range  } km</span> 
                      </Col>
                      <Col lg={12} md={12} xs={12} className="margin_top_5">
                      <FontAwesomeIcon icon={faArchive}  className="pickup-icon" />
                        <span className='ev_pickup_span'>{evVehicle.cargo_space && JSON.parse(evVehicle.cargo_space).cargo_space[0].key1 + ' x ' +
                                JSON.parse(evVehicle.cargo_space).cargo_space[0].key2 + ' x ' +
                                JSON.parse(evVehicle.cargo_space).cargo_space[0].key3}</span>
                        
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={4} md={4} xs={4}>
                    <Row>
                      <Col  lg={12} md={12} xs={12}>
                      <div className="ev_pick_price">${evVehicle.cost && parseFloat(evVehicle.cost)}</div><div className="ev_pickup_span" style={{marginTop:"-5px"}}>monthly</div>
                      </Col>
                     
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
              </Card>
: */}
<Card style={{ width: '100%', height:'350px', borderRadius: "15px", alignItems: 'center'}} className="cardHover">
<div className="pickEv-img-wrapper2 hover01" >
<a href={`/ev-listing/${evVehicle.id}`} className="remove_underline"><img alt={evVehicle.name} src={evVehicle.ev_vehicles_images.length > 0 ? `${process.env.REACT_APP_API}/${evVehicle.ev_vehicles_images[0].img_url}`:TempImg} className="pickev-img"/></a>
  </div>
  <Card.Body className='ev_pickup_cardbody'>
    <a href={`/ev-listing/${evVehicle.id}`} className="remove_underline"><Card.Title className="text-center pt-3">{evVehicle.name && evVehicle.name}</Card.Title></a>
    <Row className="pt-3">
      <Col lg={12} md={12} xs={12} >
        <Row>
          <Col  lg={6} md={6} xs={6} className="margin_top_5">
          <FontAwesomeIcon icon={faBatteryFull} className="pickup-icon"/>&nbsp;
            <span className='ev_pick_m3'>{evVehicle.estimated_range && parseFloat(evVehicle.estimated_range).toFixed(2)} km</span> 
          </Col>
          <Col lg={6} md={6} xs={6} className="margin_top_5">
          <FontAwesomeIcon icon={faArchive}  className="pickup-icon" />
            {evVehicle && evVehicle.m3_volumn && evVehicle.m3_volumn == '' || evVehicle.m3_volumn == null || evVehicle.m3_volumn == 0.00? 
              <span className='ev_pick_m3'>{evVehicle.cargo_space && JSON.parse(evVehicle.cargo_space).cargo_space.length > 0 && 
                (JSON.parse(evVehicle.cargo_space).cargo_space[0].key1 ? parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key1).toFixed(2): '') + 'm x ' +
                (JSON.parse(evVehicle.cargo_space).cargo_space[0].key2 ? parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key2).toFixed(2) : '') + 'm x ' +
                (JSON.parse(evVehicle.cargo_space).cargo_space[0].key3 ? parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key3).toFixed(2) : '' ) + 'm'}</span>
                : <span className="ev_pick_m3"> {parseFloat(evVehicle.m3_volumn).toFixed(2)}m<sup>3</sup></span>
              // <span className='ev_pickup_span'>{evVehicle.cargo_space && evVehicle.cargo_space[0] && 
              //     parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key1).toFixed(1) + 'm x ' +
              //     parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key2).toFixed(1) + 'm x ' +
              //     parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key3).toFixed(1) + 'm'}</span>
              //     : <span className="ev_pick_m3"> {parseFloat(evVehicle.m3_volumn).toFixed(1)}m<sup>3</sup></span>  
              }
            
          </Col>
        </Row>
      </Col>
    </Row>
  </Card.Body>
  </Card>
{/* } */}

          </Col>
        ))}
      </Row>
    </Container>
  );
};
export default EVListingSec3;

