import react, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../../../assets/css/style.css"
import React, { useRef } from 'react';
import client_white from '../../../assets/img/onboarding/client_white.png'
import vendor_blue from '../../../assets/img/onboarding/vendor_blue.png'
import fleet_blue from '../../../assets/img/onboarding/fleet_blue.png'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import wmsApi from "../../../api/wmsApi";
import apiUtil from "../../../api/apiUtil";
import Swal from "sweetalert2";
import { default as ReactSelect} from "react-select";
import { components} from "react-select";
import { set } from "react-ga";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const OnboardingClient = (props) => {
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("")
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [companyCode, setCompanyCode] = useState("")

  const [emailValidated, setEmailValidated] = useState(false)
  const [companyNameValidated, setCompanyNameValidated] = useState(false)
  const [phoneValidated, setPhoneValidated] = useState(false)
  const [tellUsMore, setTellUsMore] = useState('')
  const [deliveryFreq, setDeliveryFreq] = useState([])
  const [businessNature, setBusinessNature] = useState([])
  
  


  const callCreateApi = (row, callback = null) => {
    // const { accessToken } = this.context;

    setIsLoading(true)
      wmsApi.create('customer_create', row, null)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            setIsLoading(false)
              if(callback){
                callback(data);
              }
          }, (error, type) => {
            
            if (Array.isArray(error)) {
              error = error[0]
            }
            if (error == 'Company code already exists in the company!') {
              error = 'Company code already exists in the company! Change your company name.'
            }
            if (error == 'Username is already taken. Try Another') {
              error = 'Email is already taken. Try Another'
            }
            apiUtil.toast(error, null,  'error');
            setIsLoading(false)
              if(callback){
                callback();
              }
          });
      });
  }

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    setEmailValidated(false)
    setCompanyNameValidated(false)
    setPhoneValidated(false)

    let validatedEmail = true
    if(!email || email == '' || !apiUtil.emailValidation(email)) {
      setEmailValidated(true)
      validatedEmail = false
    }

    if(!companyName || companyName == '') {
      setCompanyNameValidated(true)
    }
    let validPhone = true
    if(phone && (phone != 65 || phone != "65" )  && !apiUtil.phoneValidationWithPlus(phone)) {
      setPhoneValidated(true)
      validPhone = false
    }


    if (form.checkValidity() === true && validatedEmail && validPhone) {
      let business_nature = ''
      if(businessNature.length > 0) {
        businessNature.map(bn => business_nature += bn.value +',')
      }
      business_nature = business_nature.slice(0, -1);
      let data = {
        company_name: companyName,
        company_code: companyName.replace(" ","").substring(0,4),
        country: "Singapore", 
        contact_no1: phone == 65 ? '' : `+${phone}`,
        company_email: email,
        delivery_frequency: deliveryFreq.value ? deliveryFreq.value: '',
        about_package: tellUsMore,
        business_nature,
        admin_email: email,
        is_active: false,
        application_company_id: (window.location.hostname === 'localhost' || window.location.hostname === "127.0.0.1" || window.location.hostname.includes('staging')) ? 117 : 354 ,
        application_registration_ids: "1",
      };
     callCreateApi(data, (result) => {
      if(result) {
        props.onSave(null, true)
      }
     })
    }
  }

  return (
          <Row className="p-0 m-0">
              <Col xs={12} md={6} lg={6} className="left-menu-container p-3 m-0 d-flex flex-column align-items-center">
                <h4 className="text-white text-center">How can we work together?</h4>
                <img src={client_white} alt="client" width="300px" className="my-3 img-btn"
                onClick={()=> {
                  props.onSave(1)
                }}/>
                <img src={vendor_blue} alt="vendor" width="300px" className="my-3 img-btn"
                onClick={()=> {
                  props.onSave(2)
                }}/>
                <img src={fleet_blue} alt="fleet" width="300px" className="my-3 img-btn"
                onClick={()=> {
                  props.onSave(3)
                }}/>
                <div className="text-white mx-4 text-justify my-3 ">Choose the option that best suits you. Pay for only what you need, and our Fleet Management System will be fully integrated with your business!</div>
                <div className="text-white mx-4 text-left my-3 cursor_underline" 
                onClick={()=>{
                  props.onShow(true)
                }}>See which one suits you</div>
              </Col>
              <Col xs={12} md={6} lg={6} className="right-menu-container p-0 m-0 d-flex flex-column align-items-center py-3 px-3">
              <form onSubmit={handleSubmit} class="row g-3 needs-validation" novalidate>
                <h3 className="text-left w-100 px-3">Share more with us!</h3>
                <div class="mb-3 w-100 px-3">
                  <label class="form-label fw-bold">Country*</label>
                    <select class="form-select w-100" aria-label="Default select example" required>
                      <option value="" selected>--- Select ---</option>
                      <option value="Singapore">Singapore</option>
                    </select>
                </div>
                <div class="mb-3 w-100 px-3">
                  <label class="form-label fw-bold">Company Name*</label>
                  <input type="text" class="form-control w-100"  placeholder="Company Name" required
                  value={companyName}
                  onChange={e => setCompanyName(e.target.value.trim().length === 0 ? '' : e.target.value)}
                  />
                </div>
                {/* <div class="mb-3 w-100 px-3">
                  <label class="form-label fw-bold">Company Code*</label>
                  <input type="text" class="form-control w-100"  placeholder="Company Code" required
                  value={companyCode}
                  onChange={e => setCompanyCode(e.target.value)}
                  />
                </div> */}
                <div class="mb-3 w-100 px-3">
                  <label class="form-label fw-bold">Phone Number</label>
                  <PhoneInput
                    className={`custom-phone-input ${phoneValidated ? "input-error" : ''}`}
                    country={"sg"}
                    enableSearch={false}
                    disableDropdown={true}
                    value={phone}
                    countryCodeEditable={false}
                    onChange={(phone) =>{
                      setPhone(phone)
                      setPhoneValidated(false)
                    }}
                  />
                </div>
                <div className={`mb-3 w-100 px-3 ${emailValidated ? "input-error" : ''}`}>
                  <label class="form-label fw-bold">Email Address*</label>
                  <input type="email" class="form-control w-100"  placeholder="marry@evfy.sg" required
                  value={email}
                  onChange={e=>{
                    setEmail(e.target.value)
                    setEmailValidated(false)
                  }}
                  />
                </div>
                <div class="mb-3 w-100 px-3">
                  <label class="form-label fw-bold">Delivery Frequency</label>
                   <ReactSelect
                   className="basic-single"
                   classNamePrefix="select"
                   isClearable={true}
                   isSearchable={true}
                   value={deliveryFreq}
                   onChange={selected => {
                     if(selected == null) {
                       setDeliveryFreq([])
                     } else {
                       setDeliveryFreq(selected)
                     }
                   }}
                   options={[{value:"On Demand", label:"On Demand"}, {value:"Weekly", label:"Weekly"}, {value:"Daily", label:"Daily"}, {value:"Others", label:"Others"}]}
                   />
                </div>
                <div class="mb-3 w-100 px-3">
                  <label class="form-label fw-bold">Nature of Business</label>
                  <ReactSelect
                   isMulti
                   className="basic-single"
                   classNamePrefix="select"
                   isClearable={true}
                   isSearchable={true}
                   components={{Option}}
                   hideSelectedOptions={false}
                   value={businessNature}
                   onChange={selected => {
                     if(selected == null) {
                      setBusinessNature([])
                     }else{
                       setBusinessNature(selected)
                     }
                   }}
                   options={[{value:"B2B", label:"B2B"}, {value:"B2C", label:"B2C"}, {value:"E-Commerce", label:"E-Commerce"}, {value:"F&B", label:"F&B"}, {value:"Retail & Luxury", label:"Retail & Luxury"}, {value:"FMCG", label:"FMCG"}, {value:"Others", label:"Others"}]}
                   />
                </div>
                <div class="mb-3  w-100 px-3">
                  <label class="form-label fw-bold">Tell us more about your packages</label>
                  <textarea class="form-control w-100" rows="3" 
                  onChange={e=>{
                    setTellUsMore(e.target.value)
                  }}
                  ></textarea>
                </div>
                <div class="mb-3 w-100 px-3 d-flex flex-column align-items-center">
                    <button className="btn contact_button"
                    type="submit"
                    disabled={isLoading}
                    // onClick={()=> {
                    //   props.onSave(null, true)
                    // }}
                    > {isLoading? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :"CONTACT US"}</button>
                  <span>Already have an account? Log in <a href="https://fms.evfy.sg/">here</a></span>
                </div>
              </form>
              </Col>
          </Row>
  );
};
export default OnboardingClient;


