import react, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, Card, Form, Button, Table, } from "react-bootstrap";
import {Link} from 'react-router-dom';
import caltex from "../../../assets/img/charging/caltex.png"
import charge from "../../../assets/img/charging/charge.png"
import Greenlots from "../../../assets/img/charging/Greenlots.png"
import SP from "../../../assets/img/charging/SP-Group-2.png"
import TempImg from '../../../assets/img/tempImg/temp.jpg'
import axios from "axios";
import moment from 'moment';
import CustomLoading from '../../../components/CustomLoading.jsx'

const EVChargingRate = () => {
    let date =[];
    let insightTitle = '';
    const [posts, setPosts] = useState([]);
    const [singlePosts, setSinglePosts] = useState([]);
    const [activeBotton, setActiveBottom] = useState("3");
    const [evPrices, setEvPrices] = useState([]);
    const [evPriceLoading, setEvPriceLoading] = useState(false)
    const [insightLoading, setInsightLoading] = useState(false)
    const [evPricesFilter, setEvPricesFilter] = useState([]);
    const [evAcPrices, setEvAcPrices] = useState([]);
    const [evDcPrices, setEvDcPrices] = useState([]);

    const getPosts = (id = 0) => {
        setInsightLoading(true)
        let url = `${process.env.REACT_APP_API}/api/front_posts`;
        const params = {
            status: 'internal',
            limit:4,
            published:true,
            latest:true
          };
        axios
          .get(url, {params})
          .then((response) => {
              setPosts(response.data.data);
              setSinglePosts(response.data.data[0]);
              setInsightLoading(false)
          })
          .catch((error) => {
            console.log(error);
            setInsightLoading(false)
          });
      };

    useEffect(() => {
        getPosts();
        getEVchargingPrices(40.001, 100000)
        getEVchargingfilter(40.001, 100000)
    },[]);

    const getEVchargingPrices = (minKw = '', maxKw = '') => {
        setEvPriceLoading(true);
        let url = `${process.env.REACT_APP_API}/api/front_evprices`;
        const params = {
            limit: 4,
            latest: true,
            group_by_provider: true,
            max_kw: maxKw,
            min_kw: minKw,
            status: true
          };
        axios
          .get(url, {params})
          .then((response) => {
              setEvPrices(response.data.data)
              setEvPriceLoading(false)
          })
          .catch((error) => {
            console.log(error);
            setEvPriceLoading(false)
          });
    }

    const getEVchargingfilter = (minKw = '', maxKw = '') => {
        let url = `${process.env.REACT_APP_API}/api/ev_charging_price`;
        const params = {
            latest: true,
            group_by_provider: true,
            max_kw: maxKw,
            min_kw: minKw,
            filter: true,
            status: true,
          };
        axios
          .get(url, {params})
          .then((response) => {
            let json_push = [];
            for (var i in response.data.data) {
                if(response.data.data[i].acPrice != '' || response.data.data[i].dcPrice != '') {
                    json_push.push(response.data.data[i])
                }
            }
            var json_data = json_push;
            var dcMax = [];
            var acMax = [];
           
            var ev_ac_data = '';
            var ev_dc_data = '';
            for (var i in json_data) {
                let acArray = [];
                let dcArray = [];
                acArray = json_data[i].acPrice  == '' || json_data[i].acPrice  == null ? [] : json_data[i].acPrice;
                if(acArray != '') {
                for (var key in acArray) {
                    if (acArray.hasOwnProperty(key)) {
                        ev_ac_data = acArray[key].ev_ac_cost  == '' || acArray[key].ev_ac_cost  == null ? 0 : acArray[key].ev_ac_cost ;
                        }
                    }
                } else {
                    ev_ac_data = 0 ;
                }
                acMax[i] = ev_ac_data;

                dcArray = json_data[i].dcPrice  == '' || json_data[i].dcPrice  == null ? [] : json_data[i].dcPrice;
                if(dcArray != '') {
                for (var key in dcArray) {
                    if (dcArray.hasOwnProperty(key)) {
                        ev_dc_data = dcArray[key].ev_dc_cost  == '' || dcArray[key].ev_dc_cost  == null ? 0 : dcArray[key].ev_dc_cost ;
                        }
                    }
                } else {
                    ev_dc_data = 0 ;
                }
                dcMax[i] = ev_dc_data;

                // acArray = json_data[i].acPrice  == '' || json_data[i].acPrice  == null ? [] : json_data[i].acPrice;
                // acArray.foreach((data) => 
                //     ev_ac_data = data.ev_ac_cost
                // )
                // acMax[i] = ev_ac_data == '' || ev_ac_data  == null? 0 : ev_ac_data;

                // dcArray = json_data[i].dcPrice  == '' || json_data[i].dcPrice  == null ? [] : json_data[i].dcPrice;
                // dcArray.map((data) => 
                //     ev_dc_data = data.ev_dc_cost
                // )
                // dcMax[i] = ev_dc_data == '' || ev_dc_data  == null? 0 : ev_dc_data;
            }
              setEvPricesFilter(json_push)
              setEvAcPrices(acMax);
              setEvDcPrices(dcMax);
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const callTable = () =>{
        return <div className="d-flex justify-content-center flex-column align-items-center"><Card style={{ width: '100%', borderRadius: '15px', height: '380px' }}>
                        <Card.Body className="d-flex flex-column justify-content-between p-0 m-0 scrollTab">
                            <Table className="text-center justify-content-center align-items-center ">
                                <tbody>
                                    <tr>
                                        <th className="align-middle tb-header-height">Provider</th>
                                        <th className="align-middle">AC</th>
                                        <th className="align-middle">DC</th>
                                    </tr>
                                    { evPricesFilter.map((price , i) => (
                                        (evPrices.length == 4 && evPrices.length === i + 1) ?
                                        <tr className="border-0">
                                            <td className="align-middle border-0 w-h-adj"><img alt={price.name} src={price && price.logo ? `${process.env.REACT_APP_API}/${price.logo}`: TempImg} className="img-control"/></td>
                                            <td className="align-middle border-0 w-h-adj">{evAcPrices && evAcPrices[i] > 0? `$${parseFloat( evAcPrices[i]).toFixed(2)}`: '-'}</td>
                                            <td className="align-middle border-0 w-h-adj wd">{evDcPrices && evDcPrices[i] > 0? `$${parseFloat( evDcPrices[i]).toFixed(2)}`: '-'}</td>
                                        </tr> :
                                        <tr>
                                        <td className="align-middle w-h-adj"><img alt={price.name} src={price && price.logo ? `${process.env.REACT_APP_API}/${price.logo}`: TempImg} className="img-control"/></td>
                                        <td className="align-middle w-h-adj wd">{evAcPrices && evAcPrices[i] > 0? `$${parseFloat( evAcPrices[i]).toFixed(2)}`: '-'}</td>
                                        <td className="align-middle w-h-adj wd">{evDcPrices && evDcPrices[i] > 0? `$${parseFloat( evDcPrices[i]).toFixed(2)}`: '-'}</td>
                                    </tr> 
                                    ))}
                                    
                                </tbody>
                            </Table>
                            
                        </Card.Body>
                    </Card>
                    <div className="text-end  mt-2"  style={{ width: '90%'}}>
                        <a href="/ev-charging-prices" className="text-secondary text-decoration-none text-uppercase" style={{fontSize:"13px"}}>EV Charging Prices &#62; </a>
                    </div>
                    </div>
    }

    return (
        <Container fluid className="mt-4">
            <Row>
                <Col xs={12} sm={12} md={12} lg={5} className="px-3">
                    <div>
                    <h2 className="fs-3 pt-3 mb-3">
                        EV Charging Prices <hr className="header-border-secondary border-width-long"/>
                    </h2>
                    <div>
                        <p className="filter-text">
                            <Row className="p-0 m-0">
                            <Col xs={12} md={2} lg={2} className="p-0 m-0 mb-2">
                            <span>Filter by:</span>
                            </Col>
                            <Col xs={12} md={10} lg={10} className="p-0 m-0">
                            <button type="button" className={activeBotton == "1" ? "btn btn-active-kw mb-2" : "btn tag-kw mb-2"} name="battery_capacity"
                                value="1"
                                onClick={e=> {
                                    setActiveBottom("1")
                                    getEVchargingfilter(0, 10)
                                }}>≤ 10kW
                            </button>
                            <button type="button" className={activeBotton == "2" ? "btn btn-active-kw mb-2" : "btn tag-kw mb-2"} name="battery_capacity"
                                value="2"
                                onClick={e=> {
                                    setActiveBottom("2")
                                    getEVchargingfilter(10.001, 40)
                                }}> 10-40kW
                            </button>
                            <button type="button" className={activeBotton == "3" ? "btn btn-active-kw mb-2" : "btn tag-kw mb-2"} name="battery_capacity"
                                value="3"
                                onClick={e=> {
                                    setActiveBottom("3")
                                    getEVchargingfilter(40.001, 100000)
                                }}> &#62; 40kW
                            </button>
                            </Col>
                            </Row>
                        </p>
                    </div>
                    {evPriceLoading ? <CustomLoading/> : callTable()}
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} className="px-3">
                    {insightLoading ? <CustomLoading /> : <>
                    <h2 className="fs-3 pb-2 pt-3">Insights <hr className="header-border-secondary border-width-short"/></h2>
                <Row>
                <Col xs={12} md={12} lg={12}>
                <Row>
                    { posts && 
                    posts.map((data) => 
                    <Col xs={12} md={12} lg={12} style={{paddingTop:'3px'}}>
                         <span style={{display:'none'}}>{insightTitle = data.title && data.title.replace(/\s/g, '-')}</span>
                         <div className="d-flex mt-3 align-items-center border rounded border-shadow">
                         <a  className="col-md-3 col-xs-12" href={`/insight/${data.id}/${insightTitle}`} style={{textDecoration:'none', color:'#000'}}>
                         <div className="blog-img-wrapper-internal hover01">
                            <img src={data && data.post_images && data.post_images.length > 0 ? process.env.REACT_APP_API + '/' +data.post_images[0].image_url : TempImg} className="blog-img" alt={data.title} />
                        </div>
                        </a>
                        <div className="ps-2">
                            <a href={`/insight/${data.id}/${insightTitle}`} style={{textDecoration:'none', color:'#000'}}><h5 className="fs-6">{data.title.substring(0, 100)} ...</h5></a>
                            <span style={{color:'#C4C4C4',fontSize:"12px"}}>{moment(data.updated_at).format('LL')}</span>
                        </div>
                        <hr />
                        </div>
                    </Col>
                    ) 
              }
                </Row>
              
                <div className="w-100 text-end  mt-2">
                    <a href="/insights" className="text-decoration-none text-secondary text-uppercase " style={{fontSize:"13px"}}>view more insights &#62;</a>
                </div>
                </Col>
                </Row></>
                }
                </Col>
            </Row>
        </Container>

    );
};
export default EVChargingRate;
