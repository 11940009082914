import { Container, Row, Col, Button } from "react-bootstrap";
import CirclesLife from '../../../../assets/img/drive/Circles-Life.png';
import GorillaMobile from '../../../../assets/img/drive/Gorilla-Mobile.png';
import M1 from '../../../../assets/img/drive/M1.png';
import Starhub from '../../../../assets/img/drive/Starhub.png'
import Esso from '../../../../assets/img/drive/EssoLogo.png';
import Cartimes from '../../../../assets/img/drive/cartimes.png'
import { faSearch, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SmoothDriveTwo = () => {

    return (
        <Container className="mt-5">
           <Row>
               <Col>
               <Row>
                   <Col>
                   <h3 className="fs-3 text-center mb-4"> Mobile Plans <hr className="header-border-secondary border-width-long mx-auto"/></h3>
                   </Col>
               </Row>
               <Row className="d-flex justify-content-center align-items-center">
                   <Col lg={4} md={4} sm={6} xs={12} className="mb-5 d-flex justify-content-center">
                   <img alt="CirclesLife" src={CirclesLife} className="mb-2" height="150px"/>
                   <div className="text-center">
                   {/* <a href="" className="learn-more">Learn More</a>
                   <Button className="btn btn-apply text-white ms-2">Apply Now</Button> */}
                   </div>
                   </Col>
                   <Col lg={4} md={4} sm={6} xs={12} className="mb-5 d-flex justify-content-center">
                   <img alt="GorillaMobile" src={GorillaMobile} className="mb-2" width="250px" />
                   <div className="text-center">
                   {/* <a href="" className="learn-more">Learn More</a>
                   <Button className="btn btn-apply text-white ms-2">Apply Now</Button> */}
                   </div>
                   </Col>
                   <Col lg={4} md={4} sm={6} xs={12} className="mb-5 d-flex justify-content-center">
                   <img alt="evfy" src={M1} className="mb-2" height="150px"/>
                   <div className="text-center">
                   {/* <a href="" className="learn-more">Learn More</a>
                   <Button className="btn btn-apply text-white ms-2">Apply Now</Button> */}
                   </div>
                   </Col>
                   <Col lg={4} md={4} sm={6} xs={12} className="mb-5 d-flex justify-content-center">
                   <img alt="Starhub" src={Starhub} className="mb-2" height="150px"/>
                   <div className="text-center">
                   {/* <a href="" className="learn-more">Learn More</a>
                   <Button className="btn btn-apply text-white ms-2">Apply Now</Button> */}
                   </div>
                   </Col>
               </Row>
               </Col>
           </Row>
           <Row>
               <Col>
               <Row>
                   <Col>
                    <h3 className="fs-3 text-center mt-3">
                       EV Charging <hr className="header-border-secondary border-width-long mx-auto"/>
                    </h3>
                   </Col>
               </Row>
               <Row className="justify-content-center">
                   <Col lg={4} md={4} sm={6} xs={12}  className="mb-5 justify-content-center text-center">
                   <img alt="evfy-logo" src="../img/evfy-logo.png" className="mb-2" style={{width:"200px"}}/>
                   <div className="text-center font-size-big">
                   Up to 10% discount on Evfy’s charging network
                   </div>
                   </Col>
               </Row>
               <Row>
                   <Col>
                    <h3 className="fs-3 text-center mt-3">
                        Lifestyle <hr className="header-border-secondary border-width-short mx-auto"/>
                    </h3>
                   </Col>
               </Row>
               <Row className="justify-content-center">
                   <Col lg={4} md={4} sm={6} xs={12} className="mb-5 justify-content-center text-center">
                   <img alt="evfy-logo" src="../img/evfy-logo.png" className="mb-2"  style={{width:"200px"}}/>
                   <div className="text-center font-size-big">
                   Redeem a range of apparel
                   </div>
                   </Col>
               </Row>
               </Col>
           </Row>
          <Row>
              <Col>
               <Row>
                   <Col>
                    <h3 className="fs-3 mt-3 text-center">
                        For ICE Vehicles <hr className="header-border-secondary border-width-short mx-auto"/>
                    </h3>
                   </Col>
               </Row>
               <Row className="justify-content-center ">
                   <Col lg={4} md={4} sm={6} xs={12} className="mb-5 justify-content-center text-center">
                   <img alt="Esso evfy" src={Esso} className="mb-2" style={{width:"180px"}}/>
                   <div className="text-center font-size-big">
                        20% off petrol <br />
                        22% off diesel
                   </div>
                   </Col>
                   <Col lg={4} md={4} sm={6} xs={12} className="mb-5 justify-content-center text-center">
                   <img alt="Cartimes evfy" src={Cartimes} className="mb-2" style={{width:"180px"}}/>
                   <div className="text-center font-size-big">
                   Discount off repairs and maintenance
                   </div>
                   </Col>
                   <Col className="py-5  text-center" xs={12} md={12} lg={12}>
                   <a href="/drive-with-us"><button className="btn" style={{ fontSize: '14px', backgroundColor: '#0FBEA3', borderRadius: '15px', color: '#fff', width: '180px' }}>
                   <FontAwesomeIcon icon={faLocationArrow} style={{ fontSize: '14px', color: '#fff', }} />  Drive With Us </button></a>
                </Col>
               </Row>
              </Col>
          </Row>
        </Container>
    );
};
export default SmoothDriveTwo;
