import react, { useState, useEffect } from "react";
import { Container, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import NavMenu from "../Layout/navbar";
import HEvFleet from "./partial/HEvFleet";
import "../../assets/css/style.css"
import Footer from "../Layout/Footer";
import EVChargingRate from "./partial/EVChargingRate"
import EVNews from "./partial/EVNews";
import PickEV from "./partial/pickEV";
import RentalCost from "./partial/RentalCost";
import ECOService from "./partial/ECOService";
import CustomerSatification from "./partial/CustomerSatification";
import HeadImg from "../../assets/img/logo/evfy-home-logo.png"
const Home = () => {

  return (<>
    <Container fluid>
      <Row>
          <Col>
            <NavMenu />
          </Col>
        </Row>
      <Row className="hero d-flex justify-content-center pb-5">
        <Col>
          <video loop autoPlay={true} muted loop className="background-video">
            <source src={'../../assets/file/hero.mp4'} type="video/mp4" />
          </video>
        </Col>
          <Col lg={12} className="text-white text-center">
            <h1><img src={HeadImg} alt="evfy singapore" className="img-size"/></h1>
            {/* <p>through Evfy's fleet of EV Driver Partners</p> */}
          </Col>
        <Row  className="text-center text-white">
          <Col md={3} lg={3} xs={0}></Col>
          <Col md={2} lg={2} className="text-center">
            <span className="count-result" data-from="0" data-to="21" data-speed="1000"
              data-refresh-interval="50">100%</span>
            <span className="count-category" style={{fontSize:"13px"}}>Electric Fleet</span>
          </Col>
          <Col md={2} lg={2} className="text-center">
            <span className="count-result" data-from="25" data-to="1622" data-speed="2300"
              data-refresh-interval="50">765kg</span>
            <span className="count-category" style={{fontSize:"13px"}}>CO2 saved</span>
          </Col>
          <Col md={2} lg={2} className="text-center">
            <span className="count-result" data-from="25" data-to="8360" data-speed="4000"
              data-refresh-interval="50">60,000</span>
            <span className="count-category" >Parcels delivered</span>
          </Col>
          <Col md={3} lg={3} xs={0}></Col>
        </Row>
        <Row>
          <Col lg={3} md={1} xs={0}></Col>
          <Col lg={3} md={5} xs={6} className="text-center d-flex justify-content-end">
            <ButtonGroup size="sm" aria-label="Basic example">
              <a href="#ECOService"><Button variant="transparent" className="btn btn-outline-light text-white btn-style2">Green Business</Button></a>
            </ButtonGroup>
          </Col>
          <Col lg={3} md={5} xs={6} className="text-center d-flex justify-content-start">
            <ButtonGroup size="sm" aria-label="Basic example">
            <a href="#PickEV"><Button variant="transparent" className="btn btn-outline-light text-white btn-style">Driver Partner</Button></a>
            </ButtonGroup>
          </Col>
          <Col lg={3} md={1} xs={0}></Col>
        </Row>
      </Row>
        <Container>
          <Row id="EVChargingRate">
            <Col>
              <EVChargingRate />
            </Col>
          </Row>
          {/* <Row id="EVNews">
            <Col>
              <EVNews />
            </Col>
          </Row> */}
          <Row >
            <Col id="PickEV">
              <PickEV />
            </Col>
          </Row>
          <Row  id={'RentalCost'}>
            <Col>
            <div >
              <RentalCost />
              </div>
            </Col>
          </Row>
          <Row id="ECOService">
            <Col>
              <ECOService />
            </Col>
          </Row>
          <Row id="CustomerSatification">
            <Col>
              <CustomerSatification />
            </Col>
          </Row>
        </Container>
    </Container>
    <Footer/>
    </>
  );
};
export default Home;
