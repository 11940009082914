import react, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import NavMenu from "../../Layout/navbar";
import AboutNav from "../partial/AboutNav";
import axios from 'axios';
import moment from 'moment';
import Footer from '../../Layout/Footer'
import TempImg from '../../../assets/img/tempImg/temp.jpg'
import "../../../assets/css/style.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faTwitterSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {Helmet} from "react-helmet";

const PressDetails = (props) => {
  let detailID = (props.match && props.match.params && props.match.params.id && props.match.params.id > 0) ? props.match.params.id : 0;
  const [blog, setBlog] = useState();
  const [desc, setDesc] = useState();
  const [dataId, setDataId] = useState();

  const callPressApi = () => {
    let id = dataId;
    let url = `${process.env.REACT_APP_API}/api/front_presses/${id}`
    axios
      .get(url)
      .then((response) => {
        setBlog(response.data.data)
        setDesc(response.data.data.description)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    setDataId(detailID);
    if (dataId !== 0 && dataId != null) {
      callPressApi();
    }
  }, [dataId])

  return (<>
  <Helmet>
            <title>{blog && blog.title}</title>
            <meta property='og:title'  content={blog && blog.title}/>
            <meta property="og:description" content={blog != null ?  blog.description.replace(/(<([^>]+)>)/gi, "") : ' '} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content={blog && blog.image_url ? process.env.REACT_APP_API + '/' + blog.image_url : TempImg} />
  </Helmet>
    <Container fluid className="p-0 m-0">
      <Row>
        <Col>
          <NavMenu />
        </Col>
      </Row>
    </Container>
    <AboutNav />
    <Container className="justify-content-center">
      <Row>
        <Col md={1} lg={1}></Col>
        <Col xs={12} md={10} lg={10} className="py-5 d-flex justify-content-center align-items-center">
          <Card style={{ width: '90%', border: '0px solid black' }} >
            <div className="blog-detail-wrapper hover01" >
              <img alt={blog && blog.title} className="blog-img" src={blog && blog.image_url ? process.env.REACT_APP_API + '/' + blog.image_url : TempImg} />
            </div>
            <Card.Body >
              <Card.Title>
                <h1 className="card-text w-100 fs-2 ">  {blog != null ? blog.title : ' '}</h1>
                <p className="card-text w-100 fs-6"><small className="text-muted text-decoration-none">{blog != null ? moment(blog.updated_at).format('LL') : ''}</small></p>
              </Card.Title>
              <Card.Text>
                {blog != null ? <div dangerouslySetInnerHTML={{ __html: blog.description }} /> : ' '}
              </Card.Text>
              <Card.Text className="pt-5">
                <h3 className="d-flex justify-content-end">Share</h3>
                <div className="d-flex pb-5 justify-content-end">
                  <div className="me-3"><a href={'https://twitter.com/intent/tweet?text='+window.location+'/#'} rel="nofollow" target="_blank"><FontAwesomeIcon icon={faTwitterSquare} style={{ fontSize: '36px', color: "#00acee" }} /></a></div>
                  <div className="me-3"><a href={'https://www.facebook.com/sharer/sharer.php?u='+window.location+'/#'} rel="nofollow" target="_blank"><FontAwesomeIcon icon={faFacebookSquare} style={{ fontSize: '36px', color: "#3b5998" }} /></a></div>
                  <div className=""><a href={'https://www.linkedin.com/cws/share?url='+window.location+'/#'} rel="nofollow" target="_blank"><FontAwesomeIcon icon={faLinkedin} style={{ fontSize: '36px', color: "#0E76A8" }} /></a></div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={1} lg={1}></Col>
      </Row>
    </Container>
    <Footer/>
  </>
  );
};
export default PressDetails;
