import { Container, Row, Col, } from "react-bootstrap";
import NavMenu from "../../Layout/navbar";
import AboutNav from "../partial/AboutNav";
import TempImg from '../../../assets/img/tempImg/temp.jpg'
import "../../../assets/css/style.css"
import CarrerType from "./partial/carrerType";
import { useEffect, useState } from "react";
import CarrerInfo from "./partial/carrerinfo";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Footer from "../../../components/CustomFooter";

const Carrer = (props) => {

    // const handleSubmit =(value) => {
    //     if (value != null && value != ''){
    //       setState(value);
    //     } else {
    //         setState(1);
    //     }
       
    // }

    useEffect(() => {
        // handleSubmit(state);
      });
  return (<>
    <Container fluid className="p-0 m-0">
       <Row>
     <Col>
       <NavMenu /> 
     </Col>
   </Row>
   </Container>
       <AboutNav />  
        <Container className="warpper-min-height">
            <Row>
                <Col>
                <CarrerType />
                {/* {state && state == 2 ?  <CarrerInfo handleSubmit={handleSubmit} /> : ''} */}
                </Col>
            </Row>
        </Container>
        <Footer/>
        </>
  );
};
export default Carrer;
