import react, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import "../../../assets/css/style.css"
import React, { useRef } from 'react';
import DriveWithUs from '../../../assets/img/Rental Cost Savings/drive-with-us.png'
import Mission from '../../../assets/img/onboarding/mission_bg.png'
import Eleve from '../../../assets/img/onboarding/eleve.jpg'
import Swal from "sweetalert2";
import axios from "axios";
const RegisterSuccessful = (props) => {

  const sucessEmail = props.sucessEmail ? props.sucessEmail : null


  const resendConfirmation = ()=> {
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "resend/welcome/activation";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;

    let param = {
      email: sucessEmail,
    };
    axios.post(
      URL,
      param,
       { 
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .catch( error => {
          try {
              status = error.response.status;
              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data.result;
                  
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
          Swal.fire(errorMessage, 'error')
      }).then((response) => {
        if (response) {
          Swal.fire(response.data.result);

        }
      });
  }

  return (
         <Container className="pt-5 mt-5">
          <Row>
            <Col xs={12} md={1} lg={2}></Col>
            <Col xs={12} md={10} lg={8}>
              {sucessEmail ? <><h2 className="text-center pb-3">Welcome aboard!</h2>
              <p className="text-center">We’ve sent you a confirmation email at <span style={{cursor:'pointer', color: '#007CDE'}}> {sucessEmail}</span>  for verification purposes. Set your password from there and get started immediately!</p>
              <p className="text-center pb-5">Can’t find the email? <span style={{cursor:'pointer', color: '#007CDE'}}
              onClick={e=> resendConfirmation()}
              >Click here to resend</span>.</p> </>  : <><h2 className="text-center pb-3">Successfully submitted!</h2>
              <div className="pb-3">We’ll contact you within 1-2 working days. For urgent enquiries, email us at hello@evfy.sg. </div>
              <div className="pb-3">In the meantime, check out the rest of our website and find out how green logistics are not just an alternative, but an inevitability.</div></> }
              

              
              <Row classname="p-0 m-0">
                <Col xs={12} md={4} lg={4} className="d-flex justify-content-center mb-2">
                <a href="/why-ev" className="text-decoration-none w-100 " >
                  <div className="card-auto ">
                    <img src={Mission} alt="drive with us" width="100%" />
                    <p  className="text-white d-flex align-items-center justify-content-center text-center p-2 bg-contain-text-pri">Why Evfy?</p>
                  </div>
                </a>
                </Col>
                <Col xs={12} md={4} lg={4}  className="d-flex justify-content-center mb-2">
                <a href="/ev-charging-prices" className="text-decoration-none w-100 ">
                  <div className="card-auto ">
                    <img src={DriveWithUs} alt="drive with us" width="100%" />
                    <p  className="text-white d-flex align-items-center justify-content-center text-center p-2 bg-contain-text-sec">Calculate your CO2 savings with EVs</p>
                  </div>
                </a>
                </Col>
                <Col xs={12} md={4} lg={4}  className="d-flex justify-content-center mb-2">
                <a href="https://t.me/evfysg" className="text-decoration-none w-100 ">
                  <div className="card-auto">
                    <img src={Eleve} alt="drive with us" width="100%" />
                    <p  className="text-white d-flex align-items-center justify-content-center text-center p-2 bg-contain-text-pri">Join our EV community on Telegram!</p>
                  </div>
                </a>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={1} lg={2}></Col>
          </Row>
         </Container>
  );
};
export default RegisterSuccessful;


