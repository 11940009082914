import react, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import NavMenu from "../../Layout/navbar";
import AboutNav from "../partial/AboutNav";
import { faMapMarkerAlt, faClock, faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../assets/css/style.css"
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Footer from "../../Layout/Footer";
import Swal from 'sweetalert2'

const Contact = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    emailjs.sendForm('service_8lm954n', 'template_7jbe6ue', e.target, '2stbnOdEztvRCrsq-')
      .then((result) => {
        setIsLoading(false);
        emptyFields();
        Swal.fire("Sent!", "Message has been sent.", "success");
      }, (error) => {
        console.log(error.text);
        setIsLoading(false);
      });
  };

  const emptyFields = () => {
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
  }

  return (<>
    <Container fluid className="p-0 m-0">
      <Row>
        <Col>
          <NavMenu />
        </Col>
      </Row>
    </Container>
    {/* <AboutNav /> */}
    <Container>
      <Row>
     
        <Col xs={12} md={12} lg={12}>
          <h1 className="fs-2 pt-5 text-center mt-5 ">Let’s Talk! <hr className="header-border-secondary border-width-long mx-auto mt-2" />
          </h1>
        </Col>
        <Col md={1} lg={1}></Col>
        <Col xs={12} md={5} lg={5} className="mt-4 d-flex justify-content-center">
          <div>
            <p><FontAwesomeIcon icon={faMapMarkerAlt} style={{ fontSize: '18px', color: '#0FBEA3' }} />	&nbsp; 84 Genting Lane #03-01, S349584</p>
            <p><FontAwesomeIcon icon={faClock} style={{ fontSize: '18px', color: '#0FBEA3' }} />	&nbsp; Mon - Fri: 9:00 am - 6:00 pm</p>
          </div>
        </Col>
        <Col xs={12} md={5} lg={5} className="mt-4 d-flex justify-content-center">
          <div>
          <a href="tel:+6589323141" className="text-decoration-none" style={{color:"#212529"}}><p><FontAwesomeIcon icon={faPhoneAlt} style={{ fontSize: '18px', color: '#0FBEA3' }} />	&nbsp; +65 8932 3141</p></a>
          <a href="mailto:hello@evfy.sg"  className="text-decoration-none" style={{color:"#212529"}}><p><FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '18px', color: '#0FBEA3' }} />	&nbsp; hello@evfy.sg</p></a>
          </div>
        </Col>
        <Col md={1} lg={1}></Col>
      </Row>
    </Container>

        <div className="mt-5" style={{ position: 'relative', textAlign: 'center', height: '500px', width: '100%' }}>
          <div style={{ overflow: 'hidden', background: 'none!important', height: '500px', width: '100%' }}>
             <iframe width="100%" height="500" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31910.311660191706!2d103.84546911179372!3d1.3015313892446805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19248c267d17%3A0x8df622bdb4bd6a18!2sEvfy%20Pte%20Ltd!5e0!3m2!1sen!2smm!4v1647321113983!5m2!1sen!2smm" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
            </iframe>
          </div>
        </div>

    <Container>
      <Form ref={form} autoComplete="off" onSubmit={sendEmail}>
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={12} lg={12}>
            <h2 className="fs-2 text-center mt-5">
              Send us a message <hr className="header-border-secondary border-width-long mx-auto mt-2" />
            </h2>
          </Col>
          <Col md={1} lg={1}></Col>
          <Col xs={12} md={3} lg={3} className="pt-4">
            <label className="form-label">Name</label>
            <input type="name" name="name" className="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required placeholder="Name" style={{ borderColor: '#0FBEA3', borderRadius: '8px' }} 
            value={name}
            onChange={e=>setName(e.target.value)}
            />
            <div className="invalid-feedback">
              Please enter a username.
            </div>
          </Col>
          <Col xs={12} md={4} lg={4} className="pt-4">
            <label for="exampleFormControlInput1" className="form-label">Email</label>
            <input type="email" name="email_name" className="form-control" id="exampleFormControlInput1" placeholder="Email" required style={{ borderColor: '#0FBEA3', borderRadius: '8px' }}
            value={email}
            onChange={e=>setEmail(e.target.value)}
            />
          </Col>
          <Col xs={12} md={3} lg={3} className="pt-4">
            <label for="exampleFormControlInput1" className="form-label">Phone</label>
            <input type="number" name="phone_number" className="form-control" id="exampleFormControlInput1" placeholder="Phone" required style={{ borderColor: '#0FBEA3', borderRadius: '8px' }}
            value={phone}
            onChange={e=>setPhone(e.target.value)}
            />
          </Col>
          <Col md={1} lg={1}></Col>
          <Col md={1} lg={1}></Col>
          <Col xs={12} md={10} lg={10} className="pt-4">
            <label for="exampleFormControlTextarea1" className="form-label">Message</label>
            <textarea name="message" className="form-control" id="validationCustom03" rows="5" placeholder="Message" required style={{ borderColor: '#0FBEA3', borderRadius: '8px' }}
            value={message}
            onChange={e=>setMessage(e.target.value)}
            ></textarea>
            <div className="invalid-feedback">
              Please enter a Message.
            </div>
          </Col>
          <Col md={1} lg={1}></Col>
        </Row>
        <Row className="pb-5">
          <Col className="pt-4 text-center" xs={12}>
          <input type="submit" className="btn " value={isLoading ? 'Loading…' : 'Send Now'} disabled={isLoading ? true : false} style={{ paddingLeft: "25px", paddingRight: "25px", color: "#FFF", fontSize: '18px', backgroundColor: '#0FBEA3', borderColor: '#0FBEA3', borderRadius: '15px' }}/></Col>
        </Row>
      </Form>
    </Container>
<Footer/>
  </>
  );
};
export default Contact;


