import react, { useState, useEffect } from "react";
import { Container, Row, Col, Card} from "react-bootstrap";
import NavMenu from "../../Layout/navbar";
// import AboutNav from "../partial/AboutNav";
import TempImg from '../../../assets/img/tempImg/temp.jpg'
import "../../../assets/css/style.css"
import axios from "axios";
import moment from 'moment';
import API from '../../../api/api';
import apiUtil from "../../../api/apiUtil";
import Footer from "../../Layout/Footer";
import CustomLoading from "../../../components/CustomLoading";
const Insight = (props) => {
    let insightTitle = '';
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const getPosts = (id = 0) => {
        const payLoad = {
            status: 'internal',
            published:true,
            latest:true

          };
          setIsLoading(true)
          API.get('/api/front_posts', payLoad, '')
        .then((result) => {
        setPosts(result.data.data.data)
        setIsLoading(false)
          })
          .catch((error) => {
            console.log(error)
            setIsLoading(false)
          })

       };

  useEffect(() => {
    getPosts();
  },[]);

  return (<>
    <Container fluid className="p-0 m-0">
    <Row>
     <Col>
       <NavMenu />
       
     </Col>
   </Row>
   </Container>
   {/* <AboutNav />    */}
        <Container>
        <Row>
            <Col className="pt-5 mt-5 pb-5 text-center">
            <h1>Insights <hr className="header-border-primary border-width-short" style={{margin: "10px auto"}} /></h1>
            </Col>
        </Row>
        {isLoading == true ?  
        <Row className="d-flex justify-content-center align-items-center p-5 mt-5" style={{minHeight:'350px'}}>
              <CustomLoading />
              </Row>
              :
        <Row style={{minHeight:'350px'}}>
        { posts.map((data) => 
            <Col xs={12} md={4} lg={4} className="pb-5 d-flex justify-content-center align-item-center">
              <span style={{display:'none'}}>{insightTitle = data.title && data.title.replace(/\s/g, '-')}</span>
            <Card style={{ width: '90%', borderRadius: "15px"}} className="justify-content-center align-item-center">
            <a href={`/insight/${data.id}/${insightTitle}`} style={{textDecoration:'none'}}>
            <div className="blog-img-wrapper2 hover01" >
            <img className="blog-img" src={data && data.post_images && data.post_images.length > 0 ? process.env.REACT_APP_API + '/' +data.post_images[0].image_url : TempImg}  alt={data.title} />
            </div>
            <Card.Body >
                <Card.Text className="fs-6 text-center text-dark">
                <h5 className="fs-6 text-center card-title">{data.title.length > 50 ? `${data.title.substring(0, 50)}...`: data.title}</h5>
                </Card.Text>
                <Card.Text className="text-center" style={{color:'#C4C4C4'}}>
                {moment(data.updated_at).format('LL')}
                </Card.Text>
            </Card.Body>
            </a>
            </Card>
            </Col>
        )}
        </Row>
}
        </Container>
        <Footer/>
    </>
  );
};
export default Insight;
