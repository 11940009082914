import { Container, Row, Col, } from "react-bootstrap";
import NavMenu from "../../Layout/navbar";
import AboutNav from "../partial/AboutNav";
import muuseImg from '../../../assets/img/Eco-llaborations/MUUSE_HORIZONTAL_WEB_250_LIGHT_2.png'
import packageImg from '../../../assets/img/Eco-llaborations/package.png'
import "../../../assets/css/style.css"
import Footer from "../../Layout/Footer";

const EcoAllobration = (props) => {

  return (<>
    <Container fluid className="p-0 m-0">
       <Row>
     <Col>
       <NavMenu />
      
     </Col>
   </Row>
   </Container>
   <AboutNav />
   <Container>
        <Row>
            <Col className="pt-5 pb-5 text-center">
            <h1>Eco-llaborations <hr className="header-border-secondary border-width-long" style={{margin: "10px auto"}} /></h1>
            <p className="sub-title">Networking a green ecosystem</p>
            </Col>
        </Row>
        </Container>   
        <Container>
            <Row className="align-items-center">
                <Col xs={12} md={8} lg={8}>
                    <h2 className="sub-title-h2 mb-4">Muuse x Evfy</h2>
                    <p>NUS students and staff can save single-use cups by ordering their beverage in a reusable cup at no additional charge. They can then easily return the cups at the six dedicated return stations around campus to be cleaned and sanitized. Customers who opt for reusable cups can enjoy 50 cents off each drink purchase.</p>
                    <p>To learn more, visit <a href="wwww.muuse.io">wwww.muuse.io</a> </p>
                    <div className="w-100 text-end pe-4"><button className="btn" style={{fontSize: '14px', borderColor: '#0FBEA3', borderRadius: '15px' }}>
                        <a href="https://www.muuse.io" target="_blank" style={{textDecoration:'none', color: '#0FBEA3'}}>Learn More</a>
                    </button></div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                <img src={muuseImg} alt="muuse logo" width="100%" height="100%"/>
                </Col>
            </Row>
            <hr />
        </Container>
        <Container>
            <Row className="align-items-center">
                <Col xs={12} md={8} lg={8}>
                    <h2 className="sub-title-h2 mb-4">Package Pals x Evfy</h2>
                    <p>Package Pals is a circular packaging initiative for businesses & the Earth.</p>
                    <p>Founded on the principles of Reduce and Reuse, we seek to give single-use packaging new life by collecting & distributing 2nd-hand packaging to local businesses for reuse.</p>
                    <p>To learn more, visit <a href="https://www.packagepals.co/">www.packagepals.co</a> </p>
                    <div className="w-100 text-end pe-4"><button className="btn" style={{fontSize: '14px', color: '#0FBEA3', borderColor: '#0FBEA3', borderRadius: '15px' }}>
                   
                        <a href="https://www.packagepals.co/" target="_blank" style={{textDecoration:'none', color: '#0FBEA3'}}>Learn More</a>
                    </button></div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                <img src={packageImg} alt="package logo" width="100%" height="100%"/>
                </Col>
            </Row>
            <hr />
        </Container>
        <Footer/>
    </>
  );
};
export default EcoAllobration;
