import react, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import NavMenu from "../Layout/navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import "../../assets/css/style.css"
import React, { useRef } from 'react';
import Evfy from '../../assets/img/logo/evfy-logo.png'
import OnboardingChoose from "./partial/onboardChoose";
import OnboardingClient from "./partial/onboardClient";
import OnboardingVendor from "./partial/onboardVendor";
import OnboardingFleet from "./partial/onboardFleet";
import Footer from "../Layout/Footer";
import RegisterSuccessful from "./partial/registerSuccessful";
const Onboarding = (props) => {

const [show, setShow] = useState(false);
const [onboard, setOnboard] = useState();
const [isSuccess, setIsSuccess] = useState(false);
const [isConfirmed, setIsConfirmed] = useState(false)

// useEffect(() => {
// //   setShow(true);
// }, []);

const Dialog = () => {
    return <Modal
    show={show}
    size="lg"
    centered
    onHide={() => setShow(false)}>
    <Modal.Header closeButton style={{borderBottom: '0px solid black'}}>
    </Modal.Header>
    <Modal.Body>
        <Container>
            <Row>
                <Col xs={12} md={4} lg={4} className="d-flex flex-column align-items-center">
                <h3 className="pb-2">Client</h3>
                <div className="text-title-box mb-4 text-center">For customers looking to engage EVFY as its delivery service provider</div>
                <div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> EV deliveries available</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Scheduled delivery options</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Lifetime delivery history</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Instant e-POD</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Automatic invoice generation</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Analytics and visualisations</div>
                    <div className="pb-3 d-flex"><div><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/></div><div className="px-1">API and webhooks management</div> </div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Dedicated account manager</div>
                </div>
                </Col>
                <Col xs={12} md={4} lg={4} className="d-flex flex-column align-items-center">
                <h3 className="pb-2">Fleet Manager</h3>
                <div className="text-title-box mb-4 text-center">For companies who need a SaaS solution to manage their vehicle fleet</div>
                <div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Seamless job allocation</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> 24/7 live tracking of drivers</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Route optimisation available</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Instant e-POD</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Automatic invoice generation</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Analytics and visualisations</div>
                    <div className="pb-3 d-flex"><div><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/></div><div className="px-1">API and webhooks management</div> </div>
                </div>
                </Col>
                <Col xs={12} md={4} lg={4} className="d-flex flex-column align-items-center">
                <h3 className="pb-2">Vendor</h3>
                <div className="text-title-box mb-4 text-center">For logistics contractors who want to join the EVFY partner network</div>
                <div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Seamless job allocation</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> 24/7 live tracking of drivers</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Route optimisation available</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Instant e-POD</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Automatic invoice generation</div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Analytics and visualisations</div>
                    <div className="pb-3 d-flex"><div><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/></div><div className="px-1">API and webhooks management</div> </div>
                    <div className="pb-3"><FontAwesomeIcon icon={faCheckCircle} style={{color:'#007CDE'}}/> Prioritised jobs from EVFY</div>
                    
                    
                </div>
                </Col>
            </Row>
        </Container>
    </Modal.Body>
    </Modal>
}

    
  return (<>
    <Container fluid className="p-0 m-0">
      <Row>
        <Col>
          <NavMenu />
        </Col>
      </Row>
    </Container>

    <Container fluid className="pt-1 pb-3 m-0 mt-5 bg-gray-color" style={{minHeight:"80vh"}}>
        {!isSuccess && <Row className="justify-content-center align-items-center d-flex pt-5">
            <Col xs={12} md={12} lg={12} className="d-flex justify-content-center align-items-center fw-bold fs-1 pb-3">Welcome to &nbsp;  <img src={Evfy} alt="evfy-logo" height="56em"/></Col>
            <Col xs={0} md={1} lg={2}></Col>
            <Col xs={12} md={10} lg={8} className="card-warpper-container p-0 m-0 mb-3">
                {!onboard &&
                <OnboardingChoose
                isConfirmed={isConfirmed}
                onShow={(show) =>{
                    setShow(show)
                }}
                onSave={(data)=>{
                    setOnboard(data)
                }}
                />}
                 {onboard == 1 &&
                <OnboardingClient 
                onShow={(show) =>{
                    setShow(show)
                }}
                onSave={(data, success)=>{
                    setOnboard(data);
                    setIsSuccess(success);
                }}
                />}
                 {onboard == 2 &&
                <OnboardingVendor 
                onShow={(show) =>{
                    setShow(show)
                }}
                onSave={(data, success)=>{
                    setOnboard(data)
                    setIsSuccess(success);
                }}
                />}
                 {onboard == 3&&
                <OnboardingFleet 
                onShow={(show) =>{
                    setShow(show)
                }}
                onSave={(data, success)=>{
                    setOnboard(data)
                    setIsSuccess(success)
                    // if(data == "sign-up-success") {
                    //     setOnboard(null)
                    //     setIsConfirmed(true)
                    // } else {
                    //     setOnboard(data)
                    // }
                    
                }}
                />}
                
            </Col>
            <Col xs={0} md={1} lg={2}></Col>
        </Row>}
        
        {isSuccess && <RegisterSuccessful
            sucessEmail={onboard}
        />}
    </Container>
    {Dialog()}
    <Container fluid className="p-0 m-0 " >
    <Footer />
    </Container>
  </>
  );
};
export default Onboarding;


