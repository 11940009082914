
import { Container, Row, Col, Table, Carousel, Modal } from "react-bootstrap";
import { faBatteryFull, faArchive, faChargingStation, faPlug, faTachometerAlt, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TempImg from '../../../../assets/img/tempImg/temp.jpg'
import "../../../../assets/css/style.css"
import AliceCarousel from 'react-alice-carousel';
import  jsPDF from "jspdf";
import EnquireNow from "../../../Resources/EnquireNow";
import 'react-alice-carousel/lib/alice-carousel.css';
import API from '../../../../../src/api/api';
import apiUtil from "../../../../../src/api/apiUtil";
import { useEffect, useState } from "react";

const EVListingSec1 = (props) => {
   
    const [id] = useState(props.id);
    const [evVehicle, setEvVehicle] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(()=>{
        if(id > 0) {
            callVehicleListDetail()
        } else {
            callVehicleList();
        }
        
        
    }, [id])
    const GeneratePDF = () => {
       // Default export is a4 paper, portrait, using millimeters for units
        const doc = new jsPDF();

        doc.text("Hello world!", 10, 10);
        doc.save("a4.pdf");
    }
    const callVehicleList = () => {
        let data = {
          latest: true,
          limit: 1,
        }
        API.get('/api/front_ev_vehicles', data, '')
        .then((result) => {
           apiUtil.parseResult(result, (data) => { 
             let result = data.data && data.data.length > 0 ? data.data[0] :[]
             setEvVehicle(result)
           }, (error, type) => {
             
            });
        });
    }

    const callVehicleListDetail = () => {
        let data = {
        }
        API.get('/api/front_ev_vehicles/'+id, data, '')
        .then((result) => {
           apiUtil.parseResult(result, (data) => { 
             let result = data.data
             setEvVehicle(result)
           }, (error, type) => {
             
            });
        });
    }

    const Dialog = () => {
        return <Modal
        show={show}
        onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enquire Now!!</Modal.Title>
        </Modal.Header>
      
        <Modal.Body>
          <EnquireNow
          onClose={() => setShow(false)}
          />
        </Modal.Body>
      </Modal>
    }

    const getCargoSpace = (cargo_space) => {
        let cargo_space_json= JSON.parse(cargo_space)
        let cargo_space_arry = cargo_space_json ? cargo_space_json.cargo_space[0] : [];
        return  <>{ cargo_space_arry.key1 ? `${parseFloat(cargo_space_arry.key1).toFixed(1)}m x `: ''  }{ cargo_space_arry.key2 ? `${parseFloat(cargo_space_arry.key2).toFixed(1)}m x `: ''  }{ cargo_space_arry.key3 ? `${parseFloat(cargo_space_arry.key3).toFixed(1)}m`: ''  }</>

           
    }

    return (
        <Container fluid>
           {evVehicle &&  <Row>
                <Col xs={12} md={6} lg={7} style={{height: '80%'}} className="pt-5">
                    <Carousel className="align-image-center">
                        {evVehicle && evVehicle.ev_vehicles_images && evVehicle.ev_vehicles_images.map((image) => (
                            <Carousel.Item >
                                <img alt={evVehicle && evVehicle.name} src={image.img_url ? `${process.env.REACT_APP_API}/${image.img_url}`: TempImg} style={{width: "fit-content"}} className="ev-list-img"/>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                <Col xs={12} md={6} lg={5} className="pt-5">
                    <h1 className="text-center fs-3"><span className="ev_detail_header" >{evVehicle.name && evVehicle.name } </span></h1>


                    <Row style={{ marginTop:"20px" , marginLeft:"20px"}} className='ev_detail_table w-100'>
                        <div className="d-flex">
                            <div className="w-50 d-flex">
                               <div className="pe-2"><FontAwesomeIcon icon={faBatteryFull} className='ev_detail_icon' /></div>
                            <div>Estimated Range</div>
                            </div>
                            <div  className="w-50 d-flex">{evVehicle.estimated_range && isNaN(evVehicle.estimated_range) ?  evVehicle.estimated_range : parseFloat(evVehicle.estimated_range).toFixed(2) } Km</div></div>
                    </Row>
                    <Row style={{ marginTop:"20px" , marginLeft:"20px"}} className='ev_detail_table w-100'>
                        <div className="d-flex">
                            <div className="w-50 d-flex">
                               <div className="pe-2"><FontAwesomeIcon icon={faArchive} className='ev_detail_icon'  /></div>
                            <div>Cargo Space</div>
                            </div>
                            {/* {console.log(JSON.parse(evVehicle))} */}
                            <div  className="w-50 d-flex"> 
                            {evVehicle && evVehicle.m3_volumn && evVehicle.m3_volumn == '' || evVehicle.m3_volumn == null || evVehicle.m3_volumn == 0.00? 
                            <span className=''>{evVehicle.cargo_space && JSON.parse(evVehicle.cargo_space).cargo_space.length > 0 && 
                                (JSON.parse(evVehicle.cargo_space).cargo_space[0].key1 ? parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key1).toFixed(2): '') + 'm x ' +
                                (JSON.parse(evVehicle.cargo_space).cargo_space[0].key2 ? parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key2).toFixed(2) : '') + 'm x ' +
                                (JSON.parse(evVehicle.cargo_space).cargo_space[0].key3 ? parseFloat(JSON.parse(evVehicle.cargo_space).cargo_space[0].key3).toFixed(2) : '' ) + 'm'}</span>
                                : <span className="ev_pick_m3"> {parseFloat(evVehicle.m3_volumn).toFixed(2)}m<sup>3</sup></span>
                                
                            }
                            </div>
                        </div>
                    </Row>
                    <Row style={{ marginTop:"20px" , marginLeft:"20px"}} className='ev_detail_table w-100'>
                        <div className="d-flex">
                            <div className="w-50 d-flex">
                               <div className="pe-2"><FontAwesomeIcon icon={faPlug} className='ev_detail_icon'  /></div>
                            <div>Battery Capacity</div>
                            </div>
                            <div>{evVehicle.battery_capacity && parseFloat(evVehicle.battery_capacity).toFixed(2)} kWh</div></div>
                    </Row>
                    <Row style={{ marginTop:"20px" , marginLeft:"20px"}} className='ev_detail_table w-100'>
                        <div className="d-flex">
                            <div className="w-50 d-flex">
                               <div className="pe-2"><FontAwesomeIcon icon={faChargingStation} className='ev_detail_icon'  /></div>
                            <div>Charging type</div>
                            </div>
                            <div>{evVehicle && evVehicle.ev_charging_type && JSON.parse(evVehicle.ev_charging_type)[0] ? `${JSON.parse(evVehicle.ev_charging_type)[0].type} : ${parseFloat(JSON.parse(evVehicle.ev_charging_type)[0].value).toFixed(2)} kW`:''} <br />
                            {evVehicle && evVehicle.ev_charging_type && JSON.parse(evVehicle.ev_charging_type)[1] ? `${JSON.parse(evVehicle.ev_charging_type)[1].type} : ${parseFloat(JSON.parse(evVehicle.ev_charging_type)[1].value).toFixed(2)} kW`:''}</div></div>
                    </Row>
                    <Row style={{ marginTop:"20px" , marginLeft:"20px"}} className='ev_detail_table w-100'>
                        <div className="d-flex">
                            <div className="w-50 d-flex">
                               <div className="pe-2"><FontAwesomeIcon icon={faTachometerAlt} className='ev_detail_icon'/></div>
                            <div>Top Speed <br /> Max Power</div>
                            </div>
                            <div>{evVehicle.speed && parseFloat(evVehicle.speed).toFixed(2)} km/h <br />{evVehicle.max_power && parseFloat(evVehicle.max_power).toFixed(2)} kW</div></div>
                    </Row>
                    {/* <div className="text-center w-100 mb-10 mt-2"><p><span className="text-center md-30 ev_detail_per_price">${evVehicle.cost && parseFloat(evVehicle.cost).toFixed(2)}</span> per month</p></div> */}


                    <div className="full-spec-download text-center mt-2" style={{ color: '#0FBEA3', marginBottom: "20px" }}><a href={evVehicle.attachment_link} target="_blank">Click to download the full specs</a></div>
                    <div className="text-center w-100">
                        <button className="btn" onClick={() => setShow(true)} style={{ border: '1px solid #0FBEA3', borderRadius: "10px" }}>
                            <FontAwesomeIcon icon={faLocationArrow} style={{ fontSize: '14px', color: '#0FBEA3' }} />
                            <span style={{ fontSize: '14px', color: '#0FBEA3' }}>  Book a Test Drive</span></button>
                    </div>
                </Col>
            </Row> }
            {Dialog()}
        </Container>
    );
};
export default EVListingSec1;
