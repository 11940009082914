import { Container, Row, Col } from "react-bootstrap";
import "../../../../assets/css/style.css"
import missionImg from "../../../../assets/img/background/mission_bg.png"
import visionImg from "../../../../assets/img/background/vision_bg.jpg"

const MVSec2 = (props) => {

  return (
    <Container className='mb-5'>
                <Row className="align-items-center m-0 p-0">
                    <Col xs={12} md={6} lg={6} className="mt-5 m-0 p-0">
                        <img src={visionImg} alt="Background"  style={{objectFit: 'cover'}} alt="vision" width="100%" />
                    </Col>
                    <Col xs={12} md={6} lg={6} className="text-center text-warper mt-5 mb-5">
                        <h2 className="heading-title mb-4">Our Vision</h2>
                        <p>We aim to be the thought leader in the green last-mile logistics sector, constantly staying
                            ahead by pioneering sustainable initiatives that provide value for the environment, our
                            drivers and our customers.</p>
                    </Col>
                </Row>
                <Row className="align-items-center row divReverse">
                    <Col xs={12} md={6} lg={6} className="text-center padding">
                        <h2 className="heading-title mb-4">Our Mission</h2>
                        <p>To make a social impact with businesses and consumers to adopt sustainable and environment
                            friendly options, now.</p>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="m-0 p-0">
                        <img src={missionImg} alt="mission" width="100%" />
                    </Col>
                </Row>       
    </Container>
  );
};
export default MVSec2;
