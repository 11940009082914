import react, { useState, useEffect } from "react";
import { Container, Row, Col} from "react-bootstrap";
import "../../../assets/css/style.css"

const NavMenu = () => {
    const [navChack, setNavCheck] = useState("false");
    const [path, setPath] = useState([]);

    useEffect(() => {
        NavChange();
        setPath(window.location.pathname.split("/"));
    });

    const NavChange = () => {
        // console.log(window.location.pathname );
        if (window.location.pathname != "/home") {
            setNavCheck("true")
        } else {
            const scrollChange = () => {
                { window.scrollY >= 200 ? setNavCheck("true") : setNavCheck("false") }
            }
            window.addEventListener('scroll', scrollChange);
        }
    }
    return (
            <Container fluid className="mx-0 px-0 w-100 py-3" style={{ marginTop: '70px', backgroundColor: '#F6F6F6' }}>
                <Container>
                <Row className="d-flex justify-content-evenly subNav">
                    <Col xs={6} md={6} lg={3} className={path && path[1] == "drive-with-us" ? 'text-center py-2 active  m-0 p-0 borderRight': 'text-center py-2  m-0 p-0 borderRight' } >
                    <div><a href="/drive-with-us" >
                        Drive With Us</a></div>
                    </Col>
                    <Col xs={6} md={6} lg={3} className={path && path[1] == "ev-listing" ? 'text-center py-2 active  m-0 p-0 borderRight': 'text-center py-2  m-0 p-0 borderRight' } >
                        <div><a href="/ev-listing" >
                        Pick your EV</a></div>
                    </Col>
                    <Col xs={6} md={6} lg={3} className={path && path[1] == "benefit-of-ev" ? 'text-center py-2 active  m-0 p-0 borderRight': 'text-center py-2  m-0 p-0 borderRight' } >
                    <div><a href="/benefit-of-ev" >
                        Benefits of EVs</a></div>
                    </Col>
                    <Col xs={6} md={6} lg={3} className={path && (path[1] == "smoothdrive-benefits" || path[1] == "smoothdrive" ) ? 'text-center py-2 active  m-0 p-0 borderRight': 'text-center py-2  m-0 p-0 borderRight' } >
                    <div><a href="/smoothdrive" >
                        Smoothdrive 2.0</a></div>
                    </Col>
                </Row>
                </Container>
            </Container>
    );
};
export default NavMenu;
