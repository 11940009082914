import { Container, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import "../../../assets/css/style.css"
import NavMenu from "../../Layout/navbar";
import SmoothDriveOne from "./partial/smoothDriveSectOne";
import SmoothDriveTwo from "./partial/smoothDriveSectTwo";
import EVListingNav from '../partial/EVListingNav';
import Footer from "../../Layout/Footer";
const SmoothDrive = () => {

  return (<>
    <Container fluid>
      <Row>
          <Col>
            <NavMenu />
          </Col>
        </Row>
        </Container>
        <EVListingNav/>
        <Container>
          <Row>
            <Col>
              <SmoothDriveOne />
              <SmoothDriveTwo />
            </Col>
          </Row>
        </Container>
        <Footer/>
        </>
  );
};
export default SmoothDrive;
