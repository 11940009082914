import { Container, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import "../../../assets/css/style.css"
import NavMenu from "../../Layout/navbar";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faSearch, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TierBenefit from '../../../assets/img/Smoothdrive/Tiers _ Benefits.png'
import TieringCriteria from '../../../assets/img/Smoothdrive/TieringCriteria.png'
import SmoothdriveProgram from '../../../assets/img/Smoothdrive/SmoothdriveProgram.png'
import SmoothdriveGreen from '../../../assets/img/Smoothdrive/SmoothdriveGreen.png'
import howToEarnPoint from '../../../assets/img/Smoothdrive/howToEarnPoint.png'
import DPRatingTable from '../../../assets/img/Smoothdrive/DPRatingTable.png'
import SmothDrivePic from '../../../assets/img/Smoothdrive/smoothdrive.png'

import EVListingNav from '../partial/EVListingNav';
import Footer from "../../Layout/Footer";
import { useHistory } from "react-router-dom";
const DriveWith = () => {
  let history = useHistory();
  return (<>
    <Container fluid>
      <Row>
          <Col>
            <NavMenu />
          </Col>
        </Row>
        </Container>
        <EVListingNav/>
        <Container fluid className="driveWith-background m-0 p-0" >
          <Container className="h-100">
          <Row>
            <Col>
              <h3 className="fs-3 pt-5 text-white ">Smoothdrive 2.0 <hr className="header-border-secondary border-width-long"/>
              </h3>
              <p className="text-white sub-title">Our Driver-Partner protection programme</p>
            </Col>
          </Row>
          <Row className="inner-btn " >
                <Col className="py-3 text-center" xs={6} md={6} lg={6}>
                    <a href="/drive-with-us"><button className="btn" style={{ fontSize: '14px', backgroundColor: '#0FBEA3', borderRadius: '15px', color: '#fff', width: '180px' }}>
                    <FontAwesomeIcon icon={faLocationArrow} style={{ fontSize: '14px', color: '#fff', }} /> Drive With Us </button>
                    </a>
                </Col>
                <Col className="py-3 text-center" xs={6} md={6} lg={6}>
                    <a href="/smoothdrive-benefits"><button className="btn" style={{ fontSize: '14px', backgroundColor: '#0FBEA3', borderRadius: '15px', color: '#fff', width: '180px' }}>
                     Benefits Available </button>
                     </a>
                </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-5 text-center">
              <p>In Singapore, the Driver-Partner community is often sandwiched between businesses and consumers, along with a distinct lack of job benefits and welfare protection. Unlike regular employees, they are not covered under the Employment Act. </p>
              </Col>
              <Col md={2} lg={2}></Col>
            </Row>
            <Row>
              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
              <p>At Evfy, we believe that all Driver-Partners are micro-entrepreneurs in their own right. With <strong>priority job allocations</strong> and <strong>performance-based incentives</strong> for our EV lessees, it is possible to empower the community and benefit the environment as well. </p>
              </Col>
              <Col md={2} lg={2}></Col>
            </Row>
            <Row>
            <Col xs={12} md={12} lg={12}>
              <h3 className="fs-3 pt-5 text-center mb-4">Tiers & Benefits<hr className="header-border-secondary border-width-long mx-auto mt-2" /></h3>
              </Col>
              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
              <p>SmoothDrive works on a tiered system that scales up your benefits as you work with us. All Evfy Driver-Partners are automatically enrolled as an Amateur, and can progress towards being a Veteran. To qualify for the subsequent tiers, you will need to accumulate the necessary points needed. </p>
              </Col>
              <Col md={2} lg={2}></Col>
              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
                <div className="text-center py-3">
                  <img src={TierBenefit} alt="Tier Benefit" width="85%"/>
                </div>
              </Col>
              <Col md={2} lg={2}></Col>
              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
                <div className="text-center py-3">
                  <img src={TieringCriteria} alt="Tiering Criteria" width="85%"/>
                </div>
              </Col>
              <Col md={2} lg={2}></Col>
              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
                <div className="text-center py-3">
                  <img src={SmoothdriveProgram} alt="Smoothdrive Program" width="85%"/>
                </div>
              </Col>
              <Col md={2} lg={2}></Col>
              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
                <div className="text-center py-3">
                  <img src={howToEarnPoint} alt="how To Earn Point" width="85%"/>
                </div>
              </Col>
              <Col md={2} lg={2}></Col>
              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="text-center">
                <p>A large majority of the delivery jobs offered have multiple locations within each job. These range from islandwide deliveries to clustered drops. The points system are structured with the consideration that all Evfy Driver-Partners would be able to earn them equitably, regardless of the type of jobs preferred. </p>
              </Col>
              <Col md={2} lg={2}></Col>
              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
                <p>An example scenario would be: if a Driver-Partner took up a job that has 7 locations, 2 of which are in Special Areas, then he would earn 50 + 5×2 = 60 points. These points are in addition to the base monetary payout for the job.</p>
              </Col>
              <Col md={2} lg={2}></Col>

              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
                <div className="text-center py-3">
                  <img src={SmoothdriveGreen} alt="Smoothdrive Green" width="85%"/>
                </div>
              </Col>
              <Col md={2} lg={2}></Col>

              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
              <p>At Evfy, we aim to have all Driver-Partners #maketheswitch to drive EVs for last-mile deliveries. To incentivise Drivers to adopt cleaner vehicles like Evfy’s EVs, we have established a Green Multiplier to boost the points earned. </p>
              </Col>
              <Col md={2} lg={2}></Col>
              
              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
              <p>These Bands are based on the Land Transport Authority (LTA) guidelines set on the Commercial Vehicle Emissions Scheme (CVES) and respective Carbon Emission-Based Vehicle Scheme (CEVS). Depending on when your vehicle was registered, there may be different banding categories, but we will take reference to what is noted on your vehicle log card (e.g. bands A1 and A2 will be grouped as Band A, while C1 and C2 would be grouped as Band C).</p>
              </Col>
              <Col md={2} lg={2}></Col>

              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
              <p>An example scenario would be: if a ‘Band A’ Driver-Partner earned 60 points from a job, his nett points gained would be 60 × 1.3 = 78 points.</p>
              </Col>
              <Col md={2} lg={2}></Col>

              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
              <p>Note: If there is no proof that clearly states the emissions band of your vehicle, it will be regarded as Band C.</p>
              </Col>
              <Col md={2} lg={2}></Col>

              <Col xs={12} md={12} lg={12}>
              <h3 className="fs-3 pt-5 text-center ">Priority Job Allocations<hr className="header-border-secondary border-width-long mx-auto mt-2" /></h3>
              </Col>

              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3 text-center">
                <div className="text-center py-3">
                  <img src={DPRatingTable} alt="DPRatingTable" width="85%"/>
                </div>
              </Col>
              <Col md={2} lg={2}></Col>

              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3">
              <p>Quality service is paramount to us (as you would understand through our onboarding training video). When clients feedback negative experiences, we will investigate thoroughly on a case-by-base basis. If Driver-Partners are found responsible for the following, the respective penalities would apply:</p>
              </Col>
              <Col md={2} lg={2}></Col>

              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3">
             <Row>
               <Col xs={12} md={8} lg={8}>1x late delivery without a valid reason </Col>
               <Col xs={12} md={4} lg={4} className="text-end"> ----------- (-0.5 stars)</Col>
               <Col xs={12} md={8} lg={8}>1x poor attitude incident to client </Col>
               <Col xs={12} md={4} lg={4} className="text-end">  -------- (-0.5 stars)</Col>
               <Col xs={12} md={8} lg={8}>1x damaged goods incident </Col>
               <Col xs={12} md={4} lg={4} className="text-end"> -------      (-1 star)</Col>
               <Col xs={12} md={8} lg={8}>Abrupt cancellation of accepted job </Col>
               <Col xs={12} md={4} lg={4} className="text-end">  -------       (-1 star)</Col>
               <Col xs={12} md={8} lg={8}>Loss of items (on top of payment of lost item) </Col>
               <Col xs={12} md={4} lg={4} className="text-end">  ---------    (-2 stars)</Col>
             </Row>
              </Col>
              <Col md={2} lg={2}></Col>

              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3">
              <p>Quality service is paramount to us (as you would understand through our onboarding training video). When clients feedback negative experiences, we will investigate thoroughly on a case-by-base basis. If Driver-Partners are found responsible for the following, the respective penalities would apply:</p>
              </Col>
              <Col md={2} lg={2}></Col>

              <Col md={2} lg={2}></Col>
              <Col xs={12} md={8} lg={8} className="mt-3">
              <p>Stars are important because when a job is broadcast, new Driver-Partners (who begin with 3.5 stars) and Regular Driver-Partners who minimally 4.5 stars will receive the broadcast immediately. Lower ratings result in your receiving the job broadcast later, if the job has not been taken up yet. </p>
              </Col>
              <Col md={2} lg={2}></Col>
              <Col className="py-5  text-center" xs={12} md={12} lg={12}>
              <a href="/drive-with-us"><button className="btn" style={{ fontSize: '14px', backgroundColor: '#0FBEA3', borderRadius: '15px', color: '#fff', width: '180px' }}>
              <FontAwesomeIcon icon={faLocationArrow} style={{ fontSize: '14px', color: '#fff', }} />  Drive With Us </button></a>
                </Col>
            </Row>
          </Container>
          <Footer/>
        </Container>
        
        </>
  );
};
export default DriveWith;
