import react, { useState, useEffect } from "react";
import NavMenu from "../Layout/navbar";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "../../assets/css/style.css"
import ECOServiceNav from "./partial/ECOServiceNav";
import TempImg from '../../assets/img/background/business.jpg'
import bgImg from '../../assets/img/Eco-Services/delivery.jpg'
import EnquireNow from "../Resources/EnquireNow";
import Footer from "../Layout/Footer";

const LastMileDelivery = () => {
    const [show, setShow] = useState(false);

    const Dialog = (props) => {
        
        return <Modal
        show={show}
        onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enquire Now!!</Modal.Title>
        </Modal.Header>
      
        <Modal.Body>
          <EnquireNow
          onClose={() => setShow(false)}
          />
        </Modal.Body>
      </Modal>
    }

  return (<>
    <Container fluid>
        <Row>
          <Col>
            <NavMenu />
          </Col>
        </Row>
    </Container>
    <Container fluid>
        <Row>
          <Col>
          <div className="bg-image-WhyEV"><img src={bgImg} alt="background"/></div>  
          </Col>
        </Row>
        </Container>
    <ECOServiceNav/> 
        <Container className="min-height">
        <Row>
        <Col xs={12} md={8} lg={6}>
            <Row>
                <Col>
        <h1 className="fs-3 text-center mt-5">
        Last-Mile Deliveries <hr className="header-border-primary border-width-long" style={{margin: "10px auto"}} /></h1>
                      </Col>
                      </Row>
            <Row>
                <Col>
        <div className="outer">
            <div className="progress">
                <div className="right">
                    <div><h4>Customised Deliveries</h4>
                        <p className="progress-text">Regular or odd sized parcels, our Driver-Partners have done it all. 
</p> </div>
                    <div><h4>Seamless Bookings and Live Tracking</h4>
                        <p className="progress-text">Supported by Evfy’s proprietary tech platform to give you a transparent overview of your eco-logistics process.</p>
                    </div>
                    <div className="right-end"><h4>Instant e-Proof of Delivery</h4>
                        <p className="progress-text">Customer satisfaction is key. Confidently close the loop with your customers when our Driver-Partners sends confirmation that your parcel has been successfully received! </p></div>
                </div>
            </div>
        </div>
        <br />
        </Col>
        </Row>
        <Row>
            <Col>
        <div className="text-center pt-5 mb-5">
        <button 
         onClick={() => setShow(true)}
        className="btn" style={{ fontSize: '14px', color: '#007CDE', borderColor: '#007CDE', borderRadius: '15px' }}>
             Enquire Now</button>
              </div> 
              </Col>
            </Row>
        </Col>
        </Row>
        {Dialog()}
        </Container>
        <Container fluid className="footer-bottom p-0"><Footer/></Container>
    </>
  );
};
export default LastMileDelivery;
