import react, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import NavMenu from "../Layout/navbar";
import ECOServiceNav from './partial/ECOServiceNav'
import "../../assets/css/style.css"
import Footer from "../Layout/Footer";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faSearch, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TempImg from '../../assets/img/tempImg/temp.jpg'
import CreatingFuture from '../../assets/img/Eco-Services/CreatingFuture.png'
import SGGreenPaln from '../../assets/img/Eco-Services/SGGreenPlan2030.png'
import EnquireNow from "../Resources/EnquireNow";

const WhyEV = () => {
    const [show, setShow] = useState(false);

    const Dialog = (props) => {
        
        return <Modal
            show={show}
            onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Enquire Now!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EnquireNow setShow={show} 
                onClose={() => setShow(false)}
                />
            </Modal.Body>
        </Modal>
    }

    return <>
        <Container fluid className="p-0 m-0">
            <Row>
                <Col>
                    <NavMenu />

                </Col>
            </Row>
        </Container>
        <ECOServiceNav />

        <Container>
            <Row>
                <Col md={1} lg={1}></Col>
                <Col>
                    <h1 className="fs-3 pt-5">Why Choose Evfy? <hr className="header-border-secondary border-width-long"/></h1>
                </Col>
                <Col md={1} lg={1}></Col>
            </Row>
            <Row>
                <Col md={1} lg={1}></Col>
                <Col>
                    <h2 className="text-center pt-5 mb-4 display-6 sub-title-h2">Join the Revolution <hr className="header-border-secondary border-width-long mx-auto mt-2" /></h2>
                    <p>Climate change has morphed from a global challenge into a Worldwide Emergency. According to a October 2021 report by the United Nations Environment Programme (UNEP), The Earth’s temperature is projected to hit 2.7ºC above pre-industrial levels by 2100, far higher than the 1.5ºC predicted just 3 months prior.
                    </p>
                    <p>In order to cap the temperature rise at 1.5ºC, the world would have to more than halve its annual greenhouse gas emissions by 2030. There’s not a lot of time left, but there is so much we can do. Work with Evfy, join us today.
                    </p>
                </Col>
                <Col md={1} lg={1}></Col>
            </Row>
            <Row>
                <Col md={1} lg={1}></Col>
                <Col> 
                    <h2 className="text-center pt-5 mb-4 display-6 sub-title-h2">Singapore’s Green Plan 2030 <hr className="header-border-secondary border-width-long mx-auto mt-2" /></h2>
                    <p>Singapore is increasingly well-positioned to tackle climate change. Among the multi-pronged approach, here’s some key milestones that will make EV logistics more than feasible in the coming years:
                    </p>
                    <div className="text-center py-5">
                        <img src={SGGreenPaln} alt="SG GREEN PLAN" width="80%" />
                    </div>
                </Col>
                <Col md={1} lg={1}></Col>
            </Row>
            <Row>
                <Col md={1} lg={1}></Col>
                <Col>
                    <h2 className="text-center pt-5 mb-4 display-6 sub-title-h2">Creating a Greener Future with Evfy <hr className="header-border-secondary border-width-long mx-auto mt-2" /> </h2>
                    <p>Keep yourself asset-light by tapping on Evfy’s full suite of green logistics solutions. Apart from our 100% Electric Van fleet that is rapidly expanding, Evfy also offers green warehousing and proprietary logtech, all of which will put you on a path to 0 direct carbon emissions for last mile logistics.
                    </p>
                    <p>A quick comparison with us any any other logistics provider:
                    </p>
                    <div className="text-center py-5">
                        <img src={CreatingFuture} alt="Creating Feature" width="80%" />
                    </div>
                    <p>We pride ourselves in helping organisations manage their transportation woes with our tech-enabled suite of solutions and our exceptional service. Let’s have a chat about how we  can help you reach your ESG goals!
                    </p>
                </Col>
                <Col md={1} lg={1}></Col>
            </Row>
            <Row>
                <Col md={1} lg={1}></Col>
                <Col className="py-5 text-center">
                    <button
                        onClick={() => setShow(true)}
                        className="btn" style={{ fontSize: '14px', backgroundColor: '#007CDE', borderRadius: '15px', color: '#fff', width: '180px' }}>
                        Enquire Now </button>
                </Col>
                <Col md={1} lg={1}></Col>
            </Row>
            {Dialog()}
        </Container>
        <Footer />
    </>
};
export default WhyEV;





