import { useEffect, useState } from "react";
import { Container, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import NavMenu from "../../Layout/navbar";
import EVListingNavMenu from '../partial/EVListingNav'
import "../../../assets/css/style.css"
import RegNow from './partial/RegNow'
import PayoutMethod from './partial/payoutMethod'
import WhyDriving from './partial/whyDriving'
import ThankYou from './partial/thankYou'
import Footer from "../../../components/CustomFooter";
import CommingSoon from "./partial/commingsoon";
import ThankYouCommingSoon from "./partial/thankYouCommingSoon";
import DriveWithUsOnBoarding from "./partial/driveWithUsOnBoarding";
const DriveWithUs = () => {
    const [state, setState] = useState();

    const handleSubmit =(value) => {
        if (value != null && value != ''){
          setState(value);
        } else {
            setState(1);
        }
       
    }

    useEffect(() => {
        handleSubmit(state);
      });

  return (<>
    <Container fluid className="p-0 m-0">
      <Row>
          <Col>
            <NavMenu />
          </Col>
        </Row>
        </Container>
        <EVListingNavMenu />  
        <Container fluid className="p-0 warpper-min-height" style={{overflowX:'hidden'}}>
          <Row>
            <Col>
                {/* {state && state == 1 ?  <RegNow handleSubmit={handleSubmit} /> : ''}
                {state && state == 2 ?  <WhyDriving handleSubmit={handleSubmit} /> : ''}
                {state && state == 3 ?  <PayoutMethod handleSubmit={handleSubmit} /> : ''}
                {state && state == 4 ?  <ThankYou handleSubmit={handleSubmit} /> : ''} */}

                {/* {state && state == 1 ?  <CommingSoon handleSubmit={handleSubmit} /> : ''}
                {state && state == 2 ?  <ThankYouCommingSoon handleSubmit={handleSubmit} /> : ''} */}
              <DriveWithUsOnBoarding />
            </Col>
          </Row>
        </Container>
        <Footer/>
        </>
  );
};
export default DriveWithUs;
