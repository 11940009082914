import react, { useState, useEffect } from "react";
import NavMenu from "../Layout/navbar";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import "../../assets/css/style.css"
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const EnquireNow = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    emailjs.sendForm('service_15dptsg', 'template_7jbe6ue', e.target, '2stbnOdEztvRCrsq-')
      .then((result) => {
          // console.log(result.text);
          setIsLoading(false);
          props.onClose();
          setShow(false);
      }, (error) => {
          // console.log(error.text);
          setIsLoading(false);
          setShow(false);
      });
  };

  return <Form ref={form} autoComplete="off" onSubmit={sendEmail}>
  <div className="mb-3">
  <label className="form-label">Name</label>
  <input type="name" name="name" className="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required placeholder="Name" />
  <div className="invalid-feedback">
        Please enter a username.
      </div>
</div>
<div className="mb-3">
  <label for="exampleFormControlInput1" className="form-label">Email</label>
  <input type="email" name="email_name" className="form-control" id="exampleFormControlInput1" placeholder="Email" required/>
</div>
<div className="mb-3">
  <label for="exampleFormControlTextarea1" className="form-label">Message</label>
  <textarea name="message" className="form-control" id="validationCustom03" rows="5" placeholder="Message" required></textarea>
  <div className="invalid-feedback">
        Please enter a Message.
      </div>
</div>
<input type="submit" className="btn btn-primary" value={isLoading ? 'Loading…' : 'Send Now'} disabled={isLoading ? true : false}/>
</Form>

};
export default EnquireNow;
