import react, { useState, useEffect } from "react";
import { Container, Row, Col, Card} from "react-bootstrap";
import NavMenu from "../../Layout/navbar";
// import AboutNav from "../partial/AboutNav";
import axios from 'axios';
import moment from 'moment';
import Footer from '../../Layout/Footer';
import { Link } from 'react-router-dom';
import "../../../assets/css/style.css"
import API from '../../../api/api';
import apiUtil from "../../../api/apiUtil";
import adsBanner from '../../../assets/img/BannerAds/160x600banner_ad.png'
import CustomLoading from "../../../components/CustomLoading";
import TempImg from '../../../assets/img/tempImg/temp.jpg'
import { Pagination } from 'react-pagination-handler'

const Blog = (props) => {

  const [blog, setBlog] = useState();
  const [data, setData] = useState();

  const [isLoading, setIsLoading] = useState(false)

  const callBlogApi = ( ) => {
      //let url = `${process.env.REACT_APP_API}/api/front_posts`
      const payLoad = {
        status: 'external',
        published:true,
        latest:true

      };
      setIsLoading(true)
      API.get('/api/front_posts', payLoad, '')
        .then((result) => {
          setBlog(result.data.data.data)
              let array = [];
              // <Col xs={12} md={12} lg={10}>
              setIsLoading(false)

              if(result) {
                      result.data.data.data.map((blog) => ( 
                        array.push(<div style={{width:'960px', minWidth:'220px', minHeight:'auto'}} className="card mb-3 card-min-height-global-news">
                          <a href={blog.link} style={{textDecoration: 'none', color:'#000'}} target="_blank">
                      <div>
                          <div className="row g-0">
                              <div className="col-md-4 blog-img-wrapper hover01">
                                  <img src={blog && blog.post_images && blog.post_images.length > 0 ? process.env.REACT_APP_API + '/' +blog.post_images[0].image_url : TempImg}   className="blog-img" alt={blog.title} />
                              </div>
                              <div className="col-md-8">
                                  <div className="card-body">
                                      <h5 className="card-title text-dark" >{blog.title}</h5>
                                      <p className="card-text text-dark text-decoration-none"><div dangerouslySetInnerHTML={{ __html: blog.description.substring(0, 150) + '...' }} /></p>
                                      <p className="card-text w-100 text-end text-decoration-none"><small className="text-decoration-none" style={{color: '#c4c4c4', fontSize:"13px"}}>{moment(blog.updated_at).format('LL')}</small></p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      </a></div>)
                      ))
                  // </Col>
                      }
        
          setData(array)
            })
            .catch((error) => {
              console.log(error)
              setIsLoading(false)
            })
    }

    useEffect(() => {
      callBlogApi();
    }, [])

  return (<>
    <Container fluid className="p-0 m-0">
    <Row>
     <Col>
       <NavMenu />
       
     </Col>
   </Row>
   </Container>
   {/* <AboutNav />    */}
        <Container style={{minHeight:'95vh'}}>
        <Row>
            <Col className="pt-5 mt-5 pb-5 text-center">
            <h1>Global News<hr className="header-border-primary border-width-long" style={{margin: "10px auto"}} /></h1>
            <p className="sub-title">Stay updated on current EV news!</p>
            </Col>
        </Row>
              <Row>
              {isLoading == true  ? <Col xs={12} md={12} lg={10}>
              <CustomLoading />
              </Col> :
              data?
              <Col xs={12} md={12} lg={10} className="px-3 pagination-right-position">
                <Pagination 
                className="d-flex justify-content-left"
                  items={data}
                  itemsPerPage={7}
                  paginationLabel={{display: 'none', fontSize: '1.25em'}}
                  pagesLimit= {2}
                  edgButton={{color: '#02AF95', background: 'none', border: 'none',fontSize: '3em', paddingBottom:'0.2em'}}
                  groupButton={{color: '#02AF95', background: 'none', border: 'none', fontSize: '1.5em'}}
                  singleButton={{color: '#02AF95', background: 'none', border: 'none', fontSize: '3em', paddingBottom:'0.2em'}}
                  pageButton={{color: '#02AF95', background: 'none', border: 'none', fontSize: '1.25em', padding: '5px 10px'}}
                  activePageButton={{color: '#ffffff', background: '#02AF95',border: 'none', borderRadius: '50%', fontSize: '1.25em', padding: '2px 10px'}}
                 /> 
                  {/* {blog && blog.map((blog) => ( 
                      <a href={blog.link} style={{textDecoration: 'none', color:'#000'}} target="_blank">
                  <div className="card mb-3" style={{ maxWidth: '100%' }}>
                      <div className="row g-0">
                          <div className="col-md-4 blog-img-wrapper hover01">
                              <img src={blog && blog.post_images && blog.post_images.length > 0 ? process.env.REACT_APP_API + '/' +blog.post_images[0].image_url : TempImg}   className="blog-img" alt={blog.title} />
                          </div>
                          <div className="col-md-8">
                              <div className="card-body">
                                  <h5 className="card-title text-dark" >{blog.title}</h5>
                                  <p className="card-text text-dark text-decoration-none"><div dangerouslySetInnerHTML={{ __html: blog.description.substring(0, 260) + '...' }} /></p>
                                  <p className="card-text w-100 text-end text-decoration-none"><small className="text-decoration-none" style={{color: '#c4c4c4', fontSize:"13px"}}>{moment(blog.updated_at).format('LL')}</small></p>
                              </div>
                          </div>
                      </div>
                  </div>
                  </a>
                   ))} */}
              </Col> : ''
              
              }
              <Col xs={12} md={12} lg={2} className="ads-banner mb-2">
              <a href="https://t.me/evfysg"><img src={adsBanner} alt="ads banner of evfy" width="100%" height="900vh"/></a>
              </Col>
          </Row>
        </Container>
        <Footer/>
    </>
  );
};
export default Blog;
