
import { Container, Row, Col, Form } from "react-bootstrap";
import { faCamera , faLocationArrow, faCommentAlt, faHeart, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TempImg from '../../../../assets/img/tempImg/temp.jpg'
import "../../../../assets/css/style.css"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const EQSec1 = () => {

    return (
        <Container >
            <Row>
                <Col xs={12} md={3} lg={3}>
                <div>
                <h3 className="fs-3 mt-5">
                      <span style={{ borderBottom: '2px solid #007CDE' }}>Ask</span> Away!
                </h3>
                </div>
                <div className="pt-4">
                    <ul type="none">
                        <li className="mb-3 ps-2" style={{borderLeft:'4px solid #007CDE'}}>All Categories</li>
                        <li className="mb-3 ps-2 ">EV Charging</li>
                        <li className="mb-3 ps-2 ">EV Maintenance</li>
                        <li className="mb-3 ps-2 ">Smoothdrive 2.0</li>
                        <li className="mb-3 ps-2 ">EV Rentals</li>
                        <li className="mb-3 ps-2 ">General</li>
                    </ul>
                </div>
                </Col>
                <Col xs={12} md={9} lg={9}>
                <div className="mt-5">
                        <p className="filter-text"><span className="hidden">Order By:</span>
                            <button type="button" className="btn btn-active-question ms-4" name="question"
                                value="1"><span>Recently Answered</span>
                            </button>
                            <button type="button" className="btn tag-question ms-4" name="question"
                                value="2"> New Questions
                            </button>
                            <button type="button" className="btn tag-question ms-4" name="question"
                                value="3"> Popular Questions
                            </button>
                        </p>
                    </div>
                    <div>
                    <Form.Group as={Row} className="border"  style={{borderRadius:"15px"}} controlId="search question">
                        <Row className="align-self-center align-items-center pt-3 pb-3">
                            <Col xs={10}>
                                <Form.Control type="text" placeholder="What’s your question?" className="border-0"/>
                            </Col>
                            <Col xs={1}>
                                <FontAwesomeIcon icon={faCamera} style={{ fontSize: '18px', color: '#a3a3a3' }} />
                            </Col>
                            <Col xs={1}>
                                <FontAwesomeIcon icon={faLocationArrow} style={{ fontSize: '18px', color: '#a3a3a3' }} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <span>*For new users, do allow 1 to 2 working days for your question (and answers) to reflect on the forum</span>
                    </div>
                    <Row className="border mt-4"  style={{borderRadius:"15px"}}>
                        <Row >
                            <Col xs={2} className="py-2 text-center" >
                                <img alt="temp" src={TempImg} width="50px" height="50px" style={{borderRadius: '50%'}}/> 
                            </Col>
                            <Col xs={10} className="py-2" >
                                <strong>User1</strong> asked about <strong>Smoothdrive 2.0</strong><br/>
                                <span className="pe-3 text-muted"><small>6 days ago</small></span>
                                <span className="pe-3 text-muted"><small>60 views</small></span>
                                <span className="pe-3 text-muted"><small>1 Answer</small></span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ps-5 py-3" >When can I redeem my benefits?</Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="text-center my-3" style={{borderRight:"2px solid #a3a3a3"}}>
                            <FontAwesomeIcon icon={faCommentAlt} style={{ fontSize: '18px', color: '#a3a3a3' }} />
                            &nbsp; &nbsp; &nbsp; Answer
                            </Col>
                            <Col xs={4} className="text-center my-3" style={{borderRight:"2px solid #a3a3a3"}}>
                            <FontAwesomeIcon icon={faHeart} style={{ fontSize: '18px', color: '#a3a3a3' }} />
                            &nbsp; &nbsp; &nbsp; Save
                            </Col>
                            <Col xs={4} className="text-center my-3">
                            <FontAwesomeIcon icon={faShare} style={{ fontSize: '18px', color: '#a3a3a3' }} />
                            &nbsp; &nbsp; &nbsp; Share
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
export default EQSec1;
