import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Card} from "react-bootstrap";
import NavMenu from "../../Layout/navbar";
import AboutNav from "../partial/AboutNav";
import TempImg from '../../../assets/img/About/GreenWarehousing.jpg'
import "../../../assets/css/style.css"
import Footer from "../../Layout/Footer"
import API from "../../../api/api"
import apiUtil from '../../../api/apiUtil';
import moment from 'moment';
import CustomLoading from '../../../components/CustomLoading'
import { Pagination } from 'react-pagination-handler'
const Press = (props) => {
  let pressTitle = '';
  const [presses, setPresses] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState();

  useEffect(()=>{
    getPresses()
  }, [])

  const getPresses = () => {

    let data = {
      latest: true,
      //limit: 3,
    }
    setIsLoading(true)
    API.get('/api/front_presses', data, '')
    .then((result) => {
       apiUtil.parseResult(result, (data) => { 
        let array = [];
        data.data.map((press) => ( 
          array.push(<Col xs={12} sm={12} md={6} lg={4} className="pb-5 d-flex justify-content-center">
            <Card style={{ minWidth: 297, width: '80%', borderRadius: "15px", alignItems: 'center'}}>
             <span style={{display:'none'}}> {pressTitle = press.title && press.title.replace(/\s/g, '-') }</span>
            <a href={`/press/${press.id}/${pressTitle}`} style={{textDecoration:'none'}}>
            <div className="pickEv-img-wrapper2 hover01" >
              <img alt={press.title} src={press.image_url ? `${process.env.REACT_APP_API}/${press.image_url}`: TempImg} className="pickev-img"/>
              </div>
              <Card.Body >
                  <Card.Text className="text-center card-title text-dark">
                    {press && press.title && press.title.length > 50 ? `${press.title.substring(0, 50)}...`: press.title}
                  </Card.Text>
                  <Card.Text className="text-center" style={{color:'#C4C4C4'}}>
                  {press != null ?  moment(press.updated_at).format('LL') : ''}
                  </Card.Text>
              </Card.Body>
              </a>
            </Card>
            </Col>)
        ))
        let result = data.data && data.data.length > 0 ? data.data : [];
        setPresses(result)
        setData(array)
        setIsLoading(false)
       }, (error, type) => {
        setIsLoading(false)
       });
    });
 }

  return (<>
    <Container fluid className="p-0 m-0">
    <Row>
     <Col>
       <NavMenu />
       
     </Col>
   </Row>
   </Container>
   <AboutNav />   
        <Container style={{minHeight:'95vh'}}>
        <Row>
            <Col className="pt-5 pb-5 text-center">
            <h1>Press Releases <hr className="header-border-secondary border-width-long" style={{margin: "10px auto"}} /></h1>
            <p className='sub-title'>Download our official media kit <span className='text-secondary'>here</span></p>
            </Col>
        </Row>
        <Row className="d-flex justify-content-center align-items-center p-0 mt-0" style={{minHeight:'350px'}}>
            {isLoading == true  ? <Col xs={12} md={12} lg={10}>
              <CustomLoading />
              </Col>
              :
            data?
            <Col xs={12} md={12} lg={12} className="pb-5 d-flex justify-content-center">
              <Pagination 
              className="d-flex justify-content-left"
                items={data}
                itemsPerPage={15}
                paginationLabel={{display: 'none', fontSize: '1.25em'}}
                pagesLimit= {2}
                edgButton={{color: '#02AF95', background: 'none', border: 'none',fontSize: '3em', paddingBottom:'0.2em'}}
                groupButton={{color: '#02AF95', background: 'none', border: 'none', fontSize: '1.5em'}}
                singleButton={{color: '#02AF95', background: 'none', border: 'none', fontSize: '3em', paddingBottom:'0.2em'}}
                pageButton={{color: '#02AF95', background: 'none', border: 'none', fontSize: '1.25em', padding: '5px 10px'}}
                activePageButton={{color: '#ffffff', background: '#02AF95',border: 'none', borderRadius: '50%', fontSize: '1.25em', padding: '2px 10px'}}
               /> 
                {/* {blog && blog.map((blog) => ( 
                    <a href={blog.link} style={{textDecoration: 'none', color:'#000'}} target="_blank">
                <div className="card mb-3" style={{ maxWidth: '100%' }}>
                    <div className="row g-0">
                        <div className="col-md-4 blog-img-wrapper hover01">
                            <img src={blog && blog.post_images && blog.post_images.length > 0 ? process.env.REACT_APP_API + '/' +blog.post_images[0].image_url : TempImg}   className="blog-img" alt={blog.title} />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title text-dark" >{blog.title}</h5>
                                <p className="card-text text-dark text-decoration-none"><div dangerouslySetInnerHTML={{ __html: blog.description.substring(0, 260) + '...' }} /></p>
                                <p className="card-text w-100 text-end text-decoration-none"><small className="text-decoration-none" style={{color: '#c4c4c4', fontSize:"13px"}}>{moment(blog.updated_at).format('LL')}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                </a>
                 ))} */}
            </Col> : ''
            }
        </Row>
            
        </Container>
        <Footer/>
    </>
  );
};
export default Press;
