import { Container, Row, Col, Accordion} from "react-bootstrap";
import TempImg from '../../../../assets/img/tempImg/temp.jpg'
import "../../../../assets/css/style.css"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bgImg from "../../../../assets/img/background/about_bg.jpg"
import logoImg from "../../../../assets/img/logo/evfy-logo-square.png"
import CarrerInfo from "./carrerinfo";
import { useState } from "react";

const CarrerType = (props) => {

    const  [state, setState] = useState();

    const handleSubmit = (value) => {
        props.handleSubmit(value);
    }

  return (
    <Container fluid className="pb-5 mb-5 m-0" style={{marginBottom:'120px'}}>
         <Row>
            <Col className="pt-5 pb-5 text-center">
            <h1>Careers <hr className="header-border-secondary border-width-short" style={{margin: "10px auto"}} /></h1>
            <p className="sub-title">Be a first-mover today!</p>
            </Col>
        </Row>
                <CarrerInfo/>
                <div className="pb-5 mb-5"></div>
    </Container>
  );
};
export default CarrerType;
