import react, { useState } from "react";
import Footer from "../views/Layout/Footer"

const CustomFooter = (props) => {


    return (<div style={{}}>
            <Footer/>
    </div>)
}
export default CustomFooter


