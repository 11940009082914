import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from 'react-router-dom';
import Home from './views/Home/home';
import EVListing from "./views/driverPartner/EVListing/EVListing"
import DriveWithUs from "./views/driverPartner/DriveWithUs/DriveWithUs";
import MissionVision from "./views/About/visionMisssion/MissionVision";
import Press from "./views/About/Press/Press";
import EcoAllobration from "./views/About/Ecoallobration/Ecoallobration";
import Contact from "./views/About/contact/Contact";
import Carrer from "./views/About/carrer/Carrer";
import EvCarthering from "./views/Services/ECOCathering";
import LastMileDelivery from "./views/Services/LastMileDelivery";
import GreenWarehouse from "./views/Services/GreenWarehouse";
import WhyEV from "./views/Services/WhyEV";
import ExploreQuestion from "./views/EvEverything/ExploreQuestion/ExploreQuestion";
import EVChargingRate from "./views/EVChargingRate/EVChargingRate";
import BenifitEV from "./views/driverPartner/EVBenifit/BenifitEV";
import Blog from "./views/About/Blog/blog";
import BlogDetail from "./views/About/Blog/blogDetail";
import Insight from "./views/About/Blog/insight";
import SmoothDriveCommingSoon from "./views/driverPartner/EVSmoothDrive/smoothDriveCommingSoon";
import Onboarding from "./views/onboarding/onboarding";
import CarrerDetails from "./views/About/carrer/partial/carrerDetail";
import PressDetails from "./views/About/Press/pressDetails";
import DriveWith from "./views/driverPartner/EVSmoothDrive/driveWith";
import SmoothDrive from "./views/driverPartner/EVSmoothDrive/smoothDrive";
import TermOfUse from "./views/Pages/TermsOfUse";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";


function useAnalytics(){
//let location = useLocation()
  useEffect(() => {
    if(!window.GA_INITIALIZED){
      ReactGA.initialize("UA-226525335-1");
      window.GA_INITIALIZED = true;

    }
    ReactGA.set({ page: window.location.pathname});
    ReactGA.pageview(window.location.pathname)
  }, [window.location]);
  
}

const tagManagerArgs = {
  gtmId: 'GTM-WJZ6MV3'
}

function App() {
  useAnalytics();
  TagManager.initialize(tagManagerArgs)
  return (<>
    <React.Fragment>
            <Router>
              <Switch>
                <Route exact path="/"><Redirect to="/home" /></Route>
                <Route exact path="/home" component={Home} />
                <Route exact path="/ev-charging-prices" component={EVChargingRate} />
                <Route exact path="/global-news" component={Blog} />
                <Route exact path="/insights" component={Insight} />
                <Route exact path="/insight/:id/:title" component={BlogDetail} />
                
                {/* <Route exact path="/choose-vehicle" component={ChooseVehicle} />
                <Route exact path="/benefit" component={Benefit} />
               
                <Route exact path="/green-new" component={GreenNew} />
                <Route exact path="/govt-reg" component={GovtReg} />
                <Route exact path="/warehouse" component={Warehouse} />
                <Route exact path="/site-delivery" component={SiteDelivery} /> */}

                {/* ------ECO Service------ */}
                <Route exact path="/ev-chartering" component={EvCarthering} />
                <Route exact path="/last-mile-delivery" component={LastMileDelivery} />
                <Route exact path="/green-warehousing" component={GreenWarehouse} />
                <Route exact path="/why-ev" component={WhyEV} />

                {/* ------About------ */}
                 <Route exact path="/mission-vision" component={MissionVision} />
                 <Route exact path="/press" component={Press} />
                 <Route exact path="/press/:id/:title" component={PressDetails} />
                 <Route exact path="/Eco-allobration" component={EcoAllobration} />
                 <Route exact path="/contact" component={Contact} />
                 <Route exact path="/careers" component={Carrer} />
                 <Route exact path="/career-details/:id" component={CarrerDetails} />

                 {/* ------Drive With Us------ */}
                <Route exact path="/ev-listing/:id" component={EVListing} />
                <Route exact path="/ev-listing" component={EVListing} />
                <Route exact path="/benefit-of-ev" component={BenifitEV} />
                <Route exact path="/smoothdrive-benefits" component={SmoothDrive} />
                <Route exact path="/smoothdrive" component={DriveWith} />
                <Route exact path="/drive-with-us" component={DriveWithUs} />

                {/* ------EV-everyting------ */}
                <Route exact path="/explore-question" component={ExploreQuestion} />
                <Route exact path="/saved-question" component={ExploreQuestion} />
                <Route exact path="/my-question" component={ExploreQuestion} />

                <Route exact path="/fms/register" component={Onboarding} />
                {/* <Route exact path="/van-chartering" component={VanChartering} />
                <Route exact path="/green-colaboration" component={GreenColaboration} />
                <Route exact path="/coe" component={Coe} />
                <Route exact path="/lease-calculator" component={LeaseCalc} />
                <Route exact path="/charging-network" component={ChargingNertwork} />
                <Route exact path="/community" component={Community} />
                <Route exact path="/carbon-calculator" component={CarbonCalculator} />
                <Route exact path="/component" component={Component} /> */}
                <Route exact path="/term-of-use" component={TermOfUse} />
              </Switch>
            </Router>
    </React.Fragment>
    </>
  );
}

export default App;
