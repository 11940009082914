import react, { useState, useEffect } from "react";
import uuid from "react-uuid";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../../../assets/css/style.css"
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import client_blue from '../../../assets/img/onboarding/client_blue.png'
import vendor_white from '../../../assets/img/onboarding/vendor_white.png'
import fleet_blue from '../../../assets/img/onboarding/fleet_blue.png'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import api from "../../../api/api";
import Swal from "sweetalert2";
import apiUtil from "../../../api/apiUtil";
import { default as ReactSelect} from "react-select";
import { components} from "react-select";
import { PostalCodes } from "../../../data/postal-codes";


const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const OnboardingVendor = (props) => {

  let postalCodes = new PostalCodes();

  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("")
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState("")

  const [emailValidated, setEmailValidated] = useState(false)
  const [companyNameValidated, setCompanyNameValidated] = useState(false)
  const [nameValidated, setNameValidated] = useState(false)
  const [phoneValidated, setPhoneValidated] = useState(false)
  const [idealPostalCode, setIdealPostalCode] = useState([])
  const [fleetTypes, setFleetTypes] = useState([{fleet: '', fleetType: [], uuid: uuid() }])
  const [anyOtherInfo, setAnyOtherInfo] = useState('')


  const callCreateApi = (row, callback = null) => {
    // const { accessToken } = this.context;

    setIsLoading(true)
      api.vendor_create('vendor/create', row, null)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            setIsLoading(false)
              if(callback){
                callback(data);
              }
          }, (error, type) => {
            // Swal.fire(error, 'error');
            setIsLoading(false)
              if(callback){
                callback();
                apiUtil.toast(error, '',  'error');
              }
          });
      });
  }


  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    setEmailValidated(false)
    setCompanyNameValidated(false)
    setNameValidated(false)
    setPhoneValidated(false)
    
    let valdatedEmail = true
    if(!email || email == '' || !apiUtil.emailValidation(email)) {
      setEmailValidated(true)
      valdatedEmail = false
    }

    if(!companyName || companyName == '') {
      setCompanyNameValidated(true)
    }

    if(!name || name == '') {
      setNameValidated(true)
    }

    let validPhone = true
    if( phone && (phone != 65 || phone != "65" ) && !apiUtil.phoneValidationWithPlus(phone)) {
      setPhoneValidated(true)
      validPhone = false
    }

    if (form.checkValidity() === true && valdatedEmail && validPhone) {

      let extra_data  = {
        phone_number: phone == 65 ? '' : `+${phone}`,
      }
      let ideal_postal_code = ''
      if(idealPostalCode.length > 0) {
        idealPostalCode.map((item, i) => { ideal_postal_code += item.value +','; });
      }
      ideal_postal_code = ideal_postal_code.slice(0, -1);

      let fleet_size = ''
      let fleet_types = fleetTypes.filter(fz => fz.fleet || (fz.fleetType && fz.fleetType.value))
      if(fleet_types.length > 0) {
        fleet_types.map(flee => {
          fleet_size += `${flee.fleet} - ${flee.fleetType && flee.fleetType.value ? flee.fleetType.value : ''},`
        })
      }
      
      fleet_size = fleet_size.slice(0, -1);
      
      let data = {
        company_name: companyName,
        name: name,
        email: email,
        extra_data: JSON.stringify(extra_data),
        country: "Singapore",
        phone: phone == 65 ? '' : `+${phone}`,
        ideal_postal_code,
        fleet_size,
        any_other_info: anyOtherInfo,
        adm_id: (window.location.hostname === 'localhost' || window.location.hostname === "127.0.0.1" || window.location.hostname.includes('staging')) ? 424 : 785 ,

      };
     callCreateApi(data, (result) => {
      if(result) {
        props.onSave(null, true)
      }
     })
    }
  }

  const addNewFleet = () => {
  let fleets = [...fleetTypes, {fleet: '', fleetType: [], uuid: uuid() }]
    
    
    setFleetTypes(fleets)
  }

  const removeFleet = (id) => {
    let fleets = [...fleetTypes]
    fleets = fleets.filter(fz => fz.uuid != id)

    setFleetTypes(fleets)
  }

  

  return (
    <Row className="p-0 m-0">
        <Col xs={12} md={6} lg={6} className="left-menu-container p-3 m-0 d-flex flex-column align-items-center">
          <h4 className="text-white text-center">How can we work together?</h4>
          <img src={client_blue} alt="client" width="300px" className="my-3 img-btn"
          onClick={()=> {
            props.onSave(1)
          }}/>
          <img src={vendor_white} alt="vendor" width="300px" className="my-3 img-btn"
          onClick={()=> {
            props.onSave(2)
          }}/>
          <img src={fleet_blue} alt="fleet" width="300px" className="my-3 img-btn"
          onClick={()=> {
            props.onSave(3)
          }}/>
          <div className="text-white mx-4 text-justify my-3">Choose the option that best suits you. Pay for only what you need, and our Fleet Management System will be fully integrated with your business!</div>
          <div className="text-white mx-4 text-left my-3 cursor_underline" 
          onClick={()=>{
            props.onShow(true)
          }}>See which one suits you</div>
        </Col>
        <Col xs={12} md={6} lg={6} className="right-menu-container p-0 m-0 d-flex flex-column align-items-center py-3 px-3">
        <form onSubmit={handleSubmit} class="row g-3 needs-validation" novalidate>
          <h3 className="text-left w-100 px-3">Create your account now!</h3>
          <div class="mb-3 w-100 px-3">
            <label class="form-label fw-bold">Country*</label>
              <select class="form-select w-100" aria-label="Default select example" required>
                <option value='' selected>--- Select ---</option>
                <option value="1">Singapore</option>
              </select>
          </div>
          <div class="mb-3 w-100 px-3 fw-bold">
            <label class="form-label">Company Name*</label>
            <input type="text" class="form-control w-100"  placeholder="Company Name"  required
            value={companyName}
            onChange={e => setCompanyName(e.target.value.trim().length === 0 ? '' : e.target.value)}
            />
          </div>
          <div class="mb-3 w-100 px-3">
            <label class="form-label fw-bold">Phone Number</label>
            <PhoneInput
              className={`custom-phone-input ${phoneValidated ? "input-error" : ''}`}
              country={"sg"}
              enableSearch={false}
              disableDropdown={true}
              value={phone}
              countryCodeEditable={false}
              onChange={(phone) => {
                setPhone(phone)
                setPhoneValidated(false)
              }
              }
            />
          </div>
          <div class="mb-3 w-100 px-3">
            <label class="form-label fw-bold">POC Name*</label>
            <input type="text" class="form-control w-100"  placeholder="Peter"  required
            value={name}
            onChange={e=>setName(e.target.value.trim().length === 0 ? '' : e.target.value)}
            />
          </div>
          <div class={`mb-3 w-100 px-3 ${emailValidated ? "input-error" : ''}`}>
            <label class="form-label fw-bold">Email Address*</label>
            <input type="email" class="form-control w-100"  placeholder="peter@gmail.com"  required
            value={email}
            onChange={e=>{
              setEmail(e.target.value)
              setEmailValidated(false)
            }}

            />
          </div>
          {/* <div class="mb-3 w-100 px-3">
            <label class="form-label">Password</label>
            <input type="password" class="form-control w-100"  placeholder="" />
          </div> */}
          <div class="mb-3 w-100 px-3">
            <label class="form-label fw-bold">Ideal Postal Code</label>
            <ReactSelect
            isMulti
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            components={{Option}}
            hideSelectedOptions={false}
            options={postalCodes.getAll()}
            value={idealPostalCode}
            onChange={selected => setIdealPostalCode(selected)}
            />
          </div>
          <div class="mb-3 w-100 px-3">
            <label class="form-label fw-bold">Fleet Size</label>
            <span className="d-flex align-items-center my-1" >
              
              <ReactSelect
              className=" w-75 ms-2 basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              value={fleetTypes[0].fleetType}
              onChange={selected => {
                let fleet = [...fleetTypes]
                fleet.forEach(flee => {
                  if(flee.uuid == fleetTypes[0].uuid) {
                    flee.fleetType = selected
                  }
                })
                setFleetTypes(fleet)
              }
              }
              options={[{value:"Car", label:"Car"}, {value:"MPV", label:"MPV"}, {value:"1.7m Van", label:"1.7m Van"}, {value:"2.4m Van", label:"2.4m Van"}, {value:"10 Ft Lorry", label:"10 Ft Lorry"}, {value:"14 Ft Lorry", label:"14 Ft Lorry"}]}
              />
              <input type="number" class="form-control w-25 ms-2"  placeholder=""
              value={fleetTypes[0].fleet}
              onChange={e => {
                let fleets = [...fleetTypes]
                fleets.forEach(fee => {
                  if(fee.uuid == fleetTypes[0].uuid) {
                    fee.fleet = e.target.value
                  }
                })
                setFleetTypes(fleets)
              }
              }
              />
              {fleetTypes.length ==  1 ? <span style={{cursor:'pointer'}} className="ms-2"
                onClick={()=>{
                  addNewFleet()
                }}
                ><FontAwesomeIcon icon={ faPlusCircle } style={{color:  '#007CDE'}}/></span> : <span className="ms-2 "><FontAwesomeIcon icon={ faPlusCircle } style={{color:  'white'}}/></span>  }
              <span className="ms-2 "><FontAwesomeIcon icon={ faPlusCircle } style={{color:  'white'}}/></span>
              </span> 
                  
            { fleetTypes &&  fleetTypes.slice(1).length > 0  && fleetTypes.slice(1).map((fz,i) => (
              <span key={i} className="d-flex align-items-center my-1" >
              
              <ReactSelect
              className=" w-75 ms-2 basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              value={fz.fleetType}
              onChange={selected => {
                let fleet = [...fleetTypes]
                fleet.forEach(flee => {
                  if(flee.uuid == fz.uuid) {
                    flee.fleetType = selected
                  }
                })
                setFleetTypes(fleet)
              }
              }
              options={[{value:"Car", label:"Car"}, {value:"MPV", label:"MPV"}, {value:"1.7m Van", label:"1.7m Van"}, {value:"2.4m Van", label:"2.4m Van"}, {value:"10 Ft Lorry", label:"10 Ft Lorry"}, {value:"14 Ft Lorry", label:"14 Ft Lorry"}]}
              />
              <input type="number" class="form-control w-25 ms-2"  placeholder=""
              value={fz.fleet}
              onChange={e => {
                let fleets = [...fleetTypes]
                fleets.forEach(fee => {
                  if(fee.uuid == fz.uuid) {
                    fee.fleet = e.target.value
                  }
                })
                setFleetTypes(fleets)
              }
              }
              />
              <span style={{cursor:'pointer'}} className="ms-2"
                onClick={()=>{
                    removeFleet(fz.uuid)
                  
                }}
              ><FontAwesomeIcon icon={ faMinusCircle} style={{color:  "red"}}/></span> 
              <span style={{cursor:'pointer'}} className="ms-2"
                onClick={()=>{
                  addNewFleet()
                }}
              ><FontAwesomeIcon icon={ faPlusCircle } style={{color:  '#007CDE'}}/></span>  
              </span> 
            ))}
          </div>
          
          <div class="mb-3  w-100 px-3">
            <label class="form-label fw-bold">Any other information</label>
            <textarea class="form-control w-100" rows="3"
            onChange={e=>setAnyOtherInfo(e.target.value)}
            ></textarea>
          </div>
          <div class="mb-3 w-100 px-3 d-flex flex-column align-items-center">
          <button className="btn contact_button" type="submit"
          disabled={isLoading}
                  // onClick={()=> {
                  //   props.onSave(null, true)
                  // }}
                  >{isLoading? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "REGISTER"}</button>
            <span className="text-center px-3 my-2">Already have an account? Log in <a href="https://fms.evfy.sg/">here</a></span>
            <span className="text-center px-3">By signing up, you agree to our <a href="https://fms.evfy.sg/terms_and_conditions">Terms of Service</a> and our <a href="https://fms.evfy.sg/privacy_policy">Privacy Policy</a></span>
          </div>
        </form>
        </Col>
    </Row>
  );
};
export default OnboardingVendor;


