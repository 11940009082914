
import { Container, Row, Col, Form , Modal} from "react-bootstrap";
import NavMenu from "../Layout/navbar";
import "../../assets/css/style.css"
import EVChargingImg from '../../assets/img/charging/ACDC.jpg'
import TempImg from '../../assets/img/press/evfy-electric.jpg'
import { useEffect, useState } from "react";
import axios from "axios";
import Footer from '../Layout/Footer'
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faFacebookMessenger, faTelegram, faViber, faWhatsapp } from '@fortawesome/free-brands-svg-icons' 
import MultiRangeSlider from "./MultipleRange/MultiRangeSlider"

const EVChargingRate = () => {
    

    const [activeBotton, setActiveBottom] = useState("3");
    const [open, setOpen] = useState(false);
    const [dataEv, setDataEv] = useState([]);
    const [evPrices, setEvPrices] = useState([]);
    const [evPricesFilter, setEvPricesFilter] = useState([]);
    const [evAcPrices, setEvAcPrices] = useState([]);
    const [evDcPrices, setEvDcPrices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [speedPrices, setSpeedPrices] = useState([]);
    const [batteryCapacity, setBatteryCapacity] = useState(50.3);

    const [minValue, set_minValue] = useState(0);
    const [maxValue, set_maxValue] = useState(100);
    const handleInput = (e) => {
        set_minValue(e.minValue);
        set_maxValue(e.maxValue);
    };

    const handleClose = () => setOpen(false);

    useEffect(() => {
        getEVchargingPrices(40.001, 100000)
        getEVchargingfilter(40.001, 100000)
        chargingPricesSpeed(50.3, 0, 100, false);
    },[]);

    const getEVchargingPrices = (minKw = '', maxKw = '') => {
        let url = `${process.env.REACT_APP_API}/api/front_evprices`;
        const params = {
            latest: true,
            group_by_provider: true,
            max_kw: maxKw,
            min_kw: minKw,
            filter: true,
          };
        axios
          .get(url, {params})
          .then((response) => {
              setEvPrices(response.data.data)
          })
          .catch((error) => {
            console.log(error);
          });
    }

      const getEVchargingfilter = (minKw = '', maxKw = '') => {
        let url = `${process.env.REACT_APP_API}/api/ev_charging_price`;
        const params = {
            latest: true,
            group_by_provider: true,
            max_kw: maxKw,
            min_kw: minKw,
            filter: true,
            status: true,
          };
        axios
          .get(url, {params})
          .then((response) => {
            let json_push = [];
            for (var i in response.data.data) {
                if(response.data.data[i].acPrice != '' || response.data.data[i].dcPrice != '') {
                    json_push.push(response.data.data[i])
                }
            }
            var json_data = json_push;
            var dcMax = [];
            var acMax = [];
           
            var ev_ac_data = '';
            var ev_dc_data = '';
            for (var i in json_data) {
                let acArray = [];
                let dcArray = [];
                acArray = json_data[i].acPrice  == '' || json_data[i].acPrice  == null ? [] : json_data[i].acPrice;
                if(acArray != '') {
                for (var key in acArray) {
                    if (acArray.hasOwnProperty(key)) {
                        ev_ac_data = acArray[key].ev_ac_cost  == '' || acArray[key].ev_ac_cost  == null ? 0 : acArray[key].ev_ac_cost ;
                        }
                    }
                } else {
                    ev_ac_data = 0 ;
                }
                acMax[i] = ev_ac_data;

                dcArray = json_data[i].dcPrice  == '' || json_data[i].dcPrice  == null ? [] : json_data[i].dcPrice;
                if(dcArray != '') {
                for (var key in dcArray) {
                    if (dcArray.hasOwnProperty(key)) {
                        ev_dc_data = dcArray[key].ev_dc_cost  == '' || dcArray[key].ev_dc_cost  == null ? 0 : dcArray[key].ev_dc_cost ;
                        }
                    }
                } else {
                    ev_dc_data = 0 ;
                }
                dcMax[i] = ev_dc_data;

                // acArray = json_data[i].acPrice;
                // acArray.foreach((data) => 
                //     ev_ac_data = data.ev_ac_cost
                // )
                // acMax[i] = ev_ac_data == '' || ev_ac_data  == null? 0 : ev_ac_data;

                // dcArray = json_data[i].dcPrice;
                // dcArray.map((data) => 
                //     ev_dc_data = data.ev_dc_cost
                // )
                // dcMax[i] = ev_dc_data == '' || ev_dc_data  == null? 0 : ev_dc_data;
            }
              setEvPricesFilter(json_push)
              setEvAcPrices(acMax);
              setEvDcPrices(dcMax);
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const sort_by = (field, reverse, primer) => {

        const key = primer ?
          function(x) {
            return primer(x[field])
          } :
          function(x) {
            return x[field]
          };
      
        reverse = !reverse ? 1 : -1;
      
        return function(a, b) {
          return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
        }
      }


      const chargingPricesSpeed = (battery_capacity, min, max, state) => {
             if(state == false) {
                 setLoading(true);
             let url = `${process.env.REACT_APP_API}/api/front_evprices`;
            const params = {
                latest: true,
                // group_by_provider: true,
              };
            axios
              .get(url, {params})
              .then((response) => {
                setDataEv(response.data.data)
                let data = response.data.data;
                setLoading(false);let groupded = groupBy(data, 'provider_id')
                groupded = Object.values(groupded)
                if(groupded.length > 0) {
                    let dataArray = []
                    groupded.forEach(gp => {
                        let row = {}
                        row['ev_ac'] = []
                        row['ev_dc'] = []
                        let max_ac_cost = 0
                        let max_dc_cost = 0
                        let tempAC = [];
                        let tempDC = [];
                        gp.forEach(element => {

                            if(element.ev_ac == 0 || element.ev_ac == ''){
                                
                            } else {
                                tempAC.push(element.ev_ac)
                            }
                            if (element.ev_dc == 0 || element.ev_dc == ''){
                               
                            } else {
                                tempDC.push(element.ev_dc)
                            }

                            // tempAC.push(element.ev_ac);
                            // tempDC.push(element.ev_dc);

                            if (element.ev_ac_cost > max_ac_cost) {
                                max_ac_cost = element.ev_ac_cost
                            }
                            if (element.ev_dc_cost > max_dc_cost) {
                                max_dc_cost = element.ev_dc_cost
                            }
                        })
                        
                        tempAC = tempAC.sort(function(a, b) {
                                    return a - b;
                        });

                        tempDC = tempDC.sort(function(a, b) {
                                    return a - b;
                                });
                                
                        // remove same elements
                        let groupedAC = [...new Set(tempAC)];
                        let groupedDC = [...new Set(tempDC)];

                        // add KW to each element
                        groupedAC = groupedAC.map(i => i + 'kW');
                        groupedDC = groupedDC.map(i => i + 'kW');

                        row['ev_ac'].push(groupedAC);
                        row['ev_dc'].push(groupedDC);
                        // row['ev_dc'] = row['ev_dc'].slice(0,-1)
                        row['ac_estimated'] = max_ac_cost * battery_capacity * ((max/100) - (min/100))
                        row['dc_estimated'] = max_dc_cost * battery_capacity * ((max/100) - (min/100))
                        row['ev_provider'] = gp[0]['ev_provider']
                        dataArray.push(row)
                        // console.log(" dataArray", dataArray)
                    })

                    setSpeedPrices(dataArray)
                    // console.log(speedPrices," SpeedPrices")
                }
              })
              .catch((error) => {
                setLoading(false);
                console.log(error);
              });
            }
              if(dataEv != '') {
                let groupded = groupBy(dataEv, 'provider_id')
                groupded = Object.values(groupded)
                if(groupded.length > 0) {
                    let dataArray = []
                    groupded.forEach(gp => {
                        let row = {}
                        row['ev_ac'] = []
                        row['ev_dc'] = []
                        let max_ac_cost = 0
                        let max_dc_cost = 0
                        let tempAC = [];
                        let tempDC = [];
                        gp.forEach(element => {

                          
                            if(element.ev_ac == 0 || element.ev_ac == ''){
                                
                            } else {
                                tempAC.push(element.ev_ac)
                            }
                            if (element.ev_dc == 0 || element.ev_dc == ''){
                               
                            } else {
                                tempDC.push(element.ev_dc)
                            }

                            // tempAC.push(element.ev_ac);
                            // tempDC.push(element.ev_dc);

                            if (element.ev_ac_cost > max_ac_cost) {
                                max_ac_cost = element.ev_ac_cost
                            }
                            if (element.ev_dc_cost > max_dc_cost) {
                                max_dc_cost = element.ev_dc_cost
                            }
                        })
                        
                        // remove same elements
                        tempAC = tempAC.sort(function(a, b) {
                                    return a - b;
                                });

                        tempDC = tempDC.sort(function(a, b) {
                                    return a - b;
                                });

                        let groupedAC = [...new Set(tempAC)];
                        let groupedDC = [...new Set(tempDC)];

                        // add KW to each element
                        groupedAC = groupedAC.map(i => i + ' kW');
                        groupedDC = groupedDC.map(i => i + ' kW');

                        row['ev_ac'].push(groupedAC);
                        row['ev_dc'].push(groupedDC);
                        // row['ev_dc'] = row['ev_dc'].slice(0,-1)
                        row['ac_estimated'] = max_ac_cost * battery_capacity * ((max/100) - (min/100))
                        row['dc_estimated'] = max_dc_cost * battery_capacity * ((max/100) - (min/100))
                        row['ev_provider'] = gp[0]['ev_provider']
                        dataArray.push(row)
                        // console.log(" dataArray", dataArray)
                    })

                    setSpeedPrices(dataArray)
                    // console.log(speedPrices," SpeedPrices")
                }
              }
    }

    function compare(a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        // a must be equal to b
        return 0;
      }

    const groupBy = (xs, key) => {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      }

      const Dialog = () => {
        return <Modal
        className="d-flex justify-content-center align-items-center"
        show={open}
        onHide={() => setOpen(false)}>
        <Modal.Body closeButton>
            
        <h5 className="text-center">Share link to</h5>
        <div className="d-flex justify-content-center align-center align-items-center p-4">
                <a href={"http://m.me/"+encodeURIComponent(window.location)} target="_blank"><div className="iconStyleFooter me-3"><FontAwesomeIcon icon={faFacebookMessenger} style={{ fontSize: '16px', color:'#02AF95' }} /></div></a>
                <a href={"https://telegram.me/share/url?url="+encodeURIComponent(window.location)} target="_blank"><div className="iconStyleFooter me-3"><FontAwesomeIcon icon={faTelegram} style={{ fontSize: '16px', color:'#02AF95' }} /></div></a>
                <a href={"viber://forward?url="+encodeURIComponent(window.location)} target="_blank"><div className="iconStyleFooter me-3"><FontAwesomeIcon icon={faViber} style={{ fontSize: '16px', color:'#02AF95' }} /></div></a>
                <a href={"https://api.whatsapp.com/send?text="+encodeURIComponent(window.location)} target="_blank"><div className="iconStyleFooter"><FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: '16px', color:'#02AF95' }} /></div></a>
            
        </div>
        </Modal.Body>
      </Modal>
    }
    return (<>
        <Container>
            <Row className="mb-5">
                <Col>
                    <NavMenu />
                </Col>
            </Row>
            <Row className="mt-5 pt-5">
                <Col md={12} lg={12} xs={12} className="text-center">
                <h1 className="text-center pb-2">EV Charging Prices <hr className="header-border-secondary border-width-long "  style={{margin: "10px auto"}}/></h1>
                <p className="sub-title">Check out daily live rates here</p>
                </Col>
                <Col className="justify-content-start d-flex align-items-center mt-4" md={12} lg={12} xs={12}>
                    <div className="d-flex align-items-center">
                    <p className="filter-text mt-2 d-flex align-item-center"><span className="hidden my-2">Filter by:</span>
                    <button type="button" className={activeBotton == "1" ? "btn-active-kw my-2" : "btn tag-kw my-2"} name="battery_capacity"
                                value="1"
                                onClick={e=> {
                                    setActiveBottom("1")
                                    getEVchargingfilter(0, 10)
                                }}>≤ 10kW
                            </button>
                            <button type="button" className={activeBotton == "2" ? "btn-active-kw my-2" : "btn tag-kw my-2"} name="battery_capacity"
                                value="2"
                                onClick={e=> {
                                    setActiveBottom("2")
                                    getEVchargingfilter(10.001, 40)
                                }}> 10-40kW
                            </button>
                            <button type="button" className={activeBotton == "3" ? "btn-active-kw my-2" : "btn tag-kw my-2"} name="battery_capacity"
                                value="3"
                                onClick={e=> {
                                    setActiveBottom("3")
                                    getEVchargingfilter(40.001, 100000)
                                }}> &#62; 40kW
                            </button>
                            <div className="share-bg ms-4" onClick={()=> setOpen(true)}><FontAwesomeIcon icon={faShareAlt} style={{ fontSize: '22px', color: '#0FBEA3' }} /></div>
                    </p>
                    </div>
                    
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="row m-2">
                            <div className="border rounded">
                                <div className="scrollTab" id="style-4">
                                    <div className="w-100">
                                    <div className="d-flex">
                                        <div className="d-flex pt-2 pb-2">
                                            <div className="provider-wrapper right_1px_border white-background-color">
                                                <div className="provider-header mt-2 w-100 text-center">Provider</div>
                                            </div>
                                            {evPricesFilter.length > 0 ? evPricesFilter.map((price) => (
                                                <div className="provider-wrapper w-100 text-center">
                                                    <div className="provider-header align-self-center w-100 text-center">
                                                        <img src={price && price.logo ? `${process.env.REACT_APP_API}/${price.logo}` : TempImg}
                                                            alt={price.name} height="50px" />
                                                    </div>
                                                </div>
                                            )) : <div className="provider-wrapper-no p-2 my-2 w-100 text-center"> </div>}
                                        </div>
                                    </div>
                                    <div className="d-flex charging-table-ac-row margin-top-5">
                                        <div className="d-flex charging-table-ac-row ">
                                            <div className="provider-wrapper right_1px_border ac-style-left p-2 my-2 w-100 text-center">
                                                <div className="ac-title ">AC</div>
                                            </div>
                                            {evAcPrices.length > 0 ? evAcPrices.map((price, i) => (
                                                <div className={ evAcPrices.length === i + 1 ?"provider-wrapper ac-style-right my-2" : " provider-wrapper my-2"}>
                                                    <div className={ evAcPrices.length !== i + 1 ?"ac-title p-2 ac-bg-color w-100 text-center": "ac-title p-2 w-100 text-center"}>{price && price > 0 ? `$${parseFloat(price).toFixed(2)}` : '-'}</div>
                                                </div>
                                            )) : <div className="provider-wrapper-no p-2 my-2 w-100 text-center">No Provider for this capacity for today</div>}
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex">
                                            <div className="provider-wrapper right_1px_border white-background-color p-2 my-2 w-100 text-center">
                                                <div className="dc-title">DC</div>
                                            </div>
                                            {evDcPrices.length > 0 ? evDcPrices.map((price) => (
                                                <div className="provider-wrapper p-2 my-2 w-100 text-center">
                                                    <div className="dc-title w-100 text-center">{price && price > 0 ? `$${parseFloat(price).toFixed(2)}` : '-'}</div>
                                                </div>
                                            )) : <div className="provider-wrapper-no p-2 my-2 w-100 text-center"> </div>}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                                        {/* <div className="row">
                                        <div className="col-6 col-lg-3 col-md-3 right_1px_border ac-style-left">
                                            <div className="ac-title">AC</div>
                                        </div>
                                        <div className="col-6 col-lg-8 col-md-8 ac-style-right">
                                            <div className="container-provider">                                            
                                                {evPrices.length > 0 ? evPrices.map((price) => (
                                                    <div className="provider-wrapper">
                                                    <div className="ac-title ac-bg-color">{price.ev_ac_cost && price.ev_ac_cost > 0 ? `$${parseFloat(price.ev_ac_cost).toFixed(2)}` : '-'}</div>
                                                </div>
                                                )) : <div >No Provider for this capacity for today</div>}
                                                
                                                </div>
                                            
                                        </div>
                                        </div>
                                        <div className="row ">
                                        <div className="col-6 col-lg-3 col-md-3 right_1px_border white-background-color">
                                            <div className="dc-title">DC</div>
                                        </div>
                                        <div className="col-6 col-lg-8 col-md-8 ">
                                            <div className="container-provider">                                            
                                                {evPrices.length > 0 ? evPrices.map((price) => (
                                                    <div className="provider-wrapper">
                                                    <div className="dc-title">{price.ev_dc_cost && price.ev_dc_cost > 0 ? `$${parseFloat(price.ev_dc_cost).toFixed(2)}` : '-'}</div>
                                                </div>
                                                )) : ''}
                                                
                                                </div>
                                            
                                        </div>
                                        </div> */}
                                        {/* <div className="col-6 col-lg-3 col-md-3 border">
                                            <div className="provider-header">Provider</div>
                                            <div className="ac-title">AC</div>
                                            <div className="dc-title">DC</div>
                                        </div>
                                        <div className="col-6 col-lg-8 col-md-8 border">
                                            <div className="provider-container">                                            
                                                {evPrices.length > 0 ? evPrices.map((price) => (
                                                    <div className="provider-wrapper">
                                                    <div className="provider-header">
                                                        <img src={price.ev_provider && price.ev_provider.logo ? `${process.env.REACT_APP_API}/${price.ev_provider.logo}`: TempImg} 
                                                            alt={price.ev_provider.name} width="80px" />
                                                    </div>
                                                    <div className="ac-title">{price.ev_ac_cost && price.ev_ac_cost > 0 ? `$${parseFloat(price.ev_ac_cost).toFixed(2)}` : '-'}</div>
                                                    <div className="dc-title">{price.ev_dc_cost && price.ev_dc_cost > 0 ? `$${parseFloat(price.ev_dc_cost).toFixed(2)}` : '-'}</div>
                                                </div>
                                                )) : <div className="provider m-0 p-2">No Provider for this capacity for today</div>}
                                                
                                                </div>
                                            
                                        </div> */}
                                </div>
                                <div className="col-12 text-center">
                            <p className="charge-note mt-2"><strong>*</strong>&nbsp;Within each voltage band, respective
                                charging providers have different
                                voltage charging speeds. Some charging providers may have different rates at
                                certain specific locations </p>
                                </div>
                    </div>
                    <div className="row">
                        <div className="text-center">
                            <h3 className="mt-3">EV Charging Calculator</h3>
                        </div>
                        <div className="text-center charge-img">
                            <img src={EVChargingImg} alt="ACDC Charging" width="60%" />
                            <p className="text-center">To find out more about EV chargers, read our 
                            article <a href="https://evfy.sg/insight/20/Types-of-EV-Chargers-in-Singapore" target="_blank">here</a></p>
                        </div>
                        <div className="charge-paragrap">
                            <p>Every EV has an option to charge AC or DC. However, depending on the
                                vehicle specifications, some chargers may be more suited for your
                                vehicle than others. For instance, if your vehicle is equipped to
                                handle a maximum voltage of 6.6kW, it means that even if you connect
                                a 43kWh AC charger in, your EV battery will only be able to charge
                                6.6kW every hour. </p>
                            {/* <p>
                                To assist you along, we’ve created a EV charging calculator
                                for you. Simply input your charger specifications, and we will
                                recommend charging providers that help you maximise your charging
                                time. </p> */}
                                <p>
                                Evfy has created an EV Charging Calculator for you. Simply input your battery capacity, and we'll reflect your charging costs, according to prevailing rates in the market.
                                </p>
                        </div>
                        <div className="inp-charg">
                            <div>

                                <p className="">Battery Capacity:&nbsp;&nbsp;&nbsp;<input type="number" id="capacity_calc"
                                    name="capacity_calc" className="pl-1" 
                                    style={{width:"150px"}}
                                    value={batteryCapacity}
                                    onChange={e=>{
                                        setBatteryCapacity(e.target.value)
                                        chargingPricesSpeed(e.target.value, minValue, maxValue, false)

                                    }}/>&nbsp;&nbsp;&nbsp;kWh
                                    </p>
                            </div>      
                        </div>
                        <div className="w-100 d-flex justify-content-center p-4 mb-3">
                            <div>
                                <p className="text-center fw-bold">State of charge:</p>
                            <MultiRangeSlider
                                min={0}
                                max={100}
                                onChange={({ min, max }) =>{
                                    set_minValue(min);
                                    set_maxValue(max);
                                    chargingPricesSpeed(batteryCapacity, min, max, true)
                                }}
                                />
                            </div>
                        </div>    
                    </div>
                    
                </Col>
            </Row>
        </Container>
        <div className="arrow-up"></div>
        <div className="container-fluid" style={{ backgroundColor: '#E0F7F3' }}>
                        
                        <div className="container">
                            <div className="row">
                                <div className="text-center ">
                                    <div className="row scrollTab" id="style-4">
                                        <div id="capacity-calc">
                                            <div className="row p-4">
                                                <div className="row">
                                                <div className="col-6 col-lg-3 col-md-4">
                                                    
                                                    <div className="provider-header"></div>
                                                    </div>
                                                <div className="col-6 col-lg-3 col-md-4 justify-content-center align-items-center">
                                                <div className="provider-container mb-1">
                                                    {speedPrices.length > 0 ? speedPrices.map((price) => (
                                                        <div className="provider-wrapper provider-padding width-200">
                                                        <div className="provider-header align-middle">
                                                            <img src={price.ev_provider && price.ev_provider.logo ? `${process.env.REACT_APP_API}/${price.ev_provider.logo}`: TempImg} 
                                                                alt={price.ev_provider.name} height="50px" />
                                                        </div>
                                                        </div>
                                                    )) : ''}
                                                
                                                </div>
                                                <div className="provider-container">
                                                    {speedPrices.length > 0 ? speedPrices.map((price) => (
                                                        <div className="provider-wrapper provider-padding width-200 d-flex justify-content-center">
                                                       <div className="title-charging"><b>AC:</b><span className="span-style">{price.ev_ac && price.ev_ac != '' ?   `${'  '}` + price.ev_ac : '-'}</span> </div>
                                                       </div>
                                                    )) : ''}
                                                
                                                </div>
                                                <div className="provider-container">
                                                    {speedPrices.length > 0 ? speedPrices.map((price) => (
                                                        <div className="provider-wrapper provider-padding width-200  d-flex justify-content-center">
                                                       <div className="title-charging"><b>DC:</b><span className="span-style">{price.ev_dc  && price.ev_dc != '' ?   `${'  '}` + price.ev_dc : '-'}</span> </div>
                                                       </div>
                                                    )) : <div className="provider m-0 p-0">No Provider for this capacity</div>}
                                                
                                                </div>
                                                </div>
                                                </div>
                                                {/* <div className="row">
                                                <div className="col-6 col-lg-3 col-md-4">
                                                    
                                                    <div className="provider-header"></div>
                                                    </div>
                                                <div className="col-6 col-lg-3 col-md-4  d-flex justify-content-center">
                                                <div className="provider-container text-center">
                                                    {speedPrices.length > 0 ? speedPrices.map((price) => (
                                                        <div className="provider-wrapper provider-padding width-200">
                                                       <div className="title-charging"><b>AC:</b><span className="span-style">{price.ev_ac ?   `${' '}` + price.ev_ac : '-'}</span> </div>
                                                       </div>
                                                    )) : ''}
                                                
                                                </div>
                                                </div>
                                                </div> */}
                                                {/* <div className="row">
                                                <div className="col-6 col-lg-3 col-md-4">
                                                    
                                                    <div className="provider-header"></div>
                                                    </div>
                                                <div className="col-6 col-lg-3 col-md-4 d-flex justify-content-center">
                                                <div className="provider-container">
                                                    {speedPrices.length > 0 ? speedPrices.map((price) => (
                                                        <div className="provider-wrapper provider-padding width-200">
                                                       <div className="title-charging"><b>DC:</b><span className="span-style">{price.ev_dc ?   `${' '}` + price.ev_dc : '-'}</span> </div>
                                                       </div>
                                                    )) : <div className="provider m-0 p-0">No Provider for this capacity for today</div>}
                                                
                                                </div>
                                                </div>
                                                </div> */}
                                                <div className="row mt-2">
                                                    <div className="col-6 col-lg-3 col-md-4">
                                                    <div className="p-2">Estimated Costs(AC):</div>
                                                </div>
                                                <div className="col-6 col-lg-9 col-md-6">
                                                <div className="provider-container">
                                                    {speedPrices.length > 0 ? speedPrices.map((price) => (
                                                        <div className="provider-wrapper provider-padding width-200">
                                                        <div className="ac-title p-2"><b>{price.ac_estimated? '$' + parseFloat(price.ac_estimated).toFixed(2) : '-'}</b></div>
                                                    </div>
                                                    )) : ''}
                                                
                                                </div>
                                                </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 col-lg-3 col-md-4">
                                                    <div className="p-2">Estimated Costs(DC):</div>
                                                </div>
                                                <div className="col-6 col-lg-9 col-md-6">
                                                <div className="provider-container">
                                                    {speedPrices.length > 0 ? speedPrices.map((price) => (
                                                        <div className="provider-wrapper provider-padding width-200">
                                                        <div className="ac-title p-2"><b>{price.dc_estimated? '$' + parseFloat(price.dc_estimated).toFixed(2) : '-'}</b></div>
                                                    </div>
                                                    )) : ''}
                                                
                                                </div>
                                                </div>
                                                </div>
                                                {/* <div className="col-6 col-lg-3 col-md-4">
                                                    
                                                    <div className="provider-header"></div>
                                                    <div className="ac-title title-charging"></div>
                                                    <div className="dc-title title-charging"></div>
                                                    <div className="ac-title title-charging">Estimated Costs(AC):</div>
                                                    <div className="dc-title title-charging">Estimated Costs(DC):</div>
                                                </div>
                                                <div className="col-6 col-lg-9 col-md-6">
                                                <div className="provider-container">
                                                    {speedPrices.length > 0 ? speedPrices.map((price) => (
                                                        <div className="provider-wrapper provider-padding">
                                                        <div className="provider-header">
                                                            <img src={price.ev_provider && price.ev_provider.logo ? `${process.env.REACT_APP_API}/${price.ev_provider.logo}`: TempImg} 
                                                                alt={price.ev_provider.name} width="80px" />
                                                        </div>
                                                        <div className="title-charging"><b>AC:</b><span className="span-style">{price.ev_ac ?   `${' '}` + price.ev_ac : '-'}</span> </div>
                                                        <div className="title-charging"><b>DC:</b><span className="span-style">{price.ev_dc ?  `${' '}` + price.ev_dc : '-'}</span> </div>
                                                        <div className="ac-title"><b>{price.ac_estimated? '$' + parseFloat(price.ac_estimated).toFixed(2) : '-'}</b></div>
                                                        <div className="dc-title"><b>{price.dc_estimated? '$' + parseFloat(price.dc_estimated).toFixed(2) : '-'}</b></div>
                                                    </div>
                                                    )) : <div className="provider m-0 p-0">No Provider for this capacity for today</div>}
                                                
                                                </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                        
                    </div><br /><br /> <br />
                    <div className="container text-center mt-2 mb-4 pb-4">
                        <div className="col-12">
                                        {/* <p className="charge-note mb-1"><strong>*</strong>&nbsp; Estimated
                                            costs are derived from prevailing charging prices, with the
                                            assumption that charging is from 0% - 100%</p>
                                        <p className="charge-note ">
                                            <strong>*&nbsp;*</strong> &nbsp;Reflected providers are based on which of them has
                                            chargers with minimally
                                            equal voltage to vehicle charging type specifications.
                                            If a service provider is not displayed above, it does not mean that they are not
                                            compatible.
                                        </p> */}
                                    </div>
                    </div>
        <Footer/>
        {Dialog()}
        </>
    );
};
export default EVChargingRate;
