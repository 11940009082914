import { Container, Row, Col } from "react-bootstrap";
import NavMenu from "../../Layout/navbar";
import EVListingNavMenu from '../partial/EVListingNav'
import "../../../assets/css/style.css"
import Footer from "../../Layout/Footer";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faSearch, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreatingFuture from '../../../assets/img/BenefitsOfEVs/CheaperRefuel.png'
import SGGreenPaln from '../../../assets/img/BenefitsOfEVs/SGGreenPlan2030.png'
import { useHistory } from "react-router-dom";


const BenifitEV = () => {
   let history = useHistory();

    return (<>
        <Container fluid className="p-0 m-0">
            <Row>
                <Col>
                    <NavMenu />

                </Col>
            </Row>
        </Container>
        <EVListingNavMenu />
        <Container>
            <Row>
            <Col md={1} lg={1}></Col>
                <Col>
                    <h3 className="fs-3 pt-5">Why switch to an EV? <hr className="header-border-secondary border-width-long" />
                    </h3>
                </Col>
                <Col md={1} lg={1}></Col>
            </Row>
            <Row>
            <Col md={1} lg={1}></Col>
                <Col>
                    <h4 className="text-center pt-5 pb-2 mb-4">Cleaner Future for All <hr className="header-border-secondary border-width-long mx-auto mt-2" /></h4>
                    <p>The future shines brightly for all Electric Vehicles (EVs). According to Bloomberg, global EV sales made up for 10.8% of total vehicle sales in 2021 Q4, which is nothing short of exponential over the past year, let alone the decade. Within Singapore, the EV population more than doubled in 2021. This trend certainly does not seem like it will slow down anytime soon.
                    </p>
                    <p>It certainly helps that under the Singapore Green Plan, all new COE registrations for diesel vehicles will cease from 2025 onwards. From 2030 onwards, all newly registered vehicles will have to be of cleaner energy. In other words, by 2040, all locally registered vehicles would essentially be either Hybrid or Battery Electric Vehicles.
                    </p>
                    <p>It’s pretty established that EVs have a lot of environmental benefits, such as having no exhaust pollution. Polluting gasses like CO, HC and NOX are common byproducts of Internal Combustion Engine (ICE) vehicles, and they contribute to global warming and acid rain formation.
                    </p>
                    <p>But how does adopting EVs, whether vans or cars, benefit the driver?
                    </p>
                </Col>
                <Col md={1} lg={1}></Col>
            </Row>
            <Row>
            <Col md={1} lg={1}></Col>
                <Col>
                    <h4 className="text-center pt-5 pb-2 mb-4">Cheaper to Refuel <hr className="header-border-secondary border-width-long mx-auto mt-2" /> </h4>
                    <p>Let’s face the facts: Petrol prices have always been expensive and volatile. While it was possible to find 92-Octane and 95-Octane petrol for below $2 back in 2020, it’s now impossible to even get diesel for $2 now. Many vehicles, particularly vans, are gas guzzlers, which makes it extremely expensive to constantly refuel when doing last-mile logistics.
                    </p>
                    <p>Below is a price comparison between EV charging and using petrol/ diesel. While exact figures do fluctuate, here are some snippets on the kind of savings you can expect:
                    </p>
                    <div className="text-center py-5">
                        <img src={CreatingFuture} alt="Creating Feature" width="80%"/>
                    </div>
                    <p>On average, it’s possible to save close to $600 per month if you switch to Electric Logistics Vehicles (ELVs). Can any petrol savings card ever come that close?
                    </p>
                </Col>
                <Col md={1} lg={1}></Col>
            </Row>
            <Row>
            <Col md={1} lg={1}></Col>
                <Col>
                    <h4 className="text-center pt-5 pb-2 mb-4">Lower Maintenance Costs <hr className="header-border-secondary border-width-long mx-auto mt-2" /> </h4>
                    <p>It is estimated that a standard diesel van can easily have 20,000 moving parts, while an ELV only has 2,000. Say goodbye to all the dirty parts you once thought were so essential: spark plugs, transmission fluid, exhaust pipe and alternators. All of that has been replaced with just 3 EV components: on-board charger, inverter and motor. </p>
                    <p>Sure, you may say that maintenance costs are relatively cheap in Singapore, but you would also have to eventually replace those many parts, which would undoubtedly cost hundreds.
                    </p>

                    <h5 className="text-center pt-5">There really is no alternative...<hr className="header-border-secondary border-width-long mx-auto mt-2" /></h5>

                    <div className="text-center py-5">
                        <img src={SGGreenPaln} alt="SG GREEN PLAN" width="80%"/>
                    </div>
                    <p>Again, all ICE vehicles (both diesel and petrol) now have a limited shelf life. If you don’t make the switch now, there’ll only be a mad scramble soon.
                    </p>
                    <p>When you lease EVs from Evfy, you are guaranteed prioritised job allocations too. Let’s have a chat on how we can help you build a sustainable career in the last-mile logistics sector!
                    </p>
                </Col>
                <Col md={1} lg={1}></Col>
            </Row>
            <Row className="d-flex justify-content-evenly">
            <Col md={1} lg={1}></Col>
                <Col className="py-5 text-center" xs={12} md={3} lg={3}>
                    <button className="btn" style={{ fontSize: '14px', backgroundColor: '#0FBEA3', borderRadius: '15px', color: '#fff', width: '180px' }}
                    onClick={e=>history.push("/benefit-of-ev")}
                    >
                        <FontAwesomeIcon icon={faSearch} style={{ fontSize: '14px', color: '#fff', }} />&nbsp;&nbsp;&nbsp; List of Benefits </button>
                </Col>
                <Col className="py-5 text-center" xs={12} md={3} lg={3}>

                    <button className="btn" style={{ fontSize: '14px', backgroundColor: '#0FBEA3', borderRadius: '15px', color: '#fff', width: '180px' }}
                    onClick={e=>{
                        history.push("/ev-listing");
                    }}
                    >
                        <FontAwesomeIcon icon={faTruck} style={{ fontSize: '14px', color: '#fff', }} />&nbsp;&nbsp;&nbsp; Pick your EV </button>
                </Col>
                <Col className="py-5 text-center" xs={12} md={3} lg={3}>
                    <button className="btn" style={{ fontSize: '14px', backgroundColor: '#0FBEA3', borderRadius: '15px', color: '#fff', width: '180px' }}
                    onClick={e=>history.push("/drive-with-us")}
                    >
                        <FontAwesomeIcon icon={faLocationArrow} style={{ fontSize: '14px', color: '#fff', }} /> &nbsp;&nbsp;&nbsp; Drive With Us </button>
                </Col>
                <Col md={1} lg={1}></Col>
            </Row>
        </Container>
        <Footer />

    </>
    );
};
export default BenifitEV;
