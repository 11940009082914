import NavMenu from "../../Layout/navbar";
import { Container, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import "../../../assets/css/style.css"
import TempImg from '../../../assets/img/background/business.jpg'
import EvEverythingNav from "../partial/EvEvarythingNav";
import EQSec1 from "./partial/EQSec1";
import Footer from "../../Layout/Footer";

const ExploreQuestion = () => {

    return (<>
        <Container fluid>
            <Row>
                <Col>
                    <NavMenu />
                </Col>
            </Row>
            </Container>
            <EvEverythingNav />
            <Container fluid className="mb-5">
            <Row>
                <Col>
                    <EQSec1/>
                </Col>
            </Row>
        </Container>
        <Footer/>
        </>
    );
};
export default ExploreQuestion;
