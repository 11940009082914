import react, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

import "../../../assets/css/style.css"
import React, { useRef } from 'react';
import client_blue from '../../../assets/img/onboarding/client_blue.png'
import vendor_blue from '../../../assets/img/onboarding/vendor_blue.png'
import fleet_white from '../../../assets/img/onboarding/fleet_white.png'
import apiUtil from "../../../api/apiUtil";
import authApi from "../../../api/Authentication"
import PhoneInput from "react-phone-input-2";
import Swal from 'sweetalert2'
import "react-phone-input-2/lib/bootstrap.css";
const OnboardingFleet = (props) => {


  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("")
  const [emailValidated, setEmailValidated] = useState(false)
  const [fullNameValidated, setFullNameValidated] = useState(false)
  const [companyNameValidated, setCompanyNameValidated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [phoneValidated, setPhoneValidated] = useState(false)


  const handleSubmit = e => {

    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    setEmailValidated(false)
    setFullNameValidated(false)
    setCompanyNameValidated(false)
    setPhoneValidated(false)

    let validatedEmail = true
    if(!email || email == '' || !apiUtil.emailValidation(email)) {
      setEmailValidated(true)
      validatedEmail = false
    }

    if(!companyName || companyName == '') {
      setCompanyNameValidated(true)
    }
    if(!fullName) {
      setFullNameValidated(true)
    }

    let validPhone = true
    
    if(phone != '') {
      if(phone!=65 && !apiUtil.phoneValidationWithPlus(phone)) {
        setPhoneValidated(true)
        validPhone = false
      }
    }

    if (form.checkValidity() === true && validatedEmail && validPhone) {

      setIsLoading(true);

      let data = {
        "admins": [
          {
            "email": email,
            "first_name": fullName,
            "password": ""
          }
        ],
        "is_evfy_onboarding": true,
        "company_name": companyName,
        "contact_no": phone,
        "email": email,
        "is_default_password": true,
        "ref_admin_id": null,
        "ref_company_id": null
      };
      authApi.signup(data)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {

            props.onSave(email, true)
            // history.push('/sign-up-success');
          }, (error, type) => {
            let errmessage = ''
            if (error.email) {
              errmessage = `Email ${error.email[0]}` 
            }
            if(error.company_name) {
              errmessage = `Comany Name ${error.company_name[0]}` 
            }
            apiUtil.toast(errmessage, '', 'error')
            setIsLoading(false);
          });
        });
    }
      
  }

  return (
    <Row className="p-0 m-0">
    <Col xs={12} md={6} lg={6} className="left-menu-container p-3 m-0 d-flex flex-column align-items-center">
      <h4 className="text-white text-center">How can we work together?</h4>
      <img src={client_blue} alt="client" width="300px" className="my-3 img-btn"
      onClick={()=> {
        props.onSave(1)
      }}/>
      <img src={vendor_blue} alt="vendor" width="300px" className="my-3 img-btn"
      onClick={()=> {
        props.onSave(2)
      }}/>
      <img src={fleet_white} alt="fleet" width="300px" className="my-3 img-btn"
      onClick={()=> {
        props.onSave(3)
      }}/>
      <div className="text-white mx-4 text-justify my-3 ">Choose the option that best suits you. Pay for only what you need, and our Fleet Management System will be fully integrated with your business!</div>
      <div className="text-white mx-4 text-left my-3 cursor_underline" 
      onClick={()=>{
        props.onShow(true)
      }}>See which one suits you</div>
    </Col>
    <Col xs={12} md={6} lg={6} className="right-menu-container p-0 m-0 d-flex flex-column align-items-center py-3 px-3">
    <form onSubmit={handleSubmit} class="row g-3 needs-validation" novalidate>
        <h3 className="text-left w-100 px-3">Create your account now!</h3>
        <div class="mb-3 w-100 px-3">
          <label class="form-label fw-bold">Country*</label>
            <select class="form-select w-100" aria-label="Default select example" required>
              <option value='' selected>--- Select ---</option>
              <option value="1">Singapore</option>
            </select>
        </div>
        <div class="mb-3 w-100 px-3">
          <label class="form-label fw-bold">POC Name*</label>
          <input type="text" class="form-control w-100 "   placeholder="Jhon Smith" required
          value={fullName}
          onChange={e=>setFullName(e.target.value.trim().length === 0 ? '' : e.target.value)}
          />
        </div>
        <div class="mb-3 w-100 px-3">
          <label class="form-label fw-bold">Company Name*</label>
          <input type="text" class="form-control w-100 " placeholder="Company Name" required
          value={companyName}
          onChange={e=> setCompanyName(e.target.value.trim().length === 0 ? '' : e.target.value)}
          />
          
        </div>
        <div class="mb-3 w-100 px-3">
          <label class="form-label fw-bold">Phone Number</label>
          <PhoneInput
            className={`custom-phone-input ${phoneValidated ? "input-error" : ''}`}
            country={"sg"}
            enableSearch={false}
            disableDropdown={true}
            value={phone}
            countryCodeEditable={false}
            onChange={(phone) => setPhone(phone)}
          />
        </div>
        <div class={`mb-3 w-100 px-3 ${emailValidated ? "input-error" : ''}`}>
          <label class="form-label fw-bold">Email Address*</label>
          <input type="email" class="form-control w-100"  placeholder="jhon@gmail.com" required
          value={email}
          onChange={e=> {
            setEmail(e.target.value)
            setEmailValidated(false)
          }} 
          />
        </div>
        
        <div class="mb-3 w-100 px-3 d-flex flex-column align-items-center">
        <button className="btn contact_button" type="submit"
        disabled={isLoading}
                //  onClick={()=> {
                //   props.onSave(null, true)
                // }}
                > {isLoading? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :"REGISTER"}</button>
          <span className="text-center px-3 my-2">Already have an account? Log in <a href="https://fms.evfy.sg/">here</a></span>
          <span className="text-center px-3">By signing up, you agree to our <a href="https://fms.evfy.sg/terms_and_conditions">Terms of Service</a> and our <a href="https://fms.evfy.sg/privacy_policy">Privacy Policy</a></span>
        </div>
        </form>
        </Col>
</Row>
  );
};
export default OnboardingFleet;


