import { Container, Row, Col, } from "react-bootstrap";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../assets/css/style.css"
import { useState } from "react";
import NavMenu from "../../Layout/navbar";
import AboutNav from "../partial/AboutNav";
import Footer from "../../Layout/Footer";
import MVSec1 from "./partial/MVSec1";
import MVSec2 from "./partial/MVSec2";
const MissionVision = (props) => {

  return (<>
    <Container fluid className="p-0 m-0">
         <Row>
          <Col>
            <NavMenu /> 
          </Col>
        </Row>
    </Container>
      <AboutNav />
      <MVSec1 />
      <MVSec2 />
      <Footer />
    </>
  );
};
export default MissionVision;
