import react, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, Card, Form, Modal, Table } from "react-bootstrap";
import TempImg from '../../../assets/img/tempImg/temp.jpg'
import EVCharter from '../../../assets/img/Eco-Services/ev-chartering.png'
import LastMile from '../../../assets/img/Eco-Services/Last Mile Deliveries.jpg'
import GreenWareHouse from '../../../assets/img/About/GreenWarehousing.jpg'
import EnquireNow from "../../Resources/EnquireNow";
import API from "../../../../src/api/api"
import apiUtil from "../../../../src/api/apiUtil";

const ECOService = () => {
    const [show, setShow] = useState(false);
    const [evVehicles, setEvVehicles] = useState([])
    const [selectedVehicle , setSelectedVehicle] = useState('')
    const [noEvUsed, setNoEvUsed] = useState(1)
    const [travelDistance, setTravelDistance] = useState(155)
    const [co2ReduceDaily, setCo2ReduceDaily] = useState('');
    const [co2ReduceYearly, setCo2ReduceYearly] = useState('');
    const [treePlantYearly, setTreePlantYearly] = useState('');

    useEffect(()=>{

        callVehicleList()
      }, [])
    
      const callVehicleList = () => {
         let data = {
           latest: true,
         }
        API.get('/api/front_ev_vehicles', data, '')
        .then((result) => {
            apiUtil.parseResult(result, (data) => { 
              let result = data.data && data.data.length > 0 ? data.data : [];
              setEvVehicles(result)
              if (result.length > 0) {
                let selected_vehicle = result[0]
                setSelectedVehicle(selected_vehicle)
                callRender(selected_vehicle, noEvUsed, travelDistance)
              } else {
                callRender();
            }
    
            }, (error, type) => {
              
             });
        });
    }

    const callRender = (vehicle = '', no_used = 1, travel_distance = 1) => {    
        let ice_co2_status = vehicle.ice_vehicle && vehicle.ice_vehicle.co2_status && !isNaN(vehicle.ice_vehicle.co2_status) ? parseFloat(vehicle.ice_vehicle.co2_status): 1;
        let reduce_daily = (ice_co2_status * travel_distance ) / 1000 * no_used
        let reduce_yearly = (reduce_daily * 365)  / 1000 * no_used 
        let tree_yearly = (reduce_daily * 365)  / 21.77 * no_used 

        setCo2ReduceDaily(reduce_daily)
        setCo2ReduceYearly(reduce_yearly)
        setTreePlantYearly(tree_yearly)

    }
    const handleChangeVehicle = (id) => {
        let vehicle = evVehicles.filter(ev => ev.id ==  id)
        setSelectedVehicle(vehicle[0])
        callRender(vehicle[0], noEvUsed, travelDistance)
    }
    const Dialog = () => {
        return <Modal
        show={show}
        onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enquire Now!!</Modal.Title>
        </Modal.Header>
      
        <Modal.Body>
          <EnquireNow
          onClose={() => setShow(false)}
          />
        </Modal.Body>
      </Modal>
    }

    return (
        <Container fluid className="mt-5 mb-5">
         <Row>
             <Col xs={12} md={8} lg={8}>
                    <h2 className="fs-3">Eco-Services  <hr className="header-border-primary border-width-long"/></h2>
                    <p class="sub-title">Sustainable · Innovative · Efficient</p>

            <Row>
                <Col xs={12} md={4} lg={4} className="p-2">
                <a href="/last-mile-delivery" className="text-decoration-none">
                <div style={{position: "relative", height:'320px', display:'flex', flexDirection: 'column', justifyContent:'flex-end'}}  className="cardEcoHover"> 
                <img style={{position: "absolute", zIndex: '-2', objectFit:'cover', objectPosition:'top', objectPosition: '0px 0px'}} src={LastMile} alt="Last-Mile Deliveries" width="100%" height="320px" />
                <p  className="text-end text-white fs-4 p-2 notHoverText" style={{position: "absolute", bottom:'-10px', right:'0'}}>Last-Mile Deliveries</p>
                <div className="text-center">
                    <span className="text-center text-white fs-4 p-2 hoverText ">Last-Mile Deliveries</span>
                    <p className="text-center text-white fs-6 p-2 hoverText" >Safe, transparent, timely</p>
                </div>
                </div></a>
                </Col>
                <Col xs={12} md={8} lg={8}>
                <Row>
                <Col xs={12} md={12} lg={12} className="m-2">
                    <a href="/ev-chartering" className="text-decoration-none">
                    <div style={{position: "relative", height: "150px", display:'flex', flexDirection: 'column', justifyContent:'flex-end'}} className="cardEcoHover">
                <img style={{position: "absolute", zIndex: '-2',  objectFit:'cover'}} src={EVCharter} alt="EV Chartering" width="100%" height="150px" />
                <p  className="text-end text-white fs-4 p-2 notHoverText" style={{position: "absolute", bottom:'-10px', right:'0'}}>EV Chartering</p>
                <div className="text-center">
                    <span  className="text-center text-white fs-4 p-2 hoverText" >EV Chartering</span>
                    <p  className="text-center text-white fs-6 p-2 hoverText" >Our exclusive Driver-Partners are here to help you achieve your ESG needs!</p>
                </div>
                    </div>
                    </a>
                </Col>
                <Col xs={12} md={12} lg={12} className="m-2">
                <a href="/green-warehousing"  className="text-decoration-none">
                    <div style={{position: "relative", height: "150px", display:'flex', flexDirection: 'column', justifyContent:'flex-end'}} className="cardEcoHover">
                <img style={{position: "absolute", zIndex: '-2'}} src={GreenWareHouse} alt="Green Warehousing" width="100%" height="150px" />
                <p  className="text-end text-white fs-4 p-2 notHoverText" style={{position: "absolute", bottom:'-10px', right:'0'}}>Green Warehousing</p>
                <div className="text-center">
                    <span  className="text-center text-white fs-4 p-2 hoverText" >Green Warehousing</span>
                    <p  className="text-center text-white fs-6 p-2 hoverText" >Why stop at sustainable mobility? Let us greenify all your logistics needs!</p>
                </div>
                    </div>
                </a>
                    </Col>
                </Row>
                </Col>
            </Row>
             </Col>
             <Col xs={12} md={4} lg={4}>
             <h2 className="fs-3">Carbon Calculator  <hr className="header-border-primary border-width-long"/> </h2>
                      <p className="sub-title">How much CO2 is saved?</p>
                      <div className="border rounded-3 p-1">
                    <Row>
                        <Col xs={12} md={12} lg={12} className="pt-3">
                            <label className="form-label">Vehicle type</label><br/>
                            <select value={selectedVehicle && selectedVehicle.id} onChange={(e) => handleChangeVehicle(e.target.value)} style={{ width: '100%', height:"40px", borderRadius: '8px' }} >
                               {evVehicles.length > 0 && evVehicles.map(evVehicle =>(
                                    <option key={evVehicle.id} value={evVehicle.id}>{evVehicle.name}</option>
                               ))}
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12} className="pt-3">
                            <label className="form-label">No. of EVs used</label>
                            <input type="number" className="form-control" style={{borderRadius: '8px' }} 
                            value={noEvUsed}
                            onChange={e=>{
                                let value = !isNaN(e.target.value) ? e.target.value : 0
                                setNoEvUsed(value)
                                callRender(selectedVehicle,value, travelDistance )
                            }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12} className="pt-3">
                            <label className="form-label">Distance Travelled <span className="text-muted">km/day</span></label>
                            <input type="number" Placeholder="km/day" className="form-control" style={{borderRadius: '8px' }} 
                            value={travelDistance}
                            placeholder='km/day'
                            onChange={e=>{
                                let value = !isNaN(e.target.value) ? e.target.value : 0
                                setTravelDistance(value)
                                callRender(selectedVehicle, noEvUsed, value)
                            }}
                             />
                        </Col>
                    </Row>
                   
</div>
{/* <Col className="pt-4 text-center" xs={12} md={12} lg={12}>
              <button 
               onClick={() => setShow(true)}
              className="btn" style={{ fontSize: '14px', color: '#007CDE', borderColor: '#007CDE', borderRadius: '15px' }}>
              Enquire Now </button>
            </Col> */}
             </Col>
         </Row>
         <Row>
             <p className="mt-5 sub-title">You would save the Earth with:</p>
             <div className="border rounded-3">
                 <Row>
             <Col xs={12} md={4} lg={4} className="text-center">
                 <div className="m-2 border-end-style">
             <h3 className="fs-3">
                      <span style={{ color: '#007CDE' }}>{parseFloat(co2ReduceDaily).toFixed(2)} kg</span></h3>
                      <p>of CO2 emissions reduced (daily)</p>
                      </div>
             </Col>
             <Col xs={12} md={4} lg={4} className="text-center">
             <div className="m-2 border-end-style">
             <h3 className="fs-3">
                      <span style={{ color: '#007CDE' }}>{parseFloat(co2ReduceYearly).toFixed(2)} tons</span></h3>
                      <p>of CO2 emissions reduced (yearly)</p>
                      </div>
             </Col>
             <Col xs={12} md={4} lg={4} className="text-center">
             <div className="m-2">
             <h3 className="fs-3">
                      <span style={{ color: '#007CDE' }}>{parseFloat(treePlantYearly).toFixed(2)}</span></h3>
                      <p>trees being planted (yearly)</p>
                      </div>
             </Col>
             </Row>
             </div>
         </Row>
         {/* {Dialog()} */}
        </Container>
    );
};
export default ECOService;
