import { Container, Row, Col, Form } from "react-bootstrap";
import NavMenu from "../Layout/navbar";
import Footer from "../Layout/Footer";
import logoImg from "../../assets/img/logo/evfy-logo-square.png"
const TermsOfUse = () => {

    return (<>
            <Container>
            <Row className="mb-5">
                <Col>
                    <NavMenu />
                </Col>
            </Row>
            <Row className="mt-5 pt-5">
            <div class="container blog-detail">
            <div class="heading text-center">
                    <img alt="Evfy Logo" src={logoImg} className="rounded mx-auto d-block" style={{width:"200px"}} />
                    <h2 class="heading-title mb-4 mt-3">Terms of Use <hr className="header-border-secondary border-width-long mx-auto mt-2" /></h2>
                </div>
                <div class="term-p1">
                    <h5>1. Agreements</h5>
                    <p><em>1.1</em> EVFY accepts Shipments (parcels, cartons, and/or pallets) for transport and delivery
                        from the Customer at designated EVFY drop-off points or collects such Shipments at agreed sites
                        of the Customer.</p>
                    <p><em>1.2</em> The Customer shall transmit electronically accurate shipping information via EVFY’s
                        order management platform, label the Shipment correctly, and provide all necessary details to
                        enable EVFY to perform the agreed services. Any inaccurate or missing Shipment label or order
                        submission may delay the delivery process.</p>
                    <p><em>1.3</em> EVFY will accept special instructions from the Customer for Shipments only if these
                        instructions are notified in the agreed form between the Parties (refers to EVFY and the
                        Customer). EVFY is not obliged to comply with any special instructions if these are issued only
                        after the Shipment has been handed over for transport and delivery.</p>
                    <p><em>1.4</em>The Customer agrees to all routing and diversion, including the possibility that the
                        Shipment will be transported via intermediate stops, at the sole and absolute discretion of
                        EVFY.</p>

                </div>
                <div class="term-p1">
                    <h5>2. Prohibited Goods</h5>
                    <p><em>2.1</em> EVFY will not undertake the transport and delivery of any Shipment which contains
                        Prohibited Goods. Dangerous and Prohibited items include, but are not limited to – explosives,
                        poison, flammable items, radioactive material, compressed gas, corrosive items, firearms, live
                        animals or human remains and any item which by its nature or packaging may expose employees of
                        EVFY and the general public to injury or cause damage to other items, and items which are
                        prohibited by law.</p>
                </div>
                <div class="term-p1">
                    <h5>3. Service Level</h5>
                    <div>
                        <h6>3.1 Types of Services</h6>
                        <h6>3.1.2. Standard Delivery (1-3 days)</h6>
                        <p>Upon receipt of order submission in the EVFY order management platform and Shipment (only
                            applicable for drop-off scheme) before 18:30, EVFY will proceed to deliver the Shipment in
                            the next 1-3 days. </p>
                        <h6>For illustration</h6>
                        <p>Under drop-off scheme – if order is placed on Monday and parcel is dropped off at designated
                            EVFY drop-off points on Monday before 18:30, the parcel will be delivered to the recipient
                            between Tuesday and Thursday.

                            <br/>Under collection scheme – if order is placed on Monday before 18:30, collection will
                            be done between 09:00 and 18:00 on Tuesday, and the parcel will be delivered to the
                            recipient between Wednesday and Friday.
                        </p>

                        <div>
                            <h6>3.1.2 Same Day Delivery</h6>
                            <p>Upon receipt of order submission in the EVFY order management platform and Shipment (only
                                applicable for drop-off scheme) before 09:00, EVFY will proceed to deliver the Shipment
                                on the same day.</p>
                        </div>

                        <div>
                            <h6>3.1.2 Next Day Delivery</h6>
                            <p>Upon order submission in EVFY’s order management platform and parcel collection one day
                                before at 18:30, EVFY will proceed with the deliveries for the next day</p>
                        </div>

                        <div>
                            <h6>3.1.2 Special Turnaround time Deliveries</h6>
                            <p>Special requests can be made with regards to turnaround time for deliveries if Customer
                                is requesting for a shorter turnaround time. Prices will be adjusted accordingly.</p>
                        </div>

                        <div>
                            <h6>3.1.2 Time Sensitive Delivery:</h6>
                            <p>Bookings for time sensitive deliveries must be made ONE day in advance. Upon receipt of
                                order submission in the EVFY’s order management platform and Shipment (only applicable
                                for drop-off scheme) before 18:30, EVFY will proceed to deliver the Shipment the next
                                day within a specific THREE-hour time slot specified by the Customer.</p>
                        </div>
                    </div>


                    <div>
                        <h6>3.2 Packaging and Labelling</h6>
                        <p>All Shipments are to be adequately and appropriately packaged by the Customer. EVFY reserves
                            the right to reject the delivery of any Shipment deemed to be inadequately packaged and will
                            not assume any liability for damaged goods due to inadequate packaging </p>
                        <div>
                            <h6>3.2.2 Fragile Items</h6>
                            <p>All Fragile items are to be labelled clearly and must be packed with sufficient impact
                                resistant material.</p>
                        </div>

                        <div>
                            <h6>3.2.2 Labelling</h6>
                            <p>All items must be labelled accurately and clearly. Text size on the labels must be
                                legible and must not be smaller than 4mm per character.</p>
                        </div>
                    </div>
                    <div>
                        <h6>3.3 Delivery Orders</h6>
                        <p>Deliveries with Delivery Orders will be signed and stamped if any. If the Customer has no
                            stamp to approve delivery, the Customer is to provide his/her name and phone number for
                            delivery approvals.</p>
                    </div>
                    <div><em>3.4 Order Submission</em>
                        <p>The order submission cut-off time in the EVFY order management platform is 18:30 (Monday to
                            Friday). Any orders submitted after 18:30 (Monday to Friday) will only be processed on the
                            next working day.</p>
                    </div>
                    <div><em>3.5 Collection of Shipments</em>
                        <p>The cut-off time for the deposit of Shipments at designated EVFY drop-off points is 18:30
                            (Monday to Friday). Any Shipments deposited after 18:30 (Monday to Friday) will only be
                            processed on the next working day. <br/> In the case of collection of Shipments at agreed
                            sites of the Customer, collection will be done between 09:00 and 18:00 (Monday to Friday).
                        </p>
                    </div>
                    <div><em>3.6 Delivery and Un-deliverables </em>
                        <p>Once Shipments are received at the EVFY warehouses, the Shipments will be sorted and
                            dispatched to drivers, with up to 2 delivery attempts to the same address provided. Should
                            the recipient be unreachable by phone and/or email after 2nd failed attempts, EVFY will
                            return the Shipment back to the Customer at the same price model of delivery charges. Change
                            of new addresses will be considered as a new order and will be charged accordingly.
                            <br/>Shipments shall be delivered to the Recipient's address specified by the Customer, but
                            not necessarily in person to the Recipient named. Shipments to addresses with central mail
                            departments shall be delivered to these departments.</p>
                    </div>
                    <div><em>3.7 Delivery Hours</em>
                        <p>EVFY delivery hours are from 09:00 to 18:00 (Monday to Friday) and 09:00 to 13:00 on
                            Saturdays, excluding Public Holidays. For delivery to business addresses, delivery hours
                            will be limited to 09:00 to 18:00 (Monday to Friday), excluding Public Holidays.</p>
                    </div>
                    <div><em>3.8 Restricted Areas</em>
                        <p>EVFY’s delivery service is provided only to locations within the main island of the Republic
                            of Singapore. Please refer to Annex A for the full list of Restricted Areas which EVFY does
                            not deliver to. <br/>EVFY delivers to the following Special Clearance Areas, but at an
                            additional surcharge. Please refer to the Schedule of Rates in Annex B for the applicable
                            charges.</p>
                        <h6>Special Clearance Areas:</h6>
                        <ul>
                            <li>Sentosa </li>
                            <li>Tuas </li>
                            <li>Jurong Island </li>
                            <li>Changi Airport </li>
                            <li>Any annexed buildings </li>

                        </ul>
                    </div>
                </div>
                <div class="term-p1">
                    <h5>4 Liability and Claims</h5>
                    <div>
                        <p><strong>4.1</strong> EVFY will make every reasonable effort to deliver the Shipment according
                            to EVFY’s regular delivery schedules, but these schedules are not binding and do not form
                            part of the contract. EVFY is not liable for any damages or loss caused by delay.</p>
                    </div>
                    <div>
                        <p><strong>4.2</strong> EVFY’s liability, whether in contract or in tort, for any loss of
                            delivery items shall be limited to the value of the delivery item or S$100 per consignment,
                            whichever is lower. All other types of loss or damage are excluded (including but not
                            limited to lost profits, income, interest, future business).</p>
                    </div>
                    <div>
                        <p><strong>4.3 </strong>Any claim request must be submitted to EVFY within 7 days from Proof of
                            Delivery. Any claim submitted after this period shall not be recognized. No claim shall be
                            entertained until all outstanding amount due to EVFY has been fully settled. </p>
                    </div>
                    <div>
                        <p><strong>4.4</strong> All claims must be made in writing, accompanied by supporting documents
                            and relevant receipts. All claims are to be submitted to: hello@evfy.sg</p>
                    </div>
                </div>

                <div class="term-p1">
                    <h5>5 Force Majeure</h5>
                    <div>
                        <p><strong>5.1.</strong> EVFY is not liable for any loss or damage arising out of circumstances
                            beyond EVFY’s control. These include but are not limited to – electrical or magnetic damage
                            to or erasure of electronic or photographic images, data or recordings; any defect or
                            characteristic related to the nature of the Shipment, even if known to EVFY; any act or
                            omission by a person not employed or contracted by EVFY.“Force Majeure” events include but
                            are not limited to – fire, earthquake, cyclone, hurricane, storm, flood, fog, radiation
                            contamination, pandemic, war, plane crash, shipwreck, or embargo, riot or civil commotion,
                            industrial action, or disputes.</p>
                    </div>
                </div>
                <div>
                    <h5>Annex A: Restricted Areas (No-go zone)</h5>
                    <table class="custom-table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Postal Code</th>
                                <th scope="col">Location/ Building/Area</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>WHOLE AREA</td>
                                <td>KHUSU ISLAND</td>
                            </tr>
                            <tr>
                                <td>WHOLE AREA</td>
                                <td>ST. JOHN'S ISLAND</td>
                            </tr>
                            <tr>
                                <td>WHOLE AREA</td>
                                <td>PULAU UBIN</td>
                            </tr>
                            <tr>
                                <td>WHOLE AREA</td>
                                <td>PULAU TEKONG</td>
                            </tr>
                            <tr>
                                <td>WHOLE AREA</td>
                                <td>RIFLE RANGE COMPOUND</td>
                            </tr>
                            <tr>
                                <td>WHOLE AREA</td>
                                <td>SELETAR AEROSPACE</td>
                            </tr>
                            <tr>
                                <td>WHOLE AREA</td>
                                <td>WEST CAMP ROAD</td>
                            </tr>
                            <tr>
                                <td>WHOLE AREA</td>
                                <td>AIRPORT CARGO ROAD</td>
                            </tr>
                            <tr>
                                <td>WHOLE AREA</td>
                                <td>ALPS AVENUE</td>
                            </tr>
                            <tr>
                                <td>WHOLE AREA</td>
                                <td>AIRLINE ROAD</td>
                            </tr>
                            <tr>
                                <td>WHOLE AREA</td>
                                <td>TURF CLUB AVENUE</td>
                            </tr>
                            <tr>
                                <td>98269</td>
                                <td>Resort World at Sentosa</td>
                            </tr>
                            <tr>
                                <td>98322</td>
                                <td>85, BRANI TERMINAL AVENUE</td>
                            </tr>
                            <tr>
                                <td>98323</td>
                                <td>81, BRANI TERMINAL AVENUE</td>
                            </tr>
                            <tr>
                                <td>98324</td>
                                <td>2, BRANI TERMINAL AVENUE</td>
                            </tr>
                            <tr>
                                <td>98325</td>
                                <td>89, BRANI TERMINAL AVENUE</td>
                            </tr>
                            <tr>
                                <td>99958</td>
                                <td>Resort World at
                                    Sentosa (OFFICE)</td>
                            </tr>
                            <tr>
                                <td>109680</td>
                                <td>Central
                                    Manpower Base (CMPB)</td>
                            </tr>
                            <tr>
                                <td>117536</td>
                                <td>Power Grid</td>
                            </tr>
                            <tr>
                                <td>117605</td>
                                <td>Stockport Road</td>
                            </tr>
                            <tr>
                                <td>117630</td>
                                <td>Tanjong
                                    Berlayer Camp</td>
                            </tr>
                            <tr>
                                <td>129817</td>
                                <td>Clementi Camp</td>
                            </tr>
                            <tr>
                                <td>139302</td>
                                <td>Ayer Rajah Camp</td>
                            </tr>
                            <tr>
                                <td>149051</td>
                                <td>Special
                                    Operation Command</td>
                            </tr>
                            <tr>
                                <td>149373</td>
                                <td>Queenstown
                                    Remand Centre</td>
                            </tr>
                            <tr>
                                <td>178880</td>
                                <td>Parliament
                                    House</td>
                            </tr>
                            <tr>
                                <td>248843</td>
                                <td>Tanglin Camp</td>
                            </tr>
                            <tr>
                                <td>367833</td>
                                <td>Mount Vernon
                                    Camp</td>
                            </tr>
                            <tr>
                                <td>397970</td>
                                <td>Guillemard Camp</td>
                            </tr>
                            <tr>
                                <td>417902</td>
                                <td>Kaki Bukit
                                    Prison School</td>
                            </tr>
                            <tr>
                                <td>465556</td>
                                <td>Bedok Camp</td>
                            </tr>
                            <tr>
                                <td>478937</td>
                                <td>Singapore
                                    Prisons Quarters</td>
                            </tr>
                            <tr>
                                <td>498760</td>
                                <td>Changi
                                    Exhibition Centre (Airbase)</td>
                            </tr>
                            <tr>
                                <td>498761</td>
                                <td>Changi
                                    Exhibition Centre (Airbase)</td>
                            </tr>
                            <tr>
                                <td>498819</td>
                                <td>Changi Naval
                                    Base</td>
                            </tr>
                            <tr>
                                <td>498802</td>
                                <td>1 Changi North
                                    Way</td>
                            </tr>
                            <tr>
                                <td>499611</td>
                                <td>8 Changi North
                                    Way</td>
                            </tr>
                            <tr>
                                <td>498834</td>
                                <td>Tanah Merah
                                    Prison</td>
                            </tr>
                            <tr>
                                <td>508487</td>
                                <td>Hendon Camp</td>
                            </tr>
                            <tr>
                                <td>509863</td>
                                <td>Changi Airbase</td>
                            </tr>
                            <tr>
                                <td>509927</td>
                                <td>Trent Engine
                                    Overhaul Complex</td>
                            </tr>
                            <tr>
                                <td>509928</td>
                                <td>SIA Engine Test
                                    Centre</td>
                            </tr>
                            <tr>
                                <td>509932</td>
                                <td>Trent Engine
                                    Overhaul Complex</td>
                            </tr>
                            <tr>
                                <td>534257</td>
                                <td>Paya Lebar
                                    Airbase</td>
                            </tr>
                            <tr>
                                <td>567754</td>
                                <td>Amoy Quee Camp</td>
                            </tr>
                            <tr>
                                <td>596302</td>
                                <td>Maju Camp</td>
                            </tr>
                            <tr>
                                <td>596472</td>
                                <td>Police KINS
                                    Training Camp</td>
                            </tr>
                            <tr>
                                <td>506969</td>
                                <td>Changi Prison
                                    Complex Cluster B</td>
                            </tr>
                            <tr>
                                <td>507087</td>
                                <td>Selarang Camp</td>
                            </tr>
                            <tr>
                                <td>528765</td>
                                <td>21 Tampines
                                    North Drive 2</td>
                            </tr>
                            <tr>
                                <td>507709</td>
                                <td>Changi Prison
                                    Complex Cluster A</td>
                            </tr>
                            <tr>
                                <td>609271</td>
                                <td>ASL Shipyard</td>
                            </tr>
                            <tr>
                                <td>609273</td>
                                <td>PPL Shipyard</td>
                            </tr>
                            <tr>
                                <td>609278</td>
                                <td>Shell</td>
                            </tr>
                            <tr>
                                <td>609412</td>
                                <td>Vobak Building</td>
                            </tr>
                            <tr>
                                <td>609831</td>
                                <td>Caltex Jalan
                                    Buroh 1 Service Centre</td>
                            </tr>
                            <tr>
                                <td>619110</td>
                                <td>Jurong Port</td>
                            </tr>
                            <tr>
                                <td>619523</td>
                                <td>ST Kinetics</td>
                            </tr>
                            <tr>
                                <td>628054</td>
                                <td>29, Tanjong
                                    Kling</td>
                            </tr>
                            <tr>
                                <td>628398</td>
                                <td>Tuas Naval Base</td>
                            </tr>
                            <tr>
                                <td>628439</td>
                                <td>Tanjung Gul
                                    Camp</td>
                            </tr>
                            <tr>
                                <td>629122</td>
                                <td>Jurong Shipyard</td>
                            </tr>
                            <tr>
                                <td>629351</td>
                                <td>50, Gul Road</td>
                            </tr>
                            <tr>
                                <td>629353</td>
                                <td>Keppel Shipyard
                                    (Gul)</td>
                            </tr>
                            <tr>
                                <td>629888</td>
                                <td>Keppel Shipyard</td>
                            </tr>
                            <tr>
                                <td>637559</td>
                                <td>Pasir Laba Camp</td>
                            </tr>
                            <tr>
                                <td>637607</td>
                                <td>Tuas Power
                                    Station</td>
                            </tr>
                            <tr>
                                <td>638357</td>
                                <td>Jurong Camp I</td>
                            </tr>
                            <tr>
                                <td>638361</td>
                                <td>Jurong Camp II</td>
                            </tr>
                            <tr>
                                <td>638364</td>
                                <td>SAFTI Military
                                    Institute</td>
                            </tr>
                            <tr>
                                <td>638501</td>
                                <td>ST Marine</td>
                            </tr>
                            <tr>
                                <td>638709</td>
                                <td>15, Tuas
                                    Crescent</td>
                            </tr>
                            <tr>
                                <td>638717</td>
                                <td>23, Tuas
                                    Crescent</td>
                            </tr>
                            <tr>
                                <td>667988</td>
                                <td>Bukit Gombak
                                    Camp</td>
                            </tr>
                            <tr>
                                <td>669638</td>
                                <td>Hillview Camp</td>
                            </tr>
                            <tr>
                                <td>669642</td>
                                <td>Gombak Base HQ</td>
                            </tr>
                            <tr>
                                <td>669644</td>
                                <td>Gombak Base</td>
                            </tr>
                            <tr>
                                <td>669645</td>
                                <td>Ministry of
                                    Defence</td>
                            </tr>
                            <tr>
                                <td>669646</td>
                                <td>Bukit Panjang
                                    Camp</td>
                            </tr>
                            <tr>
                                <td>688255</td>
                                <td>Mowbray Camp</td>
                            </tr>
                            <tr>
                                <td>688256</td>
                                <td>Kranji Camp</td>
                            </tr>
                            <tr>
                                <td>688793</td>
                                <td>Stagmont Camp</td>
                            </tr>
                            <tr>
                                <td>689953</td>
                                <td>Keat Hong Camp
                                    II</td>
                            </tr>
                            <tr>
                                <td>689954</td>
                                <td>Keat Hong Camp</td>
                            </tr>
                            <tr>
                                <td>698956</td>
                                <td>Tengah Air Base</td>
                            </tr>
                            <tr>
                                <td>708972</td>
                                <td>Murai Camp</td>
                            </tr>
                            <tr>
                                <td>708976</td>
                                <td>Lim Chu Kang
                                    Camp II</td>
                            </tr>
                            <tr>
                                <td>718919</td>
                                <td>Sungei Gedong
                                    Camp</td>
                            </tr>
                            <tr>
                                <td>729753</td>
                                <td>Mandai Camp</td>
                            </tr>
                            <tr>
                                <td>729754</td>
                                <td>Mandai Camp II</td>
                            </tr>
                            <tr>
                                <td>729756</td>
                                <td>Mandai Hill
                                    Camp</td>
                            </tr>
                            <tr>
                                <td>738103</td>
                                <td>Woodland Check
                                    Point</td>
                            </tr>
                            <tr>
                                <td>738203</td>
                                <td>Woodland Check
                                    Point</td>
                            </tr>
                            <tr>
                                <td>738406</td>
                                <td>60 Woodlands
                                    Industrial Park D</td>
                            </tr>
                            <tr>
                                <td>738700</td>
                                <td>Woodlands Check
                                    Point</td>
                            </tr>
                            <tr>
                                <td>757618</td>
                                <td>Sembawang Camp</td>
                            </tr>
                            <tr>
                                <td>757621</td>
                                <td>Chong Pang Camp</td>
                            </tr>
                            <tr>
                                <td>757752</td>
                                <td>Sembawang
                                    Airbase</td>
                            </tr>
                            <tr>
                                <td>757753</td>
                                <td>Khatib Camp</td>
                            </tr>
                            <tr>
                                <td>757758</td>
                                <td>Dieppe Barracks</td>
                            </tr>
                            <tr>
                                <td>758103</td>
                                <td>Senoko Power
                                    Station</td>
                            </tr>
                            <tr>
                                <td>759907</td>
                                <td>Senoko
                                    Incineration Plant</td>
                            </tr>
                            <tr>
                                <td>759945</td>
                                <td>Admiralty West
                                    Prison</td>
                            </tr>
                            <tr>
                                <td>759956</td>
                                <td>Sembawang
                                    Shipyard</td>
                            </tr>
                            <tr>
                                <td>759956</td>
                                <td>Sembawang Wharf</td>
                            </tr>
                            <tr>
                                <td>759957</td>
                                <td>Sembawang
                                    Prison</td>
                            </tr>
                            <tr>
                                <td>769194</td>
                                <td>4 Lorong
                                    Chencharu</td>
                            </tr>
                            <tr>
                                <td>778895</td>
                                <td>Nee Soon Camp</td>
                            </tr>
                            <tr>
                                <td>778900</td>
                                <td>Nee Soon Camp
                                    (Leo North)</td>
                            </tr>
                            <tr>
                                <td>779914</td>
                                <td>Nee Soon Camp
                                    (SCDF)</td>
                            </tr>
                            <tr>
                                <td>797792</td>
                                <td>Seletar Airport
                                    Passenger Terminal</td>
                            </tr>
                            <tr>
                                <td>797809</td>
                                <td>Seletar Airport</td>
                            </tr>
                            <tr>
                                <td>819658</td>
                                <td>Changi
                                    International Airport Services</td>
                            </tr>
                            <tr>
                                <td>819659</td>
                                <td>SATS Inflight
                                    Catering Centre 1</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

            </div>
            </Row>
            </Container>
            <Footer/>
    </>)
}
export default TermsOfUse